/**
 * BonusTerms - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM SPORTS BETTING BONUS TERMS AND CONDITIONS </h1>
            English version 1.3 Last update February 13th, 2025.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1 These Sports Betting Terms and Conditions apply to Nubet Sports Betting.<br></br>
            <br></br>
            1.2 The Grantor of the Sports Betting Bonus explained in these Sports Betting Bonus Terms and Conditions is Nubet on the website Nubet.com operated by Vana Lauri OÜ, a company registered in Estonia under
            company registration number 16427120 having its registered address at Roseni 10-135, 10111 Tallinn, Estonia (hereinafter referred to as <b>“Nubet”</b> or <b>“The Company”</b>). Vana Lauri OÜ is licensed and
            supervised by the Estonian Tax and Customs Board and is holding the following licenses: HKT000066 (date of issue: 10.07.2022) and HKT000067 (date of issue: 10.07.2022).<br></br>
            <br></br>
            1.3 Nubet may award you any type of Sports Betting Bonus credit or other type of bonuses such as free bet (hereinafter referred also as <b>“Bonus Money”</b>, <b>“Bonus”</b> or <b>“Sports Betting Bonus”</b>)
            if certain conditions are met. Bonus use related wagering
            Terms and Conditions (hereinafter referred also as a <b>“Wagering”</b>) will apply to any granted Sports Betting Bonus and Wagering rules will be informed in advance to the Player on the website and via
            e-mail. Any Sports Betting Bonus awarded will be automatically credited to game account as Bonus Money which is bound by the rules defined in these Sports Betting Bonus Terms and Conditions.<br></br>
            <br></br>
            <h3 id="Tc">2. GENERAL SPORTS BETTING BONUS TERMS AND CONDITIONS</h3>
            2.1. These General Sports Betting Bonus Terms and Conditions apply to any type of Sports Betting Bonus or campaign offers granted by Nubet.<br></br>
            <br></br>
            2.2. It is within the Company’s sole discretion to exclude individual Players from the offer. It is within the Company’s sole discretion
            to void any Bonus and related winnings if the Company sees that the Player does not respect the Bonus rules. The Company may also change the terms and conditions of the Bonus or stop offering it at any time
            without notice.<br></br>
            <br></br>
            2.3. Nubet Sports Betting Bonus is valid for and must be used during a certain promotional period. If it is not used, it will expire, and it cannot be used afterwards.<br></br>
            <br></br>
            2.4. The Nubet Sports Betting Bonus is only available once per Customer, Player Account, household, the owner of the money transfer method and/or account used to make the deposit, shared computer (university,
            workplace, internet café, etc.) and/or IP address.<br></br>
            <br></br>
            2.5. Nubet Sports Betting Bonus may not be combined with any other Bonuses unless expressly stated otherwise and may not be used in conjunction with any other promotions or campaigns.<br></br>
            <br></br>
            2.6. A Nubet Sports Betting Bonus Campaign offer sent by e-mail may only be redeemed by the original recipient of the Bonus e-mail. The e-mail address receiving and using the Bonus must be the same as when
            creating the Player Account. Nubet reserves the right to investigate whether the game account holder who used the Sports Betting Bonus offer received the Bonus code personally to the e-mail address used in
            the game account. Breach of this rule may lead to confiscation of the Bonus and any related winnings.<br></br>
            <br></br>
            2.7. A Nubet Sports Betting Bonus that is linked to making a deposit is only valid when using the payment methods offered by Nubet and not specifically excluded as payment method from the Sports Betting Bonus
            offer. The account holder and the payment method holder must be the same. The credit balance on game account which origin is deposit made by the Player without use of bonus, and thus not bound to Bonus Terms
            and Conditions, is called real money balance (hereinafter referred also as <b>“Real Money Balance”</b>).<br></br>
            <br></br>
            2.8. The Nubet Sports Betting Bonus will be credited to your game account balance. In case Sports Betting Bonus has Wagering the account balance cannot be withdrawn or converted to Real Money Balance until
            the Wagering is complete as specified in the Bonus Terms and Conditions. If a Sports Betting Bonus is granted on a deposit, this also applies to the amount deposited, as both are bound by the Sports Betting
            Bonus terms and conditions.<br></br>
            <br></br>
            2.9. Only granted bonus is subject to wagering requirement.<br></br>
            <br></br>
            2.10. Only settled bets count towards the Wagering requirement. In a case where bet/stake is refunded due to an interrupted event, or event is declared canceled, or void, or there is any other reason why the
            match bet cannot be settled normally, the bet amount will not count towards the Wagering. Same applies to the bets that are cashed out.<br></br>
            <br></br>
            
            2.11. Once the player has placed a bet with an active Bonus, the redeemed Bonus can no longer be canceled without losing the Bonus.<br></br>
            <br></br>
            
            <br></br>
            2.12. If not otherwise stated, any Sports Betting Bonus received must be Wagered at least six (6) times before it can be paid out or attempted to be withdrawn. Any winnings made from Bonus Money are not
            payable until the Bonus Wagering conditions are met. When the wagering requirement is in progress, the withrawals in general are blocked.
            <br></br>
            <br></br>
            2.13. The Bonus Wagering requirements must be met within 30 days. In case of failure of meeting the Bonus conditions in time Nubet reserves the right to cancel the Bonus
            amount and all winnings resulting from it.<br></br>
            <br></br>
            2.14. The use of any Bonus is subject to Nubet's monitoring for irregular playing patterns.<br></br>
            <br></br>
            2.15. Misuse of the Sports Betting Bonus constitutes a breach of the Bonus Terms and Conditions. Nubet will assume a breach of the Bonus conditions in the following cases:<br></br>
            <br></br>
            <ListItem>Multiple use of a Bonus by the same person or using the same IP or terminal;</ListItem>
            <ListItem>Suspected organised use of the Bonus offer;</ListItem>
            <ListItem>Placing of bets which, irrespective of their outcome, guarantee a Sports Betting Bonus win for an individual or for a group of players cooperating together, making so-called "sure wins".</ListItem>
            <br></br>
            2.16. If there is a suspicion that Sports Betting Bonus Terms and Conditions have been breached or customer has placed bets that guarantee a win due to a Sports Betting Deposit Bonus, free bets, or any other
            promotional offer, regardless of the outcome of the bet, Nubet reserves the right to reclaim the Nubet Sports Betting Bonus offer, correct or cancel placed bets, refuse requested withdrawals and close
            accounts.<br></br>
            <br></br>
            2.17. A Bonus will not be credited if you have been excluded or suspended from the Nubet offer.<br></br>
            <br></br>
            2.18. The Sports Betting Bonus is valid until Wagering is complete or the Sports Betting Bonus is cancelled.<br></br>
            <br></br>
            2.19. Nubet reserves the right to change, suspend or revoke the Bonus and duration of the promotion at any time.<br></br>
            <br></br>
            2.20. Nubet's terms and conditions apply.<br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
