import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";

// GET USER BALANCE DETAILS

export const getWalletBalance = async () => {
  const reply = await api.get("wallet/balance", {
    headers: authHeader(),
  });
  return reply.data;
};

// make withdraw request

export const makeWithdraw = async ({ amount, bonus }) => {
  const reply = await api.post(
    "wallet/withdraw",
    {
      params: {
        amount: amount,
        merchant: "trustly",
        bonus: bonus ? bonus : 0,
        method: "1074",
      },
    },
    { headers: authHeader() }
  );
  let response = typeof reply?.data === "object" ? { ...reply.data } : {};

  if (response?.type === "error" && typeof response?.payload === "object") {
    response = { ...response, payload: { error: response?.payload?.error } };
  }

  if (response?.type === "success" && typeof response?.payload === "object") {
    response = { ...response, payload: "withdraw_initiated_success" };
  }

  return {
    shouldAlert: true,
    response: response,
    data: reply.data?.payload,
  };
};

// GET USER DEPOSIT STATEMENTS

export const getUserDepositStatements = async ({ start, end, page }) => {
  const reply = await api.get("transactions/trans", {
    params: {
      action: "deposits",
      startTime: start,
      endTime: end,
      page,
    },
    headers: authHeader(),
  });
  return reply?.data;
};

// get user withdraw statements
export const getUserWithdrawStatements = async ({ start, end, page }) => {
  const reply = await api.get("transactions/trans", {
    params: {
      action: "withdrawals",
      startTime: start,
      endTime: end,
      page,
    },
    headers: authHeader(),
  });
  return reply?.data;
};

// get user withdraw statements
export const getUserTransactionStatements = async ({ start, end, page, filter }) => {
  const reply = await api.get("transactions/trans", {
    params: {
      action: "games",
      startTime: start,
      endTime: end,
      page,
      filter,
    },
    headers: authHeader(),
  });
  return reply?.data;
};

// GET USER SUBSCRIPTIONS
export const getSubscriptions = async () => {
  const reply = await api.get("account/subscriptions", {
    headers: authHeader(),
  });
  return reply?.data;
};

// SAVE SUBSCRIPTION UPDATE

export const updateSubscriptions = async ({ params, callback }) => {
  const reply = await api.put(
    "account/subscriptions",
    {
      params,
    },
    { headers: authHeader() }
  );
  if (callback && reply?.data?.type === "success") {
    callback();
  }
  return {
    shouldAlert: true,
    response: reply?.data,
    data: reply?.data?.payload,
  };
};

// GET USER DETAILS

export const getUserDetails = async () => {
  const reply = await api.get("account/user", {
    headers: authHeader(),
  });
  return reply.data;
};

// update password

export const updatePassword = async ({ params, callback }) => {
  const reply = await api.put(
    "auth/password",
    {
      params: params,
    },
    { headers: authHeader() }
  );
  if (callback) {
    callback();
  }
  return {
    shouldAlert: true,
    response: reply?.data,
    data: reply?.data?.payload,
  };
};

// BLOCK USER ACCOUNT

export const blockUser = async ({ params, callback }) => {
  const reply = await api.post(
    "/account/coolOff",
    {
      params: params,
    },
    { headers: authHeader() }
  );
  if (callback && reply?.data?.type === "data") {
    callback();
  }
  return {
    shouldAlert: true,
    response: reply?.data,
    data: reply?.data?.payload,
  };
};

// get user limits

export const getUserLimits = async () => {
  const reply = await api.get("account/limits", {
    headers: authHeader(),
  });
  return reply?.data?.payload;
};

// save user limits

export const saveUserLimits = async (data) => {
  const reply = await api.post(
    "account/limits",
    {
      params: data,
    },
    { headers: authHeader() }
  );
  return {
    shouldAlert: true,
    response: reply?.data,
    data: reply?.data?.payload,
  };
};

// update user limit

export const updateUserLimits = async ({ params, callback }) => {
  const reply = await api.put(
    "/account/limits",
    {
      params,
    },
    { headers: authHeader() }
  );
  if (callback) {
    callback();
  }
  return {
    shouldAlert: true,
    response: reply?.data,
    data: reply?.data?.payload,
  };
};

export const getUserDepositCampaigns = async () => {
  const res = await api.get("account/depositCampaigns", { headers: authHeader() });
  return res.data;
};
export const getUserPaymentDetails = async () => {
  const res = await api.get("wallet/user-payment-details-psp", { headers: authHeader() });
  return res.data;
};

export const getUserFreeBets = async (params) => {
  const res = await api.get("freebet/getListUnused", { headers: authHeader(), params });
  return res.data;
};

export const uploadUserDocument = async ({ data, callback }) => {
  const reply = await api.post("account/uploadDocument", data, { headers: authHeader() });
  if (callback) {
    callback();
  }
  return reply.data;
};

export const getUserDocuments = async () => {
  const res = await api.get("account/documents", { headers: authHeader() });
  return res.data;
};
