import { Autoplay, Pagination, Navigation } from "swiper/modules";

const featuredOutrightsSliderSettings = {
  spaceBetween: 5,
  loop: true,
  freeMode: true,
  loopedSlides: 5, // Ensure looping with few items
  autoplay: {
    delay: 11000,
    disableOnInteraction: false,
    pauseOnMouseEnter: false,
  },
  modules: [Autoplay, Pagination, Navigation],
  breakpoints: {
    0: {
      slidesPerView: 1.1,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
    900: {
      slidesPerView: 3,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
    1536: {
      slidesPerView: 5,
      spaceBetween: 0,
      slidesPerGroup: 1,
    },
  },
};

export default featuredOutrightsSliderSettings;
