import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrecannedCard from "../@cards/precanned-card";
import { getBetbuilderBannersAsync } from "../../../redux-store/banners-reducer";
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";
import featuredPrecannedSliderSettings from "../../swiper/settings/featured-precanned-slider";
import { PaperContainer } from "../../sports/@components/containers";
import { Box, styled } from "@mui/material";
import { useLocation } from "react-router-dom";

const shuffleBets = (precannedBetsList) => {
  for (let i = precannedBetsList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [precannedBetsList[i], precannedBetsList[j]] = [precannedBetsList[j], precannedBetsList[i]];
  }

  return precannedBetsList;
};

const formatBets = (precanned) => {
  const precannedList = precanned && typeof precanned === "object" ? precanned : null;
  let precannedBetsList = [];
  if (precannedList) {
    Object.keys(precannedList).forEach((lgkey) => {
      const league = precannedList[lgkey];
      Object.values(league).forEach((lgGame) => {
        lgGame.bets?.forEach((bet) => {
          precannedBetsList.push({ ...bet, game: { ...lgGame, bets: [] }, isPrecanned: true });
        });
      });
    });
  }
  const shuffledBets = useMemo(() => shuffleBets(precannedBetsList));
  return shuffledBets;
};

const PrecannedBetsCarousel = () => {
  const dispatch = useDispatch();
  const [maxHeight, setMaxHeight] = useState("100%");
  const lang = useSelector((state) => state.i18n.locale);
  const layout = useSelector((state) => state.settings.layout);
  const precanned = useSelector((state) => state.banner.precanned);
  const location = useLocation();

  const formattedBets = useMemo(() => formatBets(precanned));

  useEffect(() => {
    dispatch(getBetbuilderBannersAsync(lang));
  }, [location.pathname]);

  useEffect(() => {
    const updateHeight = () => {
      const heights = Array.from(document.querySelectorAll(".swiper-slide")).map((el) => el.clientHeight);
      setMaxHeight(Math.max(...heights));
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [formattedBets?.length, location.pathname]);
  if (!formattedBets.length) return null;
  return (
    <Box sx={{ p: 0, "& .swiper-slide": { height: "100%" } }}>
      <SwiperSlider btnHeight={maxHeight} {...featuredPrecannedSliderSettings} nextButton={layout === "mouse" && formattedBets?.length > 3} prevButton={layout === "mouse" && formattedBets?.length > 3}>
        {formattedBets.map((bet, indx) => (
          <SwiperSlide key={indx}>
            <PaperContainer style={{ height: maxHeight, borderRadius: 0, padding: 0 }}>
              <PrecannedCard game={bet.game} bet={bet} />
            </PaperContainer>
          </SwiperSlide>
        ))}
      </SwiperSlider>
    </Box>
  );
};

export default PrecannedBetsCarousel;
