import "./style.css";
import React from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import locales from "../../../helpers/datefns-locales";
import icons from "../../sports/@components/sportsIcons";
import { checkSelectedOdd } from "../../sports/@components/@utils/odds-utils";
import { saveBet, setAlert } from "../../../actions";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import { Box, Stack, styled, Typography } from "@mui/material";
import CountryAvatar from "../../sports/@components/CountryAvatar";

const Container = styled(Box)(({ theme }) => ({
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  height: "100%",
  [theme.breakpoints.down("sm")]: {
    borderRight: 0,
    borderLeft: 0,
    borderColor: "none",
  },
}));

const SelectionButton = styled("button")(({ theme, selected }) => ({
  background: theme.palette.background.featuredOdds,
  color: theme.palette.text.primary,
  border: "1px solid",
  borderColor: "transparent",
  outline: "none",
  padding: theme.spacing(0.9),
  cursor: "pointer",
  borderRadius: 5,
  ...(selected && {
    borderColor: theme.palette.primary.main,
  }),
  ...theme.typography.subtitle2,
}));

class FeaturedOutrightCard extends React.PureComponent {
  addBet(odd) {
    const { game, selectedSlipTemplate } = this.props;
    let payload = {
      gameId: game.gameid,
      isLive: game.isLive,
    };

    const marketId = odd.m;
    const selectionId = odd.s;
    const price = odd.p;
    const hc = odd.hc;
    const isActive = odd.iA;

    payload.marketId = marketId;
    payload.selectionId = selectionId;
    payload.price = price;
    payload.hc = hc;
    payload.hn = odd.hn;
    payload.sn = odd.sn;
    payload.nr = odd?.nr;
    payload.isActive = isActive;
    payload.game = game;
    payload.isOutright = true;
    this.props.toggleMyTicketsPanel(false);
    this.props.saveBet(payload, selectedSlipTemplate, "OutrightCard");
    return Object.keys(payload)?.length === 0 ? false : true;
  }

  render() {
    const { game, lang, odds, bets } = this.props;
    return (
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-around">
          {icons[game?.sport_code]}
          <Stack>
            <Typography variant="body2" color="text.secondary">
              {game?.sport_code === "TR" ? game?.leagueName : null} {game?.game_title}
            </Typography>
            <Typography variant="string">
              {game?.sport_code === "TR"
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm", {
                    locale: locales[lang],
                  })
                : !game.odds.find((item) => item.m === 534)
                ? format(new Date(game?.nextBetStop), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })
                : format(new Date(game?.endTime), "dd.MM. HH:mm yyyy", {
                    locale: locales[lang],
                  })}
            </Typography>
          </Stack>
          <CountryAvatar countryCode={game.countryCode} />
        </Stack>
        <Stack py={1} spacing={1}>
          {odds
            ?.slice(0, 3)
            ?.sort((a, b) => parseFloat(a.p) > parseFloat(b.p))
            .map((odd, indx) => (
              <Stack key={indx} direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">{odd?.sn}</Typography>
                <SelectionButton onClick={() => this.addBet(odd)} selected={checkSelectedOdd(odd, game, bets, 0)}>
                  {odd?.p}
                </SelectionButton>
              </Stack>
            ))}
        </Stack>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const oddsCopy = [...ownProps.game?.odds];
  const oddsList = oddsCopy.sort((a, b) => a?.ord - b?.ord);

  return {
    auth: state.auth,
    lang: state.i18n.locale,
    game: ownProps.game,
    odds: oddsList,
    selectionPrintLabels: state.labels.selection ? state.labels.selection.print : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    bets: state.slips && state.slips[state.selected.slipTemplate].settings?.bets ? Object.values(state.slips[state.selected.slipTemplate].settings.bets) : null,
  };
};

export default connect(mapStateToProps, {
  saveBet,
  setAlert,
  toggleMyTicketsPanel,
})(FeaturedOutrightCard);
