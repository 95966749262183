import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getWPContent, getWPNewsContent } from "../../actions";
import fi from "date-fns/locale/fi";
import enAU from "date-fns/locale/en-AU";
import et from "date-fns/locale/et";
import ReactHtmlParser from "react-html-parser";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import withRouter from "../../helpers/withRouter";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const locales = {
  fi: fi,
  et: et,
  en: enAU,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  postContent: {
    "& img": {
      maxWidth: "100%",
      height: "auto",
      margin: "0 auto",
      borderRadius: "1em",
      display: "block",
      "@media (hover:hover)": {
        maxWidth: "100%",
      },
    },
    "& .wp-block-image": {
      margin: "1em",
    },
    "& .wp-block-table": {
      display: "grid",
      textAlign: "center",
    },
  }
});

class CasinoBannerRotation extends React.Component {
  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  getCategoryByLocale(locale, isLoggedIn) {
    const isMobile = this.isMobileDevice();
    
    if (isLoggedIn) {
      switch(locale) {
        case 'en':
          return isMobile ? 7 : 30;
        case 'et':
          return isMobile ? 8 : 31;
        case 'fi':
          return isMobile ? 3 : 29;
        default:
          return isMobile ? 7 : 30;
      }
    } else {
      switch(locale) {
        case 'en':
          return isMobile ? 21 : 12;
        case 'et':
          return isMobile ? 22 : 27;
        case 'fi':
          return isMobile ? 20 : 28;
        default:
          return isMobile ? 21 : 12;
      }
    }
  }

  componentDidMount() {
    const { locale, auth } = this.props;
    const localeCategory = this.getCategoryByLocale(locale, auth.isSignedIn);
    const mainCategory = this.isMobileDevice() ? 13 : 11;
    const categories = [mainCategory, localeCategory];
    this.props.getWPNewsContent(categories, this.constructor.name);
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, wpContent, auth, locale } = this.props;
    const localeCategory = this.getCategoryByLocale(locale, auth.isSignedIn);
    const mainCategory = this.isMobileDevice() ? 13 : 11;

    return (
      <div className={classes.root}>
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 11000,
            disableOnInteraction: false,
          }}
          speed={1400} 
        >
          {wpContent
            ?.filter(item => {
              return item.categories?.includes(mainCategory) || 
                     item.categories?.includes(localeCategory);
            })
            .sort((a, b) => {
              const titleA = a?.title?.rendered || '';
              const titleB = b?.title?.rendered || '';
              const numberA = parseInt(titleA.match(/^\d+/)?.[0] || '0');
              const numberB = parseInt(titleB.match(/^\d+/)?.[0] || '0');
              if (numberA && numberB) {
                return numberA - numberB;
              }
              return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
            })
            .map((item, index) => (
              <SwiperSlide key={`slide-${item.id}-${item.title?.rendered}-${index}`}>
                  <Typography
                    className={classes.postContent}
                    component={"span"}
                  >
                    {ReactHtmlParser(item.content?.rendered)}
                  </Typography>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedLocale = props?.match?.params?.lang;
  return {
    locale: selectedLocale ? selectedLocale : state.i18n.locale,
    wpContent: state.wpContent.news,
    auth: state.auth
  };
};

export default withRouter(connect(mapStateToProps, { getWPContent, getWPNewsContent })(
  withStyles(styles)(CasinoBannerRotation)
));