import { Box, Grid2 } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import GameCard from "../components/CasinoGameCard";
import { searchCasinoGamesAsync } from "../../../redux-store/casino-reducer";

const CasinoSearchView = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchedGames = useSelector((state) => state.casino.searchedGames);
  const query = searchParams.get("query");

  useEffect(() => {
    if (query) {
      dispatch(searchCasinoGamesAsync(query));
    }
  }, [query]);

  return (
    <Box>
      <Grid2 container>
        {searchedGames?.map((gameData, indx) => (
          <Grid2 item size={{ xs: 6, sm: 4, md: 3 }} key={indx}>
            <Box p={1}>
              <GameCard game={gameData} />
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default CasinoSearchView;
