// Player props catalog
import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerPropsAsync } from "../../../redux-store/sports-reducer";
// components
// import FeaturedGamesBannerV1 from "../../banners/featured-game/v1";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import EmptyScreen from "../@components/EmptyScreen";
import { Translate } from "react-redux-i18n";
import SportsLayout from "../@components/SportsLayout";
import { Divider, Grid2, Stack, Typography } from "@mui/material";
import SportsSideBar from "../@components/SportsSideBar";
import { PaperContainer } from "../@components/containers";
import LeagueRow from "../@components/LeagueRow";
import PageLoader from "../../PageLoader";

const PlayerPropsCatalog = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  const location = useLocation();
  // selectors
  const isPageLoading = useSelector((state) => state.sports.playerProps.isLoading);
  const leagues = useSelector((state) => state.sports.playerProps.leagues);
  const sportCodes = useSelector((state) => state.sports.playerProps.sportCodes);
  const locale = useSelector((state) => state.i18n.locale);
  // seaerch params
  const selectedSportCode = searchParams.get("selected-sport") || sportCodes[0];
  // getSelected
  const selectedLeagues = leagues && leagues[selectedSportCode] ? leagues[selectedSportCode] : [];

  React.useEffect(() => {
    dispatch(getPlayerPropsAsync(locale));
  }, [location.pathname]);

  return (
    <SportsLayout
      banner={
        <Box display={{ xs: "none", md: "block" }}>
          <PrecannedBetsCarousel />
        </Box>
      }
      navigationBar={selectedLeagues?.length ? <SportsSideBar collection="player-props" sportCodes={sportCodes} initialSportCode={sportCodes[0]} /> : null}
    >
      <Box pb={{ xs: 0, md: 2 }}>
        {isPageLoading ? (
          <PageLoader />
        ) : selectedLeagues?.length ? (
          <Stack gap={1}>
            <PaperContainer>
              <Stack pb={2} pt={1}>
                <Typography variant="h5" component="p">
                  <Translate value="labels.all_competetions" />
                </Typography>
              </Stack>
              <Divider />
              {selectedLeagues?.map((league, indx) => (
                <LeagueRow key={league.leagueId} rowIndex={indx + 1} leaguesCount={selectedLeagues?.length} {...league} />
              ))}
            </PaperContainer>
          </Stack>
        ) : (
          <EmptyScreen message={<Translate value="labels.empty_screen_message" />} />
        )}
      </Box>
    </SportsLayout>
  );
};

export default PlayerPropsCatalog;
