import { Box, CircularProgress, FormControl, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { differenceInHours, format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AbortLimit from "../user/settings/limits/abort-limit";
import RemoveLimit from "../user/settings/limits/remove-limit";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { getUserLimitsAsync, updateUserLimitsAsync } from "../../redux-store/account-reducer";

const RHFLimitField = ({ data, name, ...other }) => {
  const dispatch = useDispatch();
  const [isAbortOpen, setAbort] = useState(false);
  const [isRemoveOpen, setRemove] = useState(false);
  const { control } = useFormContext();
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isUpdateLimitLoading = useSelector((state) => state.account.limits.isUpdateLoading);
  const autoRenewTime = data?.endTime ? format(new Date(data.endTime), "yyyy-MM-dd HH:mm") : null;
  const amountLeft = Number(data?.amount) - Number(data?.usedAmount);
  const percent = (amountLeft * 100) / Number(data?.amount);

  const handleAbort = () => {
    setAbort(!isAbortOpen);
  };

  const handleRemove = () => {
    setRemove(!isRemoveOpen);
  };

  const getTimeDifference = () => {
    const effectDate = data?.amountEffectiveTime;
    const amount = data?.newAmount;
    let message = translate.labels.confirm_limit_period;
    let endMessage = translate.labels.confirm_limit_period_ended;
    const currentDate = new Date();
    const timeDiff = differenceInHours(new Date(effectDate), currentDate);

    message = message?.replace("{{!time}}", timeDiff);
    message = message?.replace("{{!amount}}", amount);
    endMessage = endMessage?.replace("{{!amount}}", amount);

    if (timeDiff > 0) {
      return message;
    }
    return endMessage;
  };

  const canConfirm = () => {
    const effectDate = data?.amountEffectiveTime;
    const currentDate = new Date();
    const timeDiff = differenceInHours(new Date(effectDate), currentDate);
    return timeDiff > 0;
  };

  const handleConfirmNewLimit = () => {
    dispatch(
      updateUserLimitsAsync({ params: { operation: "confirmLimit", limitType: "loss", isConfirmed: true, limitPeriod: name === "daily" ? 1 : name === "weekly" ? 7 : 30 }, callback: () => dispatch(getUserLimitsAsync()) })
    );
  };

  const handleAbortLimit = () => {
    dispatch(
      updateUserLimitsAsync({
        params: { operation: "confirmLimit", limitType: "loss", isConfirmed: false, limitPeriod: name === "daily" ? 1 : name === "weekly" ? 7 : 30 },
        callback: () => dispatch(getUserLimitsAsync()),
      })
    );
    setAbort(false);
  };

  return (
    <Box>
      <Box minHeight={{ xs: "5vh", sm: "10vh", md: "18vh" }} pb={5} mt={3} display="flex" alignItems="center" justifyContent="center">
        {Boolean(data?.amount) && <CircularProgressWithLabel value={percent} amountLeft={amountLeft} />}
      </Box>

      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <TextField
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment>
                      {data?.newAmount && data?.newAmount > 0 ? <AbortLimit handleConfirm={handleAbortLimit} open={isAbortOpen} handleAbort={handleAbort} /> : null}
                      {/* <RemoveLimit open={isRemoveOpen} handleRemoveDialog={handleRemove} /> */}
                    </InputAdornment>
                  ),
                },
              }}
              inputProps={{ sx: { textAlign: "right" } }}
              helperText={error && error?.message ? error.message : autoRenewTime ? `${translate.labels.renewaltime_2} ${autoRenewTime}` : ""}
              type="number"
              error={!!error}
              variant="standard"
              {...field}
              {...other}
            />
            {data?.newAmount && data?.newAmount > 0 ? (
              <Stack p={3} mt={2} border="1px solid" borderColor="divider" borderRadius={1} gap={3}>
                <Typography variant="body2">{getTimeDifference()}</Typography>
                <LoadingButton loading={isUpdateLimitLoading} onClick={handleConfirmNewLimit} disabled={canConfirm()} variant="contained" color="primary">
                  {translate.labels.limits_confirm}
                </LoadingButton>
              </Stack>
            ) : null}
          </FormControl>
        )}
      />
    </Box>
  );
};

export default RHFLimitField;

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress size={100} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography variant="body2" component="div" sx={{ color: "text.secondary" }}>
            {props.amountLeft}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            EUR left
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
