//Categories list and labels for news

export const newsConfigs = {
  en: [
    // {
    //   id: 37,
    //   label: "Welcome",
    // },
    {
      id: 54,
      label: "All",
    },
    {
      id: 52,
      label: "Sports",
    },
    // {
    //   id: 49,
    //   label: "Esports",
    // },
    {
      id: 45,
      label: "Casino",
    },
    {
      id: 42,
      label: "Test",
    },
  ],
  et: [
    // {
    //   id: 36,
    //   label: "Welcome",
    // },
    {
      id: 55,
      label: "Kõik",
    },
    {
      id: 51,
      label: "Sport",
    },
    // {
    //   id: 48,
    //   label: "Esport",
    // },
    {
      id: 46,
      label: "Kasiino",
    },
    {
      id: 43,
      label: "Test",
    },
  ],
  fi: [
    // {
    //   id: 35,
    //   label: "Welcome",
    // },
    {
      id: 53,
      label: "Kaikki",
    },
    {
      id: 50,
      label: "Urheilu",
    },
    // {
    //   id: 47,
    //   label: "Esports",
    // },
    {
      id: 44,
      label: "Kasino",
    },
    {
      id: 41,
      label: "Test",
    },
    
  ],
};
