import { Box, CircularProgress, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import UploadDocumentDialog from "./@components/UploadDocument";
import { useEffect } from "react";
import { getUserDocumentsAsync } from "../../../redux-store/account-reducer";
import { format } from "date-fns";
import { Translate } from "react-redux-i18n";
import Iconify from "../../iconify";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "70%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    margin: "auto",
  },
  "& .MuiTabPanel-root": {
    padding: 0,
  },
  "& .MuiTableCell-root": {
    borderBottom: "2px solid",
    borderColor: theme.palette.divider,
    padding: theme.spacing(1),
  },
}));

const UserDocuments = () => {
  const translate = useSelector((state) => state.i18n.translations[state.i18n.locale]);
  const documents = useSelector((state) => state.account.documents.list);
  const isLoading = useSelector((state) => state.account.documents.isGetLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDocumentsAsync());
  }, []);

  return (
    <Box py={2} px={{ xs: 1, md: 0 }}>
      <StyledContainer>
        <Stack direction="row" alignItems="center" justifyContent="space-between" pb={2}>
          <Typography variant="h5">{translate.labels.document}</Typography>
          <UploadDocumentDialog />
        </Stack>
        <Stack>
          {isLoading ? (
            <Stack height={"50dvh"} alignItems="center" justifyContent="center" gap={2} px={2}>
              <CircularProgress color="text.secondary" />
              <Typography variant="body2" color="text.secondary">
                loading...
              </Typography>
            </Stack>
          ) : documents.length ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtite2" color="text.secondary">
                        {translate.labels.document_type}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtite2" color="text.secondary">
                        {translate.labels._status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtite2" color="text.secondary">
                        {translate.labels.document_side}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtite2" color="text.secondary">
                        {translate.labels._date}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((doc) => (
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">{translate.labels[doc.documentType]}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="primary">
                          {doc.documentStatus}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Translate value={`labels.${doc.documentSide}_side`} />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{format(new Date(doc.uploadTime), "dd-MM-yyyy HH:mm")}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Stack alignItems="center" gap={1} height="60dvh" justifyContent="center" px={2}>
              <Iconify icon="mdi:file-document-minus-outline" width={40} sx={{ color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {translate.labels.no_documents_found}
              </Typography>
            </Stack>
          )}
        </Stack>
      </StyledContainer>
    </Box>
  );
};

export default UserDocuments;
