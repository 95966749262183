import "./style.css";
import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import FeaturedOutrightCard from "../@cards/outright-card";
import withRouter from "../../../helpers/withRouter";
import { getOutrightsBannerAsync } from "../../../redux-store/banners-reducer";
// @Swiper carousel utils
import SwiperSlider from "../../swiper";
import featuredOutrightsSliderSettings from "../../swiper/settings/featured-outrights-slider";
import { SwiperSlide } from "swiper/react";
import { PaperContainer } from "../../sports/@components/containers";

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em 0 1em",
    },
    "& li": {
      margin: "1em 0",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },

    padding: "0 0 10px 0",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

class FeaturedOutrightsBanner extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getOutrightsBannerAsync(this.props.lang);
  }

  goToRiskitonVeto = () => {
    this.props.navigate(`/${this.props.lang}/info/riskiton-veto`);
  };

  render() {
    const {  games, lang, layout } = this.props;
    if (games?.length === 0) return;
    return (
      <PaperContainer sx={{ p: 0 }}>
        <SwiperSlider prevButton={layout === "mouse" && games?.length > 3} nextButton={layout === "mouse" && games?.length > 3} {...featuredOutrightsSliderSettings}>
          {games
            ?.sort((a, b) => b.countryCode === "FIN" ? 1 : -1)
            .map((game, indx) => (
              <SwiperSlide key={indx}>
                <FeaturedOutrightCard game={game} />
              </SwiperSlide>
            ))}
        </SwiperSlider>
      </PaperContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const gamesList = [];
  const gamesGroups = state.banner.outrights && Object.values(state.banner.outrights) ? Object.values(state.banner.outrights) : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      gamesList.push(item);
    });
  });
  return {
    games: gamesList,
    lang: state.i18n.locale,
    layout: state.settings.layout,
  };
};

export default withRouter(connect(mapStateToProps, { getOutrightsBannerAsync })(withStyles(styles)(FeaturedOutrightsBanner)));
