import { Box, IconButton, Stack, styled } from "@mui/material";
import { Swiper } from "swiper/react";
import Iconify from "../iconify";
import { useRef } from "react";

const ButtonContainer = styled(Box)(({ theme, left, right }) => ({
  background: theme.palette.background.dbPaper,
  padding: theme.spacing(0.3),
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "centr",
  },
  ...(left && {
    borderTopLeftRadius: theme.spacing(0.6),
    borderBottomLeftRadius: theme.spacing(0.6),
  }),
  ...(right && {
    borderTopRightRadius: theme.spacing(0.6),
    borderBottomRightRadius: theme.spacing(0.6),
  }),
}));

const SwiperSlider = ({ children, nextButton, prevButton, btnHeight, ...rest }) => {
  const swiperRef = useRef(null);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
      {prevButton && (
        <ButtonContainer left sx={{ ...(btnHeight && { height: btnHeight }) }}>
          <IconButton size="small" onClick={() => swiperRef.current?.slidePrev()}>
            <Iconify icon="jam:chevron-left" />
          </IconButton>
        </ButtonContainer>
      )}
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        {...rest}
      >
        {children}
      </Swiper>
      {nextButton && (
        <ButtonContainer sx={{ ...(btnHeight && { height: btnHeight }) }}>
          <IconButton size="small" onClick={() => swiperRef.current?.slideNext()}>
            <Iconify icon="jam:chevron-right" />
          </IconButton>
        </ButtonContainer>
      )}
    </Stack>
  );
};

export default SwiperSlider;
