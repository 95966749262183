import { Box, Button, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../iconify";
import { Link } from "react-router-dom";
import { makeDepositAsync } from "../../../redux-store/auth-reducer";
import RHFAmountField from "../../hook-form/rhf-amount-field";
import { useEffect } from "react";
import { getUserDetailsAsync } from "../../../redux-store/account-reducer";

const DepositInterface = () => {
  const dispatch = useDispatch();
  // selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.auth.deposit.isInitLoading);
  const details = useSelector((state) => state.account.payments.details);
  const depositCampaigns = useSelector((state) => state.account.campaigns.deposit.list);
  // validation scheme
  const amountValidationScheme = Yup.object().shape({
    amount: Yup.number().min(10, translate.labels.minimum_amount).max(5000, translate.labels.minimum_amount).required(translate.labels.minimum_amount),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(amountValidationScheme),
    defaultValues: {
      amount: 0,
    },
  });
  // Handle INIT DEPOSIT
  const onSubmit = async (formValues) => {
    if (depositCampaigns?.length) {
      localStorage.setItem("deposit-bonuses-count", depositCampaigns.length);
    }
    dispatch(makeDepositAsync({ ...formValues, bonus: 0, storedDetailReference: Array.isArray(details) && details[0] ? details[0]?.storedDetailReference : undefined }));
  };

  const currentAmount = useWatch({ control: methods.control, name: "amount" });

  useEffect(() => {
    dispatch(getUserDetailsAsync());
  }, []);

  return (
    <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack alignItems="center" mb={3} gap={2}>
        <Box height="1.5em" component="img" src="https://api.nubet.com/media/icons/trustly.svg" />
        <Typography variant="body1" fontSize={24}>
          {translate.labels.deposit_trustly_1}
          <Typography variant="h1" component="spin" color="primary">
            {translate.labels.deposit_trustly_2}
          </Typography>
          {translate.labels.deposit_trustly_3}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center" gap={1} mb={3}>
        <Button onClick={() => methods.setValue("amount", 50, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          50
        </Button>
        <Button onClick={() => methods.setValue("amount", 100, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          100
        </Button>
        <Button onClick={() => methods.setValue("amount", 200, true)} endIcon={<Iconify icon="bx:euro" />} variant="contained" color="primary">
          200
        </Button>
      </Stack>
      <Stack alignItems="center" width={250} margin="auto">
        <RHFAmountField size="small" type="number" name="amount" />
      </Stack>
      <Stack mt={3} alignItems="center">
        <LoadingButton loading={isLoading} startIcon={<Iconify icon="ion:card-outline" />} type="submit" variant="contained" color="primary">
          {translate.labels.deposit_now}
        </LoadingButton>
      </Stack>
      <Stack mt={3}>
        <Typography variant="body1" align="center">
          {translate.labels.deposit_terms_1}
          <Typography variant="body1" color="primary" component="spin">
            <Link to={`/${locale}/info/terms-conditions`}>{translate.labels.deposit_terms_2}</Link>
          </Typography>
        </Typography>
      </Stack>
    </RHFormProvider>
  );
};

export default DepositInterface;
