/**
 * BonusTermsSlots - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTermsSlots extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component={"span"}>
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM CASINO BONUS TERMS AND CONDITIONS</h1>
            English version 1.3 Last update February 13th, 2025.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1. These Casino Bonus Terms and Conditions apply to Nubet Casino.<br></br>
            <br></br>
            1.2. The Grantor of the Casino Bonus explained in these Casino Bonus Terms and Conditions is Nubet on the website Nubet.com operated by Vana Lauri OÜ, a company registered in Estonia under company
            registration number 16427120 having its registered address at Roseni 10-135, 10111 Tallinn, Estonia (hereinafter referred to as <b>“Nubet”</b> or <b>“The Company”</b>). Vana Lauri OÜ is licensed and
            supervised by the Estonian Tax and Customs Board and is holding the following licenses: HKT000066 (date of issue: 10.07.2022) and HKT000067 (date of issue: 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet may award you any type of Casino Bonus credit or other type of bonuses such as free spins (hereinafter referred also as <b>“Bonus Money”</b>, <b>“Bonus”</b> or <b>“Casino Bonus”</b>) if certain
            conditions are met. Bonus use related Wagering Terms and
            Conditions (hereinafter referred also as a <b>“Wagering”</b>) will apply to any granted Casino Bonus and Wagering rules will be informed in advance to the Player on the website and via e-mail. Any Casino
            Bonus awarded will be automatically credited to game account as Bonus Money which is bound by the rules defined in these Casino Bonus Terms and Conditions.<br></br>
            <br></br>
            <h3 id="Generalbonus">2. GENERAL CASINO BONUS TERMS AND CONDITIONS</h3>
            2.1. These General Casino Bonus Terms and Conditions apply to any type of Casino Bonus granted by Nubet.<br></br>
            <br></br>
            2.2. It is within the Company’s sole discretion to exclude individual Players from the offer. It is within the Company’s sole discretion to void
            any Bonus and related winnings if the Company sees that the Player does not respect the Bonus rules. The Company may also change the terms and conditions of the Bonus or stop offering it at any time without
            notice.<br></br>
            <br></br>
            2.3. Nubet Casino Bonus is valid for and must be used during a certain promotional period. If it is not used, it will expire, and it cannot be used afterwards.<br></br>
            <br></br>
            2.4. The Nubet Casino Bonus is only available once per Customer, Player Account, household, the owner of the money transfer method and/or account used to make the deposit, shared computer (university,
            workplace, internet café, etc.) and/or IP address.<br></br>
            <br></br>
            2.5. Nubet Casino Bonus may not be combined with any other Bonuses unless expressly stated otherwise and may not be used in conjunction with any other promotions or campaigns.<br></br>
            <br></br>
            2.6. A Nubet Casino Bonus Campaign Offer sent by e-mail may only be redeemed by the original recipient of the Bonus e-mail. The e-mail address receiving and using the Bonus must be the same as when creating
            the Player Account. Nubet reserves the right to investigate whether the game account holder who used the Casino Bonus Offer received the Bonus code personally to the e-mail address used in the game account.
            Breach of this rule may lead to confiscation of the Bonus and any related winnings.<br></br>
            <br></br>
            2.7. A Nubet Casino Bonus that is linked to making a deposit is only valid when using the payment methods offered by Nubet and not specifically excluded as payment method from the Casino Bonus Offer. The
            account holder and the payment method holder must be the same. The credit balance on game account which origin is deposit made by the Player without use of bonus, and thus not bound to Bonus Terms and
            Conditions, is called real money balance (hereinafter referred also as <b>“Real Money Balance”</b>).<br></br>
            <br></br>
            2.8. The Nubet Casino Bonus will be credited to your game account balance Only granted bonus is subject to wagering requirement. In case Casino Bonus has Wagering the account balance cannot be withdrawn or converted to Real Money Balance until the Wagering is complete as specified in the Bonus Terms and Conditions.<br></br>
            <br></br>
            2.9. Only granted bonus is subject to wagering requirement.<br></br>
            <br></br>
            2.10. If not otherwise stated, any Casino Bonus received must be Wagered at least 30 times (30x) before it can be paid out or attempted to be withdrawn. Any winnings made from Bonus Money are not payable
            until the Bonus Wagering conditions are met. When the wagering requirement is in progress, the withrawals in general are blocked.
            <br></br>
            <br></br>
            2.11. If not otherwise stated, the Bonus Wagering requirements must be met within 7 days of receiving a Bonus. In case of failure of meeting the Bonus conditions in time Nubet reserves the right to cancel
            the Bonus amount and all winnings resulting from it.<br></br>
            <br></br>
            2.12. If free spins are awarded, they have a minimum value of 0.10 EUR per spin, unless otherwise stated. A spin that is not used during the specified validity period will be forfeited. All winnings from a
            free spin are considered Casino Bonus credit and must meet the Wagering requirements.<br></br>
            <br></br>
            2.13. The use of any Bonus is subject to Nubet's monitoring for irregular playing patterns.<br></br>
            <br></br>
            2.14. Misuse of the Casino Bonus constitutes a breach of the Bonus Terms and Conditions. Nubet will assume a breach of the Bonus conditions in the following cases:<br></br>
            <br></br>
            <ListItem>Multiple use of a Bonus by the same person or using the same IP or terminal;</ListItem>
            <ListItem>Suspected organised use of the Bonus offer.</ListItem>
            <br></br>
            <br></br>
            2.15. Nubet reserves the right to confiscate all non-wagered Bonus credit winnings, excluding deposited Real Money Balance, if there is suspicion of irregular gaming or addictive behaviour. In such situation
            real money credit shall be refunded.<br></br>
            <br></br>
            2.16. A Bonus will not be credited if you have been excluded or suspended from the Nubet offer.<br></br>
            <br></br>
            2.17. The Casino Bonus is valid until Wagering is complete or the Casino Bonus is cancelled.<br></br>
            <br></br>
            2.18. Nubet reserves the right to change, suspend or revoke the Bonus and duration of the promotion at any time.<br></br>
            <br></br>
            2.19. Wagering on the following games do not count towards the wagering requirement included in the bonuses: Absolootly Mad Mega Moolah, Agent Royale, Age of Conquest, Astro Legends Lyra & Erion, Blazing Bull
            2, Bakers Treat, Book Of 99, Castle Builder II, Cauldron, Double Dragons,Cazino Cosmos, Dwarf Mine, EggOmatic, Eye Of The Kraken, Gemix, Goblins and Gemstones, Magic of Sahara, Mad Monsters, Reel Rush II,
            Dynamite Riches, 11 Champions, Victoria Wild, Ducks Till Dawn, The Wish Master, Viking Runecraft, White Rabbit, Sea Hunter, Mystery Joker 6000, Extra Chilli, Wild Swarm, Dark Vortex, Jingle Spin, Zombie
            Hoard, Mahjong 88, Le Kaffee Bar, Narcos, Village People Macho Moves, Solar Queen, Razor Shark, Tiki Mania, Jewel Scarabs, Crystal Cavern, Blazing Bull, Solar Temple, Wilderland, Bronco Spirit, Hellcatraz,
            Football glory, All Star Knockout Ultra Gamble, Marching Legions, Banana Drop, Maui Millions, Lil Devil, Bombuster, Jungle Gorilla, 5 Families, Rage of the Seas, Lucky Fridays, Solar King, Emerald King, Hoard
            of Poseidon, Golden Tsar, Leprechauns Magic Megaways, Augustus, Jingle Bells Power Reels, Sylvan Spirits, Golden Stallion, Frost Queen Jackpots, Rome The Golden Age, Rome The Golden Age, Pirates Plenty
            MegaWays, Pearls Of India, The Hot Offer, Tower Quest, Rage To Riches, Robin Hood Shifting Riches, Scudamores Super Stakes, Scrooge, Serengeti Kings, Spina Colada, Sweet Alchemy, Tuts Twister, Vikings Go
            Berzerk, Wolf Hunters.<br></br>
            <br></br>
            2.20. Nubet's terms and conditions apply. <br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTermsSlots));
