import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import Iconify from "../../../iconify";
import { Translate } from "react-redux-i18n";

const AbortLimit = ({ open, handleConfirm, handleAbort }) => {
  return (
    <Box>
      <Tooltip title="Abort limit">
        <IconButton size="small" onClick={handleAbort}>
          <Iconify icon="ic:twotone-cancel" />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="subtitle1">
            <Translate value={`labels.abort_limit_change`} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body2" fontSize={12}>
              <Translate value={`labels.confirm_limit_abort`} />
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAbort} variant="contained" color="warning">
            <Translate style={{ fontSize: 13 }} value={`labels.limits_cancel`} />
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={handleConfirm} autoFocus sx={{ ml: 2 }}>
            <Translate style={{ fontSize: 13 }} value={`labels.i_am_sure`} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AbortLimit;
