import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const RHFSelectField = ({ name, helperText, type, textField, children, label, size, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          {label ? (
            <InputLabel size={size} id="select-input">
              {label}
            </InputLabel>
          ) : null}
          <Select size={size} label={label} labelId="select-input" id="select-input" {...field} {...other}>
            {children}
          </Select>
          <FormHelperText error={!!error} id="rhf-text-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RHFSelectField;
