import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import UserDetailsSaver from "./user-details";
import CookieLoader from "../CookieLoader";
import MarketLayoutLoader from "../MarketLayoutLoader";
import RouteListener from "../../helpers/routeListener";
import CustomLiveChatWidget from "../LiveChatWidget";
import AffiliateLoader from "./actions/AffiliateLoader";
import SSE from "../../apis/SSE";
import Alert from "../Alert";
import LiveCheckLoader from "./actions/LiveCheckLoader";
import { getUserDepositCampaignsAsync, getUserFreeBetsAsync, getWalletBalanceAsync } from "../../redux-store/account-reducer";
import { getCustomerFreeSpinsAsync } from "../../redux-store/casino-reducer";
import { removeAuthLoadingStatus, removeTrustlyRegPayload, generateDatabetTokenAsync } from "../../redux-store/auth-reducer";
import BonusOfferDialog from "./payments/bonus-offer-dialog";

const AppStateLoader = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // state selectors
  const auth = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.i18n.locale);
  const regPayload = useSelector((state) => state.auth.trustlyRegPayload);
  const loginPayload = useSelector((state) => state.auth.trustlyLoginPayload);
  const withdrawPayload = useSelector((state) => state.account.payments.withdraw.payload);
  const host = useSelector((state) => state.settings.host);
  const databet_token = localStorage.getItem("databet_token");

  useEffect(() => {
    if (regPayload?.token && regPayload?.reference && !location.pathname.includes("/auth/registration/status")) {
      dispatch(removeTrustlyRegPayload());
      navigate(`/${locale}/auth/registration/status`);
    }
    if (loginPayload?.token && !location.pathname.includes("/auth/login/status")) {
      navigate(`/${locale}/auth/login/status`);
    }
    if (auth.isSignedIn) {
      dispatch(getWalletBalanceAsync());
      dispatch(getCustomerFreeSpinsAsync());
      dispatch(getUserDepositCampaignsAsync());
      dispatch(getUserFreeBetsAsync({ player_id: auth?.user_id }));
    }
    if ((auth?.isRegLoading && !location.pathname.includes("/auth/registration")) || (auth.isLoginLoading && !location.pathname.includes("/auth/login"))) {
      dispatch(removeAuthLoadingStatus());
    }
  }, [loginPayload?.token, location.pathname, withdrawPayload?.reference, auth.isSignedIn]);

  useEffect(() => {
    if (!databet_token) {
      dispatch(generateDatabetTokenAsync(locale));
    }
  }, [databet_token]);

  return (
    <Box>
      <UserDetailsSaver />
      <CookieLoader />
      <MarketLayoutLoader />
      <RouteListener />
      <AffiliateLoader />
      <CustomLiveChatWidget />
      <LiveCheckLoader />
      <Alert />
      <BonusOfferDialog />
      {host.bet ? <SSE /> : null}
      {children}
    </Box>
  );
};

export default AppStateLoader;
