import { setAlert } from "../../actions";
import { handleUserLogoutAsync } from "../auth-reducer";

const alertMiddleware = (storeAPI) => (next) => (action) => {
  if (action.payload?.shouldAlert) {
    const alert = validate(action.payload?.response);
    if (alert.required && action.payload?.response?.type) {
      let errorType = typeof action.payload.response.payload === "string" ? action.payload.response.payload : "";
      const isObject = typeof action.payload.response.payload === "object";
      const pyd = isObject ? action.payload.response.payload : {};

      if (isObject) {
        if (pyd?.type) errorType = pyd.type;
        if (pyd?.error) errorType = pyd.error;
        if (pyd?.message) errorType = pyd.message;
      }

      storeAPI.dispatch(
        setAlert({
          ...alert.payload,
          payload: errorType || "",
          from: "",
        })
      );
    }
  }
  if (typeof action.payload === "object" && typeof action.payload?.payload === "object" && action.payload?.payload.error === "NoAvailableSessionException") {
    storeAPI.dispatch(handleUserLogoutAsync());
  }
  if (action?.error?.message && action?.error?.message?.includes("401")) {
    storeAPI.dispatch(handleUserLogoutAsync());
  }
  return next(action);
};

export default alertMiddleware;

function validate(res) {
  let alertRequired = false;
  let alertPayload = {};

  switch (res?.type) {
    default:
      alertPayload = {
        type: "error",
        payload: "connection_error",
        collection: "labels",
      };
      alertRequired = false;
      break;

    case "error":
    case "warning":
    case "success":
    case "info":
      alertPayload = {
        type: res?.type,
        payload: res?.payload,
        collection: res?.collection,
      };
      alertRequired = true;
      break;

    case "data":
      if (!res?.payload) {
        alertPayload = {
          type: "error",
          payload: "connection_error",
        };
        alertRequired = true;
      }
      break;
  }

  return {
    required: alertRequired,
    payload: alertPayload,
    stop: res?.type !== "success",
  };
}
