import { Box, Divider, Grid2, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetbuilderGamesAsync, getBetbuilderLeaguesAsync } from "../../../../redux-store/sports-reducer";
import BetbuilderGameCard from "./game-card";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import SportsLayout from "../../@components/SportsLayout";
import SportsSideBar from "../../@components/SportsSideBar";
import PrecannedBetsCarousel from "../../../banners/precanned-bets";
import { PaperContainer } from "../../@components/containers";
import SportsHeader from "../../@components/SportsHeader";

const BetbuilderLeagueGamesPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const params = useParams();

  // selectors
  const leagueId = params.leagueId;
  const allSportsCodes = useSelector((state) => state.sports.betbuilder.sportCodes);
  const sportCode = searchParams.get("selected-sport") || allSportsCodes[0];
  const lang = useSelector((state) => state.i18n.locale);
  const gamesList = useSelector((state) => state.sports.betbuilder.games);
  const leagues = useSelector((state) => state.sports.betbuilder.leagues);
  const selectedLeague = leagues && leagues[sportCode] ? leagues[sportCode].find((lg) => lg.leagueId === Number(leagueId)) : {};

  const initialSportCode = allSportsCodes[0];

  useEffect(() => {
    if (!allSportsCodes?.lenght) {
      dispatch(getBetbuilderLeaguesAsync(lang));
    }
    dispatch(getBetbuilderGamesAsync({ leagueId, lang }));
  }, [leagueId, location.pathname]);

  const sortedGames = _.cloneDeep(gamesList).sort((a, b) => {
    const dateA = new Date(a.startTime);
    const dateB = new Date(b.startTime);
    return dateA - dateB;
  });

  return (
    <SportsLayout
      banner={
        <Box display={{ xs: "none", md: "block" }}>
          <PrecannedBetsCarousel />
        </Box>
      }
      navigationBar={<SportsSideBar collection="betbuilder" sportCodes={allSportsCodes} initialSportCode={initialSportCode} />}
    >
      <Stack gap={1} pb={{ xs: 0, md: 2 }}>
        <SportsHeader sportCode={sportCode} league={selectedLeague} />
        <PaperContainer>
          <Grid2 container spacing={0.5}>
            {sortedGames.map((game) => (
              <Grid2 item size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={game.gameid}>
                <BetbuilderGameCard game={game} />
              </Grid2>
            ))}
          </Grid2>
        </PaperContainer>
      </Stack>
    </SportsLayout>
  );
};

export default BetbuilderLeagueGamesPage;
