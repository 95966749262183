import "react-phone-number-input/style.css";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Box, FormControl, FormHelperText, styled } from "@mui/material";
import { useSelector } from "react-redux";

const StyledContainer = styled(Box)(({ theme }) => ({
  "& .PhoneInput": {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    borderRadius: "5px",
    "& .PhoneInputInput": {
      background: theme.palette.background.paper,
      color: theme.palette.text.primary,
      outline: "none",
      border: "none",
      height: 45,
      fontSize: 14,
      paddingLeft: theme.spacing(1),
      "&::placeholder": {
        color: theme.palette.text.secondary,
        fontSize: 12,
      },
    },
    "& select": {
      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
  },
}));

export default function RHFPhoneField({ name, helperText, type, ...other }) {
  const { control } = useFormContext();
  const locale = useSelector((state) => state.i18n.locale);

  return (
    <StyledContainer>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <PhoneInputWithCountry countryCallingCodeEditable={false} international autoFormat={false} defaultCountry={locale === "et" ? "EE" : "FI"} {...field} {...other} />
            <FormHelperText error={!!error} id="rhf-text-field">
              {error ? error?.message : helperText}
            </FormHelperText>
          </FormControl>
        )}
      />
    </StyledContainer>
  );
}
