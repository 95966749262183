import { format, isTomorrow, isMonday, differenceInDays, isToday } from "date-fns";
import { enUS, fi, et } from "date-fns/locale";
/**
 * utilities library
 */

/**
 * Format value with commas and dots
 ** Format value with comma if current locale is not DE
 ** Format value with dots if current locale is DE
 */

export const formatCommasAndDots = (x, currentLocale) => {
  if (x) {
    x = parseFloat(x).toFixed(2);
    if (currentLocale === "de") {
      x = x.toString().replace(/\./g, ",");
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  } else {
    return 0;
  }
};

export function createUuid() {
  var chars = "0123456789abcdefghijklmnopqrstuvwxyz".split("");

  var uuid = [],
    rnd = Math.random,
    r;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
  uuid[14] = "4"; // version 4

  for (var i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (rnd() * 16);

      uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join("");
}

export const sortAlphabetically = (arr) => {
  const sortedArr = arr.sort(function (a, b) {
    if (a[0].sn < b[0].sn) {
      return -1;
    }
    if (a[0].sn > b[0].sn) {
      return 1;
    }
    return 0;
  });
  return sortedArr;
};

export const getStatusColor = (status) => {
  let color = "primary.main";
  switch (status) {
    case "error":
    case "failed":
    case "rejected":
    case "voided":
    case "cancelled":
    case "LOSS":
    case "loss":
      color = "error.main";
      break;
    case "ok":
    case "success":
    case "WON":
    case "WIN":
    case "win":
    case "won":
    case "confirmed":
      color = "success.main";
      break;
    case "pending":
    case "waiting":
      color = "warning.main";

    default:
      break;
  }
  return color;
};

export const getEventTime = (date, locale) => {
  if (!date) return;
  const selectedLocale = locale === "fi" ? fi : locale === "et" ? et : enUS;
  const now = new Date();

  if (isTomorrow(date)) {
    return `${selectedLocale.localize.day(0)} ${format(date, "hh:mm a", { locale: selectedLocale })}`;
  }

  if (isMonday(date)) {
    return `${selectedLocale.localize.day(1)} ${format(date, "hh:mm a", { locale: selectedLocale })}`;
  }

  // If the event is within a week
  if (differenceInDays(date, now) <= 7) {
    return format(date, "EEEE hh:mm a", { locale: selectedLocale });
  }

  // If the event is not within a week, return the full formatted date
  return format(date, "PPPP p", { locale: selectedLocale });
};

export const getCatalogTime = (date, locale) => {
  if (!date) return;
  const selectedLocale = locale === "fi" ? fi : locale === "et" ? et : enUS;

  if (isTomorrow(date)) {
    return selectedLocale.localize.day(0);
  }

  if (isToday(date)) {
    return "Today";
  }

  // If the event is within a week
  return format(date, "EEEE dd MMM", { locale: selectedLocale });
};

export const groupPlayerProps = (markets) => {
  const groups = {};

  markets.forEach((market) => {
    const playerIds = market.contracts
      .map((c) => c.player_id)
      .sort()
      .join("-");
    if (!groups[playerIds]) {
      groups[playerIds] = {
        group_name: `Market ${Object.keys(groups).length + 1}`,
        submarkets: [],
      };
    }
    groups[playerIds].submarkets.push(market);
  });
  return Object.values(groups);
};

export const getTimeDiffernce = (start, end) => {
  const dateA = new Date();
  const dateB = new Date(end);
  const diffTime = Math.abs(dateB - dateA);
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};
