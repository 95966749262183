import axios from "axios";
import api from "../../apis/nubet";

// GET BETBUILDER BANNERS DATA
export const getBetbuilderBanners = async (lang) => {
  const res = await api.get("featured/precanned", { params: { lang } });
  return res.data?.payload;
};

// GET OUTRIGHTS BANNERS
export const getOutrightsBanners = async (lang) => {
  const res = await api.get("featured/outrights", { params: { lang } });
  const payload = res.data?.payload;
  const result = typeof payload === "object" && res?.data?.type !== "error" ? payload : {};
  return result;
};

// GET FEATURED GAMES BANNERS

export const getFeaturedGamesBanners = async (lang) => {
  const res = await api.get("featured/games", { params: { lang } });
  return res.data?.payload;
};

// get WP banners content

export const getWpBannersContent = async ({ category }) => {
  const res = await axios.get("https://www.cms-nubet.com/wp-json/wp/v2/posts", { params: { categories: category, per_page: 100, _fields: "id,content,categories" } });
  return res.data;
};
