import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserSummary, checkTrustlyLoginStatus, checkTrustlyRegDepositStatus, initRegisterDeposit, trustlyLogin, sendPasswordResetEmail, resetUserPassword, makeDeposit, checkDepositStatus } from "./_requests";
import { addMinutes } from "date-fns";

const initialState = {
  isRefreshSessionLoading: false,
  isSendPassEmailLoading: false,
  isResetPasswordLoading: false,
  isDBTokenLoading: false,
  isLogoutLoading: false,
  liveCheck: {},
  shouldCheckLive: false,
  isSignedIn: false,
  authToken: null,
  isRegDepositLoading: false,
  isLoginLoading: false,
  trustlyRegPayload: {},
  trustlyRegPayloadCopy: {},
  trustlyDepositStatus: {},
  trustlyLoginPayload: {},
  autoLoginResponse: {},
  trustlyLoginStatus: {},
  isLoginStatusLoading: false,
  isAutoLoginLoading: false,
  isRegLoading: false,
  shouldAddDetails: false,
  termsConfirmed: true,
  loginTS: undefined,
  user_id: undefined,
  initials: undefined,
  firstName: undefined,
  lastName: undefined,
  exp: undefined,
  app_tld: undefined,
  deposit: {
    payload: {},
    status: {},
    isInitLoading: false,
    isStatusLoading: false,
    shouldAlertBonusOffer: false,
  },
};

export const initRegisterDepositAsync = createAsyncThunk("thunk/initRegisterDeposit", initRegisterDeposit);
export const trustlyLoginAsync = createAsyncThunk("thunk/trustlyLogin", trustlyLogin);
export const checkTrustlyRegDepositStatusAsync = createAsyncThunk("thunk/checkTrustlyRegDepositStatus", checkTrustlyRegDepositStatus);
export const checkTrustlyLoginStatusAsync = createAsyncThunk("thunk/checkTrustlyLoginStatus", checkTrustlyLoginStatus);
export const getUserSummaryAsync = createAsyncThunk("thunk/getUserSummary", getUserSummary);
export const sendPasswordResetEmailAsync = createAsyncThunk("thunk/sendPasswordResetEmail", sendPasswordResetEmail);
export const resetUserPasswordAsync = createAsyncThunk("thunk/resetUserPassword", resetUserPassword);
export const makeDepositAsync = createAsyncThunk("thunk/makeDeposit", makeDeposit);
export const checkDepositStatusAsync = createAsyncThunk("thunk/checkDepositStatus", checkDepositStatus);

export const authSlice = createSlice({
  name: "slice/auth",
  initialState,
  reducers: {
    removeTrustlyRegPayload: (state) => {
      state.trustlyRegPayload = {};
    },
    removeTrustlyLoginPayload: (state) => {
      state.trustlyLoginPayload = {};
    },
    removeTrustlyLoginStatus: (state) => {
      state.trustlyLoginStatus = {};
      state.autoLoginResponse = {};
    },
    removeTrustlyDepositStatus: (state) => {
      state.deposit.status = {};
      state.deposit.isStatusLoading = true;
    },
    removeTrustlyDepositPayload: (state) => {
      state.deposit.payload = {};
      state.deposit.status = {};
    },
    removeAuthLoadingStatus: (state) => {
      state.isLoginLoading = false;
      state.isRegLoading = false;
    },
    checkUserLiveStatus: (state, { payload }) => {
      state.shouldCheckLive = payload;
    },
    handleUserLoginAsync: (state) => {
      state.isLoginLoading = true;
    },
    setLoginFail: (state) => {
      state.isLoginLoading = false;
    },
    setLoginSuccess: (state, { payload }) => {
      state.isLoginLoading = false;
      if (payload?.jwt) {
        state.isSignedIn = true;
        state.authToken = payload?.jwt;
        state.loginTS = payload?.loginTS;
        state.exp = payload?.exp;
        // state.exp = addMinutes(new Date(), 2).getTime() / 1000;
        state.initials = payload?.initials;
        state.firstName = payload?.firstName;
        state.lastName = payload?.lastName;
        state.app_tld = payload?.app_tld;
        state.user_id = payload?.user_id;
        state.trustlyRegPayload = {};
        state.trustlyDepositStatus = {};
      }
    },
    // auto login
    handleAutoLoginAsync: (state) => {
      state.isAutoLoginLoading = true;
    },
    setAutoLoginSuccess: (state, { payload }) => {
      state.isAutoLoginLoading = false;
      if (payload?.payload?.error === "USER_MUST_REGISTER") {
        state.shouldAddDetails = true;
        localStorage.setItem("js_session_id", payload?.payload?.JSESSIONID);
      } else {
        localStorage.removeItem("js_session_id");
      }
      if (!payload?.payload?.jwt) {
        state.autoLoginResponse = payload;
        state.trustlyLoginPayload = {};
        state.trustlyLoginStatus = {};
      }
      if (payload?.payload?.jwt) {
        state.authToken = payload.payload.jwt;
        state.loginTS = payload.payload.loginTS;
        state.exp = payload.payload.exp;
        state.initials = payload.payload.initials;
        state.firstName = payload.payload.firstName;
        state.lastName = payload.payload.lastName;
        state.app_tld = payload.payload.app_tld;
        state.user_id = payload.payload.user_id;
        state.isSignedIn = true;
      }
    },
    setAutoLoginError: (state) => {
      state.isAutoLoginLoading = false;
    },
    // user logut
    handleUserLogoutAsync: (state) => {
      state.isLogoutLoading = true;
    },
    setLogoutSuccess: (state) => {
      state.isSignedIn = false;
      state.authToken = undefined;
      state.loginTS = undefined;
      state.exp = undefined;
      state.initials = undefined;
      state.firstName = undefined;
      state.lastName = undefined;
      state.app_tld = undefined;
      state.user_id = undefined;
      state.trustlyRegPayload = {};
      state.trustlyDepositStatus = {};
      state.trustlyLoginPayload = {};
      state.autoLoginResponse = {};
      state.trustlyLoginStatus = {};
      state.shouldCheckLive = false;
      state.isLogoutLoading = false;
    },
    setLogoutError: (state) => {
      state.isLogoutLoading = false;
    },

    // refresh session
    handleSessionRefreshAsync: (state) => {
      state.isRefreshSessionLoading = true;
    },
    setSessionRefreshSuccess: (state, { payload }) => {
      state.isRefreshSessionLoading = false;
      const newLoginTime = new Date().getTime() / 1000;
      state.exp = payload.exp;
      state.loginTS = newLoginTime;
      state.shouldCheckLive = false;
    },
    setSessionRefreshError: (state, { payload }) => {
      state.isRefreshSessionLoading = false;
      state.shouldCheckLive = false;
    },
    //  handle register user
    handleRegisterUserAsync: (state) => {
      state.isRegLoading = true;
    },
    setRegisterSuccess: (state, { payload }) => {
      state.isRegLoading = false;
      if (payload.data?.status === "SUCCESS") {
        state.shouldAddDetails = false;
      }
    },
    setRegisterError: (state) => {
      state.isRegLoading = false;
      state.shouldAddDetails = false;
    },

    // generate databet token
    generateDatabetTokenAsync: (state) => {
      state.isDBTokenLoading = true;
    },
    setDatabetSuccess: (state) => {
      state.isDBTokenLoading = false;
    },
    setDatabetError: (state) => {
      state.isDBTokenLoading = false;
    },
    // cancel bonus offer
    cancelBonusOffer: (state) => {
      state.deposit.shouldAlertBonusOffer = false;
    },
    setBonusOfferAlert: (state) => {
      state.deposit.shouldAlertBonusOffer = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // handle register deposit init
      .addCase(initRegisterDepositAsync.pending, (state) => {
        state.isRegDepositLoading = true;
        state.trustlyRegPayload = {};
        state.trustlyRegPayloadCopy = {};
        state.trustlyDepositStatus = {};
      })
      .addCase(initRegisterDepositAsync.rejected, (state) => {
        state.isRegDepositLoading = false;
        state.trustlyRegPayload = {};
        state.trustlyRegPayloadCopy = {};
        state.trustlyDepositStatus = {};
      })
      .addCase(initRegisterDepositAsync.fulfilled, (state, { payload }) => {
        state.isRegDepositLoading = false;
        if (payload?.response?.type === "success") {
          state.trustlyRegPayload = payload.data;
          state.trustlyRegPayloadCopy = payload.data;
          window.location.href = payload.data.redirectUrl;
        }
      })
      // check user reg deposit status
      .addCase(checkTrustlyRegDepositStatusAsync.pending, (state) => {
        state.trustlyRegPayload = {};
      })
      .addCase(checkTrustlyRegDepositStatusAsync.rejected, (state) => {
        state.trustlyRegPayloadCopy = {};
      })
      .addCase(checkTrustlyRegDepositStatusAsync.fulfilled, (state, { payload }) => {
        if (payload?.response?.type === "error") {
          state.trustlyRegPayload = {};
        }
        if (payload.data?.status === "ok" || payload.data?.status === "success") {
          state.trustlyRegPayloadCopy = {};
          state.shouldAddDetails = true;
        }
        state.trustlyDepositStatus = payload.data;
      })
      // TRUSTLY LOGIN
      .addCase(trustlyLoginAsync.pending, (state) => {
        state.isLoginLoading = true;
        state.trustlyLoginPayload = {};
        state.trustlyLoginStatus = {};
        state.autoLoginResponse = {};
      })
      .addCase(trustlyLoginAsync.rejected, (state) => {
        state.isLoginLoading = false;
      })
      .addCase(trustlyLoginAsync.fulfilled, (state, { payload }) => {
        if (payload.data?.redirectUrl) {
          state.trustlyLoginPayload = payload.data;
          window.location.href = payload.data.redirectUrl;
        }
        state.isLoginLoading = false;
      })
      // CHECK TRUSTLY LOGIN STATUS
      .addCase(checkTrustlyLoginStatusAsync.pending, (state) => {
        state.isLoginStatusLoading = true;
      })
      .addCase(checkTrustlyLoginStatusAsync.rejected, (state) => {
        state.isLoginStatusLoading = false;
        state.trustlyLoginPayload = {};
      })
      .addCase(checkTrustlyLoginStatusAsync.fulfilled, (state, { payload }) => {
        if (
          (payload?.type === "success" && payload?.payload.status === "success") ||
          payload?.type === "cancelled" ||
          payload?.type === "error" ||
          payload?.payload.status === "pending" ||
          payload?.payload.status === "failed" ||
          payload?.payload.status === "rejected" ||
          payload?.payload.status === "voided" ||
          payload?.payload.status === "init"
        ) {
          state.trustlyLoginPayload = {};
        }
        if (payload?.payload?.loginCode) {
          localStorage.setItem("trustly-login-code", payload?.payload?.loginCode);
        }
        state.trustlyLoginStatus = payload;
        state.isLoginStatusLoading = false;
      })
      // GET USER SUMMARY
      .addCase(getUserSummaryAsync.fulfilled, (state, { payload }) => {
        const data = typeof payload?.payload === "object" ? { ...payload?.payload } : {};
        state.liveCheck = data;
      })
      // send email to reset the pasword
      .addCase(sendPasswordResetEmailAsync.pending, (state) => {
        state.isSendPassEmailLoading = true;
      })
      .addCase(sendPasswordResetEmailAsync.rejected, (state) => {
        state.isSendPassEmailLoading = false;
      })
      .addCase(sendPasswordResetEmailAsync.fulfilled, (state) => {
        state.isSendPassEmailLoading = false;
      })
      // reset user password
      .addCase(resetUserPasswordAsync.pending, (state) => {
        state.isResetPasswordLoading = true;
      })
      .addCase(resetUserPasswordAsync.rejected, (state) => {
        state.isResetPasswordLoading = false;
      })
      .addCase(resetUserPasswordAsync.fulfilled, (state) => {
        state.isResetPasswordLoading = false;
        const lang = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "fi";
        window.location.href = window.location.origin + `/${lang}` + "/auth/login";
      })
      // make deposit
      .addCase(makeDepositAsync.pending, (state) => {
        state.deposit.isInitLoading = true;
        state.deposit.status = {};
        state.deposit.payload = {};
      })
      .addCase(makeDepositAsync.rejected, (state) => {
        state.deposit.isInitLoading = false;
        localStorage.removeItem("deposit-bonuses-count");
      })
      .addCase(makeDepositAsync.fulfilled, (state, { payload }) => {
        state.deposit.isInitLoading = false;
        if (payload.response?.type === "success") {
          state.deposit.payload = payload.data;
          window.location.href = payload.data?.redirectUrl;
        } else {
          localStorage.removeItem("deposit-bonuses-count");
        }
      })
      // check deposit status
      .addCase(checkDepositStatusAsync.pending, (state) => {
        state.deposit.isStatusLoading = true;
      })
      .addCase(checkDepositStatusAsync.rejected, (state) => {
        state.deposit.isStatusLoading = false;
        localStorage.removeItem("deposit-bonuses-count");
      })
      .addCase(checkDepositStatusAsync.fulfilled, (state, { payload }) => {
        state.deposit.isStatusLoading = false;
        state.deposit.status = payload?.deposit?.payload || {};
        if (payload?.deposit?.payload?.status !== "pending" && payload?.deposit?.payload?.status !== "waiting") {
          state.deposit.payload = {};
        }
        if (payload?.shouldAlertBonus) {
          state.deposit.shouldAlertBonusOffer = true;
        }
      });
  },
});

export const {
  checkUserLiveStatus,
  setLoginSuccess,
  handleUserLoginAsync,
  setLoginFail,
  removeAuthLoadingStatus,
  removeTrustlyRegPayload,
  removeTrustlyLoginPayload,
  removeTrustlyLoginStatus,
  removeTrustlyDepositStatus,
  removeTrustlyDepositPayload,
  handleAutoLoginAsync,
  setAutoLoginSuccess,
  setAutoLoginError,
  handleUserLogoutAsync,
  setLogoutSuccess,
  setLogoutError,
  handleSessionRefreshAsync,
  setSessionRefreshSuccess,
  setSessionRefreshError,
  handleRegisterUserAsync,
  setRegisterSuccess,
  setRegisterError,
  generateDatabetTokenAsync,
  setDatabetSuccess,
  setDatabetError,
  cancelBonusOffer,
  setBonusOfferAlert,
} = authSlice.actions;

export default authSlice.reducer;
