/**
 * BonusTermsSlots - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";
// import ListItem from '@mui/material/ListItem';

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTermsSlots extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component={"span"}>
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i KASIINOBOONUSE TINGIMUSED</h1>
            Eestikeelne versioon 1.3, viimati uuendatud 13. vebruaaris 2025.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Siinsed kasiinoboonuse tingimused kehtivad Nubeti kasiino kohta.<br></br>
            <br></br>
            1.2. Siinsetes kasiinoboonuse tingimustes nimetatud kasiinoboonust pakub Nubet veebisaidil nubet.com, mida haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registrijärgne aadress
            Roseni 10–135, 10111 Tallinn, Eesti (edaspidi: <b>Nubet</b> või <b>ettevõte</b>). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud
            10.07.2022) ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet võib teatavate tingimuste täitmise korral anda teile mis tahes tüüpi kasiinoboonuskrediiti või muud tüüpi boonuseid, nagu tasuta keerutusi (edaspidi: <b>boonusraha</b>, <b>boonus</b> või{" "}
            <b>kasiinoboonus</b>). Iga antud kasiinoboonuse puhul kehtivad boonuse
            kasutamisega seotud panustamistingimused (edaspidi: <b>panustamine</b>), millest teavitatakse mängijat varem veebisaidi ja e-posti teel. Kõik antud kasiinoboonused kantakse mängukontole automaatselt
            boonusrahana, mida reguleerivad kasiinoboonuse tingimustes määratletud reeglid.<br></br>
            <br></br>
            <h3 id="Generalbonus">2. KASIINOBOONUSE ÜLDTINGIMUSED</h3>
            2.1. Siinsed kasiinoboonuse üldtingimused kehtivad Nubeti antud mis tahes tüüpi kasiinoboonuste kohta.<br></br>
            <br></br>
            2.2. On ettevõttel õigus oma ainuäranägemise järgi keelata üksikutel mängijatel boonust kasutamast. Ettevõte võib oma
            ainuäranägemisel tühistada mis tahes boonuse ja sellega seotud võidud, kui ettevõte avastab, et mängija ei järgi boonusereegleid. Ettevõte võib igal ajal ilma ette teatamata muuta boonuse tingimusi või
            lõpetada selle pakkumise.<br></br>
            <br></br>
            2.3. Nubeti kasiinoboonus kehtib ja seda tuleb kasutada teatava kampaaniaperioodi jooksul. Kui seda ei kasutata, siis boonus aegub ja seda ei saa hiljem rakendada.<br></br>
            <br></br>
            2.4. Nubeti kasiinoboonus on saadaval ainult üks kord kliendi, mängijakonto, majapidamise, sissemakse tegemiseks kasutatud ülekandemeetodi ja/või konto omaniku, ühise arvuti (ülikool, töökoht, internetikohvik
            jne) ja/või IP-aadressi kohta.2.5. Nubeti kasiinoboonust ei tohi kombineerida teiste boonustega, kui sõnaselgelt ei ole teisiti märgitud, ega kasutada koos muude kampaaniatega.<br></br>
            <br></br>
            2.6. Nubeti kasiinoboonuse kampaaniapakkumise, mis saadetakse e-kirjaga, võib lunastada ainult boonuse e-kirja algne saaja. Boonuse saanud ja seda kasutav e-posti aadress peab ühtima mängijakonto loomisel
            kasutatud e-posti aadressiga. Nubet jätab endale õiguse uurida, kas boonuskood saadeti isiklikult kasiinoboonuse pakkumist kasutanud mängukonto omanikule mängukontol kasutatud e-posti aadressile. Kui seda
            reeglit rikutakse, võidakse boonus ja sellega seotud võidud konfiskeerida.<br></br>
            <br></br>
            2.7. Nubeti kasiinoboonus, mis on seotud sissemakse tegemisega, kehtib ainult Nubeti pakutavate maksemeetodite kasutamise korral ega ole kasiinoboonuse pakkumisest maksemeetodina välistatud. Kontoomanik ja
            maksemeetodi omanik peavad olema samad. Mängukonto krediidijääki, mis koosneb ainult mängija tehtud sissemaksetest (mitte boonustest) ja mis seega ei ole seotud boonuse tingimustega, nimetatakse
            pärisrahajäägiks (edaspidi: pärisrahajääk).<br></br>
            <br></br>
            2.8. Nubeti kasiinoboonus kajastub teie mängukonto jäägis. Kui kasiinoboonus on panustatud, ei saa kontojääki välja võtta ega konverteerida seda pärisrahajäägiks enne, kui panustamine on lõpetatud, nagu on
            sätestatud boonuse tingimustes.<br></br>
            <br></br>
            2.9. Ainult antud boonuse suhtes kohaldatakse panustamise nõuet.<br></br>
            <br></br>
            2.10. Kui ei ole sätestatud teisiti, tuleb saadud kasiinoboonus enne selle väljamaksmist või väljamaksmise üritamist panustada vähemalt 30 korda. Boonusrahaga teenitud võite ei maksta välja enne, kui boonuse
            panustamise tingimused on täidetud. Kui panustamisnõue on käimas, on tagasivõtmised üldiselt blokeeritud. <br></br>
            <br></br>
            2.11. Kui ei ole sätestatud teisiti, tuleb boonuse panustamise nõuded täita 7 päeva jooksul pärast boonuse saamist. Kui boonuse tingimusi ei täideta õigel ajal, jätab Nubet endale õiguse tühistada boonuse
            summa ja kõik sellest tulenenud võidud.<br></br>
            <br></br>
            2.12. Kui kasutajale antakse tasuta keerutusi, on nende minimaalne väärtus 0,10 eurot keerutuse kohta, kui ei ole märgitud teisiti. Keerutus, mida ei kasutata määratud perioodi jooksul, kaotab kehtivuse. Kõik
            tasuta keerutustest saadud võidud loetakse kasiinoboonusega seotud krediidiks ja need peavad olema kooskõlas panustamisnõuetega.<br></br>
            <br></br>
            2.13. Nubet jälgib, kas boonuseid kasutatakse ebahariliku mängumustri osana.<br></br>
            <br></br>
            2.14. Kasiinoboonust väärkasutades rikutakse boonuse tingimusi. Nubet eeldab, et boonuse tingimusi on rikutud järgmistel juhtudel:<br></br>
            <br></br>
            <ListItem>sama isik kasutab boonust mitu korda või boonust kasutatakse mitu korda sama IP-aadressi all või seadmes;</ListItem>
            <ListItem>kahtlustatakse, et boonuspakkumist kasutab organisatsioon.</ListItem>
            <br></br>
            2.15. Kui Nubet kahtlustab ebaharilikku mängimist või sõltuvuskäitumist, jätab ettevõte endale õiguse konfiskeerida kõik boonusega saadud võidud, mida ei ole panustatud, välja arvatud kontole kantud
            pärisraha. Sellises olukorras tagastatakse pärisraha.<br></br>
            <br></br>
            2.16. Boonust ei kanta kontole, kui te ei tohi Nubeti pakkumises osaleda või pakkumine on peatatud.<br></br>
            <br></br>
            2.17. Kasiinoboonus kehtib kuni panustamise lõpuni või boonuse tühistamiseni.<br></br>
            <br></br>
            2.18. Nubet jätab endale õiguse boonust ja kampaania kestust igal ajal muuta, peatada või tühistada.<br></br>
            <br></br>
            2.19. Panustamisnõudeid ei täida panused, mis tehakse järgmistes mängudes: Absolootly Mad Mega Moolah, Agent Royale, Age of Conquest, Astro Legends Lyra & Erion, Blazing Bull 2, Bakers Treat, Book Of 99,
            Castle Builder II, Cauldron, Double Dragons, Cazino Cosmos, Dwarf Mine, EggOmatic, Eye Of The Kraken, Gemix, Goblins and Gemstones, Magic of Sahara, Mad Monsters, Reel Rush II, Dynamite Riches, 11 Champions,
            Victoria Wild, Ducks Till Dawn, The Wish Master, Viking Runecraft, White Rabbit, Sea Hunter, Mystery Joker 6000, Extra Chilli, Wild Swarm, Dark Vortex, Jingle Spin, Zombie Hoard, Mahjong 88, Le Kaffee Bar,
            Narcos, Village People Macho Moves, Solar Queen, Razor Shark, Tiki Mania, Jewel Scarabs, Crystal Cavern, Blazing Bull, Solar Temple, Wilderland, Bronco Spirit, Hellcatraz, Football glory, All Star Knockout
            Ultra Gamble, Marching Legions, Banana Drop, Maui Millions, Lil Devil, Bombuster, Jungle Gorilla, 5 Families, Rage of the Seas, Lucky Fridays, Solar King, Emerald King, Hoard of Poseidon, Golden Tsar,
            Leprechauns Magic Megaways, Augustus, Jingle Bells Power Reels, Sylvan Spirits, Golden Stallion, Frost Queen Jackpots, Rome The Golden Age, Rome The Golden Age, Pirates Plenty MegaWays, Pearls Of India, The
            Hot Offer, Tower Quest, Rage To Riches, Robin Hood Shifting Riches, Scudamores Super Stakes, Scrooge, Serengeti Kings, Spina Colada, Sweet Alchemy, Tuts Twister, Vikings Go Berzerk, Wolf Hunters.<br></br>
            <br></br>
            2.20. Kohaldatakse Nubeti tingimusi.<br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTermsSlots));
