/**
 * TermsConditions - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class TermsConditions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM GENERAL TERMS AND CONDITIONS</h1>
            English version 1.4 Last update 19.12.2024.<br></br>
            <br></br>
            <h3 id="General">1. GENERAL INFORMATION</h3>
            1.1. The Nubet.com website (hereinafter referred as the <b>“Website”</b>) is operated by Vana Lauri OÜ (the <b>“Company”</b>, <b>“Vana Lauri”</b> or the <b>“Operator”</b>), a company registered in Estonia
            under company registration code 16427120 having its registered address at Roseni 10-135, 10111 Tallinn, Estonia.<br></br>
            <br></br>
            1.2. Vana Lauri is licensed and supervised by The Estonian Tax and Customs Board (<b>“EMTA”</b>). Vana Lauri is holding the licenses [HKT000066, HKT000067] for commercial online gambling to provide sports
            betting and casino games in accordance with the Estonian Law.<br></br>
            <br></br>
            1.3. All references to games in Nubet.com General Terms and Conditions (“Terms and Conditions”) shall refer to betting, casino games and other games available on the Website (hereinafter collectively referred
            as the <b>“Games”</b> or the <b>“Services”</b>). These Terms and Conditions apply to your use of the Games on the Website.<br></br>
            <br></br>
            1.4. These Terms and Conditions are governed by Estonian law and are displayed on the Jokeri.com website in more than one language. The official language of these Terms and Conditions for Estonian Players is Estonian. The official language of these Terms and Conditions Players other than Estonian is English.<br></br>
            <br></br>
            1.5. Due to changes to regulatory obligations and responsibilities, technical, and / or business reasons, the Company may from time to time need to amend these Terms and Conditions. If amendments are made to
            these Terms and Conditions, the amendments will always be informed separately on the Website.<br></br>
            <br></br>
            1.6. Players who register to use the services of the Website (<b>“Registered Player’s”</b> or <b>“Registered Player”</b>) will be separately informed about any changes made to these Terms and Conditions once
            they login to their account. During the log-in process, a pop-up window will appear, and the Registered Player must agree on the changes by ticking the box “I hereby agree on the updated Terms and Conditions”
            to continue using the Website.<br></br>
            <br></br>
            1.7. Any changes to these Terms and Conditions will enter into force from the moment they are uploaded and published on the Website and are generally visible to the players on the Internet.<br></br>
            <br></br>
            1.8. If the Registered Player uses the Games, consisting of a range of sports betting games, casino games, and other game products provided on the Website after the changes have been published, this will be
            interpreted as accepting the updated Terms and Conditions.<br></br>
            <br></br>
            1.9. If the Registered Player does not agree with the changes made to the Terms and Conditions, the Registered Player should immediately stop using the Services. If the Registered Player does not confirm the
            changes by ticking the box mentioned above, the Registered Player will not be eligible to continue using the Services.<br></br>
            <br></br>
            1.10. The contents published on the Website is subject to copyright law. All material found on the page is for non-commercial personal use only. The use of the Services does not give the Registered Player any
            intellectual property rights concerning the contents available on the Website. It is forbidden to copy and use any information, logos, and images on the Website.<br></br>
            <br></br>
            1.11. The Company reserves the right to restrict the use of the Website at any time without explanation if there is an indication that the customer has violated these provisions.<br></br>
            <br></br>
            <h3 id="Parties">2. PARTIES TO THE AGREEMENT</h3>
            2.1 The Operator shall open a personal account for each Registered Player (<b>“Player’s Account”</b> or <b>“Player Account”</b>) free of charge.<br></br>
            <br></br>
            2.2 The Player’s Account is intended for administration of the Registered Player’s gaming expenses and/or bets and for refunding and payment of winnings by the Operator.<br></br>
            <br></br>
            2.3 The Operator accepts only natural persons over the age limit of minimum age required to play the provided games legally as Registered Players.<br></br>
            <br></br>
            2.4 Registered Players cannot be registered in the name of a company.<br></br>
            <br></br>
            2.5 The Operator always verifies that the Registered Player has reached the legal minimum age required to play on the Website. Verification will be carried out to ensure that minors cannot play. The age limit
            may vary from country to country. Typically, the age limit is set to 18 years but for example, the age limit for playing games of chance in Estonia is 21 years. A person under 21 years of age located in
            Estonia shall not play a game of chance, a game of chance organized as remoted gambling or game of skill organized as remote gambling.<br></br>
            <br></br>
            2.6 The Operator can deny player's registration or not allow Registered Player to play anymore if the verification or further verification request is not sufficient. Failure from the Registered Player to
            oblige with such request within a reasonable time may result in closure of the Player’s Account.<br></br>
            <br></br>
            2.7 The Registered Player understands that in some jurisdictions online gambling may be illegal. If suspected illegal or fraudulent activity is detected while a VPN or proxy service is used, the Company has
            the right to block the relevant account and withhold the funds. The Company does not intend to provide any services to Registered Players which could conflict the legislation of the Registered Players’
            jurisdiction. The Registered Player uses the Services at his own choice. The Registered Player confirms, warrants, and agrees that use of the Services is in accordance with the laws of his jurisdiction. The
            Company is not responsible for any illegal use or unauthorized use of the Services by the Registered Player.<br></br>
            <br></br>
            2.8 Online casino operator licensed in Estonia shall not accept customers from Afghanistan, Albania, Algeria, American Samoa, Angola, Anguilla, Antigua & Barbuda, Argentina, Armenia, Aruba, Australia,
            Azerbaijan, Bahamas, Bangladesh, Barbados, Belgium, Belize, Benin, Bermuda, Bhutan, Bonaire(Sint Eustatius and Saba), Bosnia and Herzegovina, Botswana, Bouvet Island, British Indian Ocean Region, Bulgaria,
            Burkina Faso, Burundi, Cambodia, Cameroon, Cape Verde, Cayman Islands, Central African Republic, Chad, Christmas Island, Coconut Islands, Colombia, Comoros, Congo (Democratic Republic), Cook Islands, Costa
            Rica, Côte d'Ivoire, Croatia, Cuba, Curacao, Cyprus, Czech Republic, North Korea, Denmark, Djibouti, Dominica, Dominican Republic, Ecuador, Egypt, El Salvador, Equatorial Guinea, Eritrea, Ethiopia, Falkland
            Islands, Faroe Islands, Fiji, France, Guiana, French Polynesia, French Southern Regions, Gabon, Gambia, Ghana, Gibraltar, Greece, Greenland, Grenada, Guadeloupe, Guam, Guatemala, Guinea, Guinea-Bissau,
            Guyana, Haiti, Heard, Holy See (Vatican City State), Honduras, Hong Kong, Hungary, Iran, Iraq, Israel, Italy, Jamaica, Jordan , Kazakhstan, Kuwait, Kirgizstan, Laos, Latvia, Lesotho, Liberia, Libya,
            Lithuania, Madagascar, Malawi, Malaysia, Maldives, Mali, Marshall Islands, Martinique, Mauritania, Mauritius, Mayotte, Mexico, Micronesia, Montenegro, Montserrat, Mozambique, Myanmar, Namibia, Nauru, Nepal,
            Netherlands, Netherlands Antilles, New Caledonia, Nicaragua, Niger, Nigeria, Niue, Norfolk Island, North Korea, Northern Mariana Islands, Oman, Pakistan, Poland, Puerto Rico, Réunion, Romania, Rwanda,
            Saint-Barthelemy, Saint Helena Islands ascension and Tristan da Cunha Islands, Saint Kitts & Nevis, Saint Lucia, Saint-Mart French part, Saint-Pierre and Miquelon, Saint Vincent and the Grenadines, Samoa, San
            Marino, Sao Tome and Principe, Senegal, Seychelles, Sierra Leone, Singapore, Sint Maarten, Slovakia, Slovenia, the Solomon Islands, Somalia, South Georgia and the Southern Sandwich Islands, South Sudan,
            Spain, Sri Lanka, Palestinian State, Sudan, Surinam, Spitsbergen and Jan Mayen, Swaziland, Switzerland, Syria, Taiwan, Tajikistan, Tanzania, Faroe Islands, Timor-Leste, Togo, Tokelau, Tonga, Trinidad and
            Tobago, Tunisia, Turkey, Turkmenistan, Turks and Caicos Islands , Tuvalu, Belarus, U.S. Virgin Islands, Uganda, United Kingdom, U.S. Minor Outlying Islands, The United States of America and all its bases,
            military bases and territories, Uzbekistan, Vanuatu, Venezuela, British Virgin Islands, Wallis and Futuna, Western Sahara, Yemen, Antarctica, Macedonia, Moldova, Mongolia, Kenya, Kiribati and Lebanon.
            <br></br>
            <br></br>
            2.9 If a Registered Player violates the above-listed country-specific rule, the Operator reserves the rights to suspend the Registered Player’s Player Account immediately and withhold all money transfers from
            and to the Player Account.<br></br>
            <br></br>
            2.10 Subject to the Company’s anti-money laundering requirements and relevant responsible gambling obligations, the Company may also close or suspend the Player Account.<br></br>
            <br></br>
            2.11 Each Registered Player may only have one Player Account. Violation of this rule may result in the closure of all the Player Accounts registered by the Registered Player and the Company shall only pay
            back that which remains of the deposits made by the Registered Player.<br></br>
            <br></br>
            2.12 The Operator reserves right to refuse registration without explanation.<br></br>
            <br></br>
            <h3 id="Playeraccount">3. USING A PLAYER ACCOUT AND PLAYER RESPONSIBILITY </h3>
            3.1. By accepting these Terms and Conditions the Registered Player hereby confirms they do not act in the name of another person.<br></br>
            <br></br>
            3.2. Either party of this Agreement may close the Player Account immediately, temporarily or permanently, if the other party fails to fulfil its obligations under these Terms and Conditions. Payout of any
            remaining credit shall be governed by the provisions of these Terms and Conditions. Temporary or permanent closure of Player Account does not release the parties from their legal rights and obligations.
            <br></br>
            <br></br>
            3.3. The Registered Player also hereby accepts that they do not have the right to allow third parties, whether accidentally or intentionally, whether minors or other persons, to log into their Player Account.
            The Company shall not be held accountable for the Registered Player’s failure, including acts of negligence and / or willful misconduct, to comply with this provision. Access to the customer account is only
            possible with a username and password, and it is the Registered Player's responsibility to ensure that they are only known to the Registered Player and that they are not, for instance, saved on a device that
            others have access to, or written down somewhere where they can end up in another person's possession.<br></br>
            <br></br>
            3.4. The Company may ask the Registered Player to change their password or temporarily suspend the Registered Player’s Player Account if there is reason to suspect breach of the Company's information security
            policy or that the security of the game account has been compromised.<br></br>
            <br></br>
            3.5. Subject to mandatory regulations, and except in cases of gross negligence, willful misconduct, fraud or fraudulent activity by the Company, Company’s liability for any damages (direct or indirect) or
            losses caused by the Registered Player’s use of the Services are excluded.<br></br>
            <br></br>
            3.6. Funds shall only be transferred and deposited from and to the same account informed during the registration and verification process. The use of digital aids such as robots is prohibited.<br></br>
            <br></br>
            3.7. The Registered Player is not allowed to transfer any funds through a third-party account.<br></br>
            <br></br>
            3.8. The Company and its partners, such as affiliates, are never responsible for compensating for a Registered Player’s losses on the Services. The Company offers games of chance where the risk of loss falls
            entirely for the Registered Player when he or she decides to play the games of chance provided on the Website.<br></br>
            <br></br>
            3.9. The Company does not make automatic reports of the Registered Players’ winnings to the tax authorities. If the Registered Player is obliged to declare their profits to a tax authority, it is the
            Registered Player’s sole responsibility to comply with such obligation.<br></br>
            <br></br>
            3.10. The Company will keep all relevant information about the game, including its rules and likelihood of winning, easily accessible to the Registered Players.<br></br>
            <br></br>
            3.11. The Company reserves the right to refuse bets or stakes at any time without explanation.<br></br>
            <br></br>
            3.12. If the Registered Player has any questions, they can contact the Operator‘s customer service team at <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate> at any time.<br></br>
            <br></br>
            3.13. The Company reserves the right to remove or modify any service provided to the Registered Player, or its Website, at any time and without notice, and to add or remove offers on its Website.<br></br>
            <br></br>
            <h3 id="Harmful">4. HARMFUL ACTIVITIES</h3>
            4.1. The Operator is obliged to monitor money laundering and other financing referring to crime. Registered Players are obligated to assist the Operator in all queries and surveys about them.<br></br>
            <br></br>
            4.2. If you become aware of any suspicious activity on the website, you must immediately report it to the Operator.<br></br>
            <br></br>
            <h3 id="Transactions">5. FINANCIAL TRANSACTIONS</h3>
            5.1. The Company accepts deposits in Euros (EUR).<br></br>
            <br></br>
            5.2. An account statement with all financial transactions is available to the Registered Player once logged in to the Website.<br></br>
            <br></br>
            5.3. Whenever possible the Registered Player must use the same payment account for withdrawals as they made the deposit.<br></br>
            <br></br>
            5.4. The Registered Player is not entitled to interest on the funds in their Player Account, regardless of how long they keep money in their Player Account.<br></br>
            <br></br>
            5.5. In line with the mandatory obligatory requirements, the Operator ensures the acceptance of bets and payments transferred to the account of the Operator for the making of bets only from the settlement
            account of the same Registered Player or from a Registered Player in the gaming location of the same Operator and; the making of distributions only to the same settlement account, from which the Registered
            Player has transferred a payment to the account of the gambling operator for the making of bets in gambling.<br></br>
            <br></br>
            5.6. The Operator will credit the deposited funds to the Players Account once the Operator has received authorization from the payment provider/credit card company/bank that the Registered Player has
            deposited the funds.<br></br>
            <br></br>
            5.7. The principle of the Company is to credit the Registered Player’s Player account the winnings almost immediately. In case of suspicion of criminal activity or other unauthorized activity the Company
            reserves the right to suspend crediting of the winnings until the investigation result.<br></br>
            <br></br>
            5.8. The payment period may vary from a few minutes to 24 hours after withdrawal request has been processed.<br></br>
            <br></br>
            5.9. For larger winnings over 20,000 EUR, a reasonable payment schedule can be negotiated separately with the Registered Player. For example, the payment of 100,000 EUR in parts of payments of 20,000 EUR over
            five days can be considered as such.<br></br>
            <br></br>
            5.10. The maximum payout amount per betting slip is 100,000 EUR.<br></br>
            <br></br>
            5.11. The maximum aggregate winnings per Registered Player is limited to 200,000 EUR per each week (Monday 00:00 EET to Sunday 23:59 EET).<br></br>
            <br></br>
            5.12. All bet selections are subject to pre-imposed limits which may be lower than the limits above.<br></br>
            <br></br>
            5.13. The Company does not charge its Registered Players for money transfers. It is the Registered Player’s responsibility to find out if the service provider he or she uses charges him or her a transfer fee
            in connection with the money transfers.<br></br>
            <br></br>
            5.14. The Complany requires at least a one-time rollover of any deposit (a deposit risked at least one time) and may reject withdrawals if this is not completed.<br></br>
            <br></br>
            5.15. Registered Player’s funds are held in a separate account from the Company’s own funds and accordingly the funds in a Player Account are protected if the Company becomes financially insolvent.<br></br>
            <br></br>
            5.16. The Company has the right to suspend the Registered Player’s deposits and withdrawals if the use of the Player Account requires verification or further verification of the Registered Player’s identity,
            age, or other information (<b>“Verifications”</b>).<br></br>
            <br></br>
            5.17. It is at the Operator’s sole consideration when Verifications are carried out.<br></br>
            <br></br>
            5.18. In accordance with the relevant anti-money laundering requirements the Company is obliged to make sure that the Registered Player proves his age, identity, personal identification number (social
            security number), address and source of funds.<br></br>
            <br></br>
            5.19. As per AML policy the Verifications are carried always when i) upon establishment of a Business Relationship; ii) upon payment of winnings, making of a bet, or both on occasions where the sum given or
            receivable by the Registered Player is at least 2,000 EUR or an equivalent sum in another currency, regardless of whether the monetary obligation is performed as a single payment or as several related
            payments over a period of up to one month; iii) upon verification of information gathered while applying due diligence measures or in the case of doubts as to the sufficiency or truthfulness of the documents
            or data gathered earlier while updating the relevant data and; iv) upon suspicion of Money Laundering or Terrorist Financing.<br></br>
            <br></br>
            5.20. If the Verification requires contact with the Registered Player and any further clarifications, the Player Account can be suspended pending the review. The most common reason for further verification
            request and additional AML measures is completely exceptionally large numbers of games.<br></br>
            <br></br>
            5.21. A person can only become a Registered Player if he or she is the legal owner of the funds they use, and the funds have not been acquired through criminal activities.<br></br>
            <br></br>
            5.22. The Company will process any withdrawal requests within one to six (1-6) working days from receipt of the request. It is possible that timeframe will be extended for reasons such as KYC procedures or
            additional verification on the withdrawal request or any other necessary verification prior to processing of the withdrawal.<br></br>
            <br></br>
            5.23. When playing a game the Registered Player must acknowledge that products are consumed immediately and the Company cannot return, cancel or refund products during the game or afterwards.<br></br>
            <br></br>
            5.24. Transferring funds from the Registered Player’s Player Account to another Player Account is prohibited.<br></br>
            <br></br>
            5.25. The Company reserves the right to suspend withdrawals, confiscate the Player Account balance, void bets, restrict the Registered Player's Player Account, if the Company has a reasonable cause to
            suspect, that:<br></br>
            The Registered Player is involved in fraud; the integrity of match/event has been compromised; the Registered Player is involved in match fixing; the Registered Player has been involved in using of a
            technical system which purpose is to manipulate or influence in to the outcome of a product (game, game round, bet); the Registered Player is acting as part of the group placing a series of bets collectively
            together to gain financial benefit; any other unlawful or criminal activity; or activities that are contrary to these terms and conditions.<br></br>
            <br></br>
            5.26. All fraudulent, criminal or suspicious activities are reported to the relevant authorities.<br></br>
            <br></br>
            5.27. A valid bet will be confirmed with a betting slip number. Betting slip number is available by logging in to Registered Player’s Player Account, under “My Bets”. <br></br>
            <br></br>
            5.28. Played casino games, game rounds, and placed stakes are available by logging in to Registered Player’s Player Account, under menu “Statement”.<br></br>
            <br></br>
            <h3 id="Duty">6. DUTY OF CARE, SELF-RESTRICTIONS, AND LIMITS</h3>
            6.1. Registered Players may restrict their play or close their game account independently by using provided restriction tools on their player account, or by contacting the Company via support chat or email.
            The Company offers restrictions, limits, and self-exclusion opportunity to its Registered Players in accordance with the regulations in force.<br></br>
            <br></br>
            6.2. Registered Players can exclude themselves from all games that require registration with the gambling companies that have a license to conduct money games in Estonia by EMTA’s national suspension registry
            at any time. The Registered Player can apply for a self-exclusion at https://www.emta.ee/en/private-client/e-services-tax-literacy/registers-inquiries/setting-restrictions-gambling.<br></br>
            <br></br>
            6.3. The gambling authority shall keep a registry of players who are excluded from gambling and the Company will exclude such players on the registry from its gambling offer. As a result, it is possible that
            Vana Lauri will deny player's registration or will not allow Registered Players to play anymore.<br></br>
            <br></br>
            6.4. Registered Players shall actively specify and confirm their stakes when gambling.<br></br>
            <br></br>
            6.5. The Company nor any gambling agent working on behalf of the Company will not offer or provide credit for gambling stakes in any circumstances<br></br>
            <br></br>
            6.6. Registered Players may set various type of limits on their game account.<br></br>
            <br></br>
            6.7. After registration the Website offers opportunity to set loss limits. Limits are also accessible under Settings - Limits tab.<br></br>
            <br></br>
            <h3 id="Disputes">7. DISPUTES</h3>
            7.1. If the Registered Player has a complaint or wishes to lodge a claim against the Company regarding the Services, the Registered Player may submit such claim through any means, in any shape and / or form.
            However, we recommend that such matters are submitted via chat or via an email at support@nubet.com. The Company will try to process complaints as fast as possible, in any case within 15 days.<br></br>
            <br></br>
            7.2. If there are software errors in the games available on the Website, the Company may consider cancelling the draws to the extent that the error has affected the results. Any attempted manipulation,
            successful or unsuccessful, interrupts the Registered Player's gaming and is likely to lead to legal action.<br></br>
            <br></br>
            7.3. In the event of a temporary interruption in the Services, all bets, wagers, and other stakes placed to the Services during the temporary interruption will be fully refunded to the Registered Players. If
            the Registered Player stops playing, the unfinished game continues when the Registered Player returns to play.<br></br>
            <br></br>
            7.4. If you cannot reach an agreement with us or are not satisfied with the resolution of your matter, you may refer to an Alternative Dispute Resolution (“ADR”) entity. The following ADR:s are recommended by
            the Company:<br></br>
            The European Online Dispute Resolution (“ODR”), a platform provided by the European Commission as an access to dispute resolution. The detailed information and access to the ODR platform provided by the
            European Commission can be found here: https://ec.europa.eu/consumers/odr/main/?event=main.complaints.screeningphase<br></br>
            <br></br>
            <h3 id="Limitation">8. LIMITATION OF LIABILITY</h3>
            8.1. Except in cases of gross negligence or willful misconduct, or due to mandatory rules, the Company shall not be liable for any software-related issues and errors that have prevented the Registered Player
            from accessing the Website or suspends play. In case of communication error, if the placed bet is not received by the Website’s servers, it will not be wagered, and the amount of the bet will also not be
            deducted from the Player Account. In case of any other error in the software or the random number generator, the bet will be voided, and the bet amount will be refunded to the Registered Player.<br></br>
            <br></br>
            8.2. If the Company incorrectly pays winnings to the Registered Player due to an error, the money will be considered to still belong to the Company and thus it will be deducted from the Player’s Account. If
            the Registered Player has already withdrawn the money, the Registered Player must inform the Operator’s customer service immediately to settle the matter and to avoid further consequences.<br></br>
            <br></br>
            8.3. The Company and its employees and other personnel related to the Company do not guarantee that Company’s software will always function flawlessly.<br></br>
            <br></br>
            8.4. The Registered Player confirms being an individual who has reached the minimum legal age for gambling on the Website and has full legal capacity, in accordance with the country where he or she resides or
            lives permanently. Further, the Registered Player understands the significance of his actions, agrees that the choice of the use of the Services is exclusively the Registered Player’s personal choice and
            understands that the Registered Player manages funds included in the Player’s Account.<br></br>
            <br></br>
            8.5. The Company is not responsible for the content on any Internet sites, access to which can be obtained through the Website.<br></br>
            <br></br>
            8.6. The limitations and exclusions of liability in these Terms and Conditions do not apply to the extent such limitations or exclusions are not applicable under the applicable law.<br></br>
            <br></br>
            <h3 id="Protection">9. DATA PROTECTION</h3>
            9.1. The Company appreciates the integrity and confidentiality of Registered Players personal data as defined in Nubet.com’s Privacy Policy (<b>“Privacy Policy”</b>). The Company collects, processes, and
            shares only the information that is necessary for the provision of the Services. The Company processes personal information provided by the Registered Player strictly in accordance with its Privacy Policy and
            applicable data protection laws and regulations. <br></br>
            <br></br>
            9.2. By accepting the Company’s Privacy Policy and providing the Company their personal information, the Registered Player agrees to processing of their personal data for the purposes described in the Privacy
            Policy. <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(TermsConditions));
