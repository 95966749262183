import { LoadingButton } from "@mui/lab";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { Translate } from "react-redux-i18n";

const ConfirmLimits = ({ open, handleClose, isLoading, monthly, weekly, daily, handleConfirm }) => {
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Stack p={2} gap={2}>
        <Typography variant="body1">
          <Translate value="labels.confirm_alert_limits" />
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <Translate value="labels.limits_requested_changes" />:
        </Typography>
        <Stack>
          {daily && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <Translate value="labels.daily" />
              </Typography>
              <Typography variant="body2" color="primary">
                {daily} EUR
              </Typography>
            </Stack>
          )}
          {weekly && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <Translate value="labels.weekly" />
              </Typography>
              <Typography variant="body2" color="primary">
                {weekly} EUR
              </Typography>
            </Stack>
          )}
          {monthly && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">
                <Translate value="labels.monthly" />
              </Typography>
              <Typography variant="body2" color="primary">
                {monthly} EUR
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2} mt={2}>
          <Button onClick={handleClose} size="medium" variant="contained" color="warning">
            <Translate value={`labels.button_decline`} />
          </Button>
          <LoadingButton onClick={handleConfirm} loading={isLoading} type="submit" variant="contained" color="primary">
            <Translate value="labels.limits_confirm" />
          </LoadingButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default ConfirmLimits;
