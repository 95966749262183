/**
 * Actions for all api requests balance outright
 */

import api from "../apis/nubet";
import apiCatalog from "../apis/nubetCatalog";
import authHeader from "../apis/auth-header";
import acceptLanguage from "../apis/accept-language";
import { reset } from "redux-form";
import { v4 as uuid } from "uuid";

import {
  SET_LEAGUES,
  GET_SPORTS_LABELS,
  GET_FEATURED_GAMES,
  GET_PRECANNED_BETS,
  GET_FEATURED_OUTRIGHTS,
  GET_FEATURED_SLIP,
  REMOVE_ENDED_FEATURED_GAME,
  REMOVE_ENDED_FEATURED_OUTRIGHT,
  REMOVE_ENDED_FEATURED_SLIP,
  GET_WP_CONTENT,
  GET_WP_NEWS_CONTENT,
  SAVE_LEAGUE_RESULTS,
  SELECT_LEAGUES,
  UPDATE_SELECTED_LEAGUE,
  SAVE_FAVORITE_LEAGUES,
  SAVE_TREEVIEW_LEAGUES,
  DELETE_SELECTED_LEAGUES,
  SET_LEAGUE_MENU_VALUE,
  UPDATE_OUTRIGHT_LEAGUE_ODDS,
  DELETE_ENDED_SLIP_GAMES,
  SAVE_GAMES,
  SAVE_OUTRIGHT_GAMES,
  SAVE_GAME_ALL_ODDS,
  GET_MARKET_LABELS,
  GET_MARKET_SPECIFIERS,
  SAVE_MARKET_SET_CONFIG,
  GET_SELECTION_LABELS,
  GET_SELECTION_VIEWER_LABELS,
  GET_SELECTION_PRINT_LABELS,
  GET_HC_LABELS,
  UPDATE_SCORES_IN_LEAGUE,
  UPDATE_ODDS_IN_LEAGUE,
  UPDATE_ODDS_IN_SLIP,
  UPDATE_ODDS_IN_TICKET,
  UPDATE_SCORES_IN_TICKET,
  UPDATE_BET_WITH_LOWER_PRICE,
  SET_ODDS_FORMAT,
  EXPAND_MULTI_LINE_ODDS,
  COLLAPSE_MULTI_LINE_ODDS,
  SAVE_ODDS_HISTORY,
  SELECT_SLIP_TEMPLATE,
  SAVE_SLIP_TEMPLATE,
  CREATE_SLIP_TEMPLATE,
  DELETE_SLIP_TEMPLATE,
  COPY_SLIP_TEMPLATE,
  IMPORT_SLIP_TEMPLATE,
  EXPORT_SLIP_TEMPLATE,
  EMPTY_SLIP_TEMPLATE,
  SAVE_BET,
  DELETE_BET,
  SAVE_TICKETS,
  SAVE_TICKET,
  POST_TICKET,
  POST_TICKET_LOADING,
  POST_TICKET_CASHOUT,
  SAVE_TICKET_FILTER,
  TOGGLE_MY_TICKETS_PANEL,
  GET_CASHOUT_AVAILABILITY,
  GET_USER_BONUSES,
  GET_USER_DETAILS,
  GET_BALANCE,
  GET_BALANCE_DETAILS,
  REGISTER_USER,
  REGISTER_USER_WITH_TRUSTLY,
  CHECK_TRUSTLY_DEPOSIT_STATUS,
  RESET_REGISTER_USER,
  UPDATE_USER,
  BLOCK_USER,
  UPDATE_PASSWORD,
  GET_FORGOTTEN_PASSWORD,
  GET_SUBSCRIPTIONS,
  // UPDATE_SUBSCRIPTIONS,
  SET_KYC_STATUS,
  SET_VALID_INSTRUMENT,
  SET_PENDING_CASHOUTS,
  GET_KYC_DETAILS,
  // UPLOAD_KYC_DOCUMENTS,
  GET_LIMITS,
  POST_WALLET_DEPOSIT,
  GET_WALLET_STATEMENT,
  RESET_WALLET_STATEMENT,
  LOAD_MORE_WALLET_STATEMENT,
  POST_WITHDRAW,
  SET_THEME,
  SET_LAYOUT,
  SET_MODE,
  SET_NAV_MODE,
  SET_MARKETS_LAYOUT,
  SET_URL,
  SET_HOST,
  SET_IS_MOBILE,
  SET_SSE,
  SET_FAVORITES_LAYOUT,
  SET_LEAGUE_GROUPING,
  SHOW_RESULTS_FIRST,
  SAVE_CASINO_FILTER,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_IN_FAIL_BLOCKED_USER,
  SIGN_OUT,
  SIGN_IN_NOT_CONFIRMED_EMAIL,
  CONFIRM_EMAIL_ADDRESS,
  RESEND_CONFIRM_EMAIL_ADDRESS,
  SET_ALERT,
  CLEAR_ALERT,
  SAVE_CASINO_GAMES,
  FILTER_CASINO_GAMES,
  SELECT_CASINO_GAME,
  DELETE_CASINO_GAME,
  GET_CASINO_GAME_GROUP_NAMES,
  SEARCH_CASINO_GAMES,
  GET_CASINO_GAMES_BY_GROUP_NAME,
  GET_USER_FAVORITE_CASINO_GAMES,
  SAVE_VENDORS,
  UPDATE_STAKE,
  UPDATE_LEAGUE_FILTER,
  GET_ACCOUNT_LIVECHECK,
  SAVE_AUTO_SLIP_UPDATE_STATUS,
  SET_MAINTENANCE_MODE,
  CLEAR_CASINO_SEARCH_RESULTS,
  UPDATE_FEATURED_GAMES,
  UPDATE_FEATURED_OUTRIGHTS,
  UPDATE_FEATURED_SLIP,
  SAVE_BETBUILDER_GAMES,
  GET_CUSTOMER_FREE_SPINS,
  REMOVE_ENDED_LEAGUE_GAMES,
  REMOVE_ENDED_OUTRIGHT_GAMES,
  SHOULD_CONFIRM_TERMS,
  CASINO_GAME_LOADING,
  CASINO_GAMES_LIST_LOADING,
  RESET_CASINO_GAMES_CACHE,
  UPDATE_LIMITS_STATUS,
  SET_LIVECHAT_WIDGET,
  UPDATE_BETBUILDER_GAMES,
  SHOULD_ALERT_ODDS_UPDATE,
  IS_TICKETS_LOADING,
  GET_TICKET_GAMES,
  IS_TICKET_GAMES_LOADING,
  HANDLE_LIMITS_ALERT,
  FEATURED_GAMES_LOADING,
  FEATURED_OUTRIGHTS_LOADING,
  FEATURED_SLIPS_LOADING,
  GET_PLAYERPROPS_GATALOG_GAMES,
  SET_EVENTS_SEARCH_RESULT,
  SET_EVENT_SEARCH_LOADING,
  SELECT_SEARCHED_LEAGUES,
  GET_COMBO_BOOST,
  DELETE_SEARCH_RESULTS,
  CASINO_FREE_SPINS_LOADING,
  GET_EXAMPLE_SEARCHES,
  REMOVE_POSTED_TICKET_STATE,
  GET_CASINO_SLIDER_GAMES,
  GET_CASINO_GAMES_BY_FEATURE,
  RESET_FEATURE_GAMES,
  GET_CASINO_GAMES_BY_PROVIDER,
  RESET_CASINO_PROVIDER_GAMES,
  GET_USER_FAV_GAMES_IDS,
  GENERATE_DATABET_TOKEN,
} from "./types";
import { calcGame, checkGameSession, checkOutrightGameSession } from "../helpers/gameFunctions";
import axios from "axios";
import { setTermsStatus } from "../redux-store/settings-reducer";

// format date

function formatDateToApi(date) {
  const ye = new Intl.DateTimeFormat("de", { year: "numeric" }).format(date);
  const mo = new Intl.DateTimeFormat("de", { month: "2-digit" }).format(date);
  const da = new Intl.DateTimeFormat("de", { day: "2-digit" }).format(date);

  // return (`${da}.${mo}.${ye}`);
  return `${ye}-${mo}-${da}`;
}

/**
 * Validate API response and show status as notification
 */

const validate = (reply) => {
  let alertRequired = false;
  let alertPayload = {};

  switch (reply?.data?.type) {
    default:
      alertPayload = {
        type: "error",
        payload: { error: "connection_error" },
        collection: "labels",
      };
      alertRequired = true;
      break;

    case "error":
    case "warning":
    case "success":
    case "info":
      alertPayload = {
        type: reply.data.type,
        payload: typeof reply.data.payload === "object" ? reply.data.payload : { [reply?.data?.type]: reply.data.payload },
        collection: reply.data.collection,
      };
      alertRequired = true;
      break;

    case "data":
      if (!reply.data.payload) {
        alertPayload = {
          type: "error",
          payload: "connection_error",
        };
        alertRequired = true;
      }
      break;
  }

  return {
    required: alertRequired,
    payload: alertPayload,
    stop: reply?.data?.type !== "success" ? true : false,
  };
};

const jsonCopy = (src) => {
  return JSON.parse(JSON.stringify(src));
};

export const setEventSearchResult = (query, lang, component) => async (dispatch) => {
  dispatch({
    type: SET_EVENT_SEARCH_LOADING,
    payload: true,
    component: (component ? component : "") + " -> setSearchedLeagues -> ",
  });
  const reply = await api.get(`/sports/search?text=${query}&&lang=${lang}`, {
    headers: acceptLanguage(),
  });
  dispatch({
    type: SET_EVENT_SEARCH_LOADING,
    payload: false,
    component: (component ? component : "") + " -> setSearchedLeagues -> ",
  });

  if (reply.data.type === "error") {
    dispatch({
      type: SET_EVENTS_SEARCH_RESULT,
      payload: {},
      component: (component ? component : "") + " -> setSearchedLeagues -> ",
    });
    return;
  }

  let response = reply?.data?.payload?.leagues && typeof reply.data.payload.leagues === "object" ? reply.data.payload.leagues : {};
  dispatch({
    type: SET_EVENTS_SEARCH_RESULT,
    payload: response,
    component: (component ? component : "") + " -> setSearchedLeagues -> ",
  });
  Object.keys(response).forEach((leaguekey) => {
    if (response[leaguekey]["games"]) {
      if (response[leaguekey]["league_outright_cnt"] > 0) {
        response[leaguekey]["hasOutright"] = true;
      }
      Object.keys(response[leaguekey]["games"])?.forEach((gameKey) => {
        const returnedGame = checkGameSession(response[leaguekey]["games"][gameKey], "all");
        if (returnedGame) {
          response[leaguekey]["games"][gameKey] = calcGame(returnedGame);
        }
      });
    }
    if (response[leaguekey]["sportCode"] === "TR") {
      response[leaguekey]["games"] = response[leaguekey]["outrightGames"];
    }
  });
  dispatch({
    type: SELECT_SEARCHED_LEAGUES,
    payload: response,
    component: (component ? component : "") + " -> setSearchedLeagues -> ",
  });
};

export const setLeagues = (leagues, leagueFilter, sportCode, lang, component, languageSwitching) => async (dispatch) => {
  if (leagueFilter === "search") return;
  dispatch({
    type: DELETE_SELECTED_LEAGUES,
    payload: null,
    component: (component ? component : "") + " -> setLeagues -> ",
  });
  lang = lang ? lang : localStorage.getItem("lang") ? localStorage.getItem("lang") : "fi";

  if (!leagues || leagueFilter === "live" || sportCode === "favorites" || languageSwitching) {
    const reply = await api.get(`sports/leagues`, {
      params: { lang },
      headers: acceptLanguage(),
    });

    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> setLeagues -> ",
      });
      if (alert.stop) return;
    }
    leagues = reply.data.payload;
  }

  // const outRightReply = await api.get(`outright/leagues`, {
  //   params: { lang }
  // });
  // const outRightAlert = validate(outRightReply);
  // if (outRightAlert.required) {
  //   dispatch({
  //     type: SET_ALERT,
  //     payload: outRightAlert.payload,
  //     component: (component ? component : '') + ' -> setOutrightLeagues -> '
  //   });
  // }

  // let outRightLeagues = outRightReply.data.payload;
  // let sportsLeagues = reply.data.payload;
  // let combiLeagues = {};

  // console.log(outRightLeagues);

  // for (let filter in sportsLeagues) {
  //   const sport = sportsLeagues[filter];
  //   const outrightKeys = outRightLeagues[filter] && outRightLeagues[filter] ? Object.keys(outRightLeagues[filter]) : [];
  //   const sportsKeys = sportsLeagues[filter] && sportsLeagues[filter] ? Object.keys(sportsLeagues[filter]) : [];
  //   const notIncludedList = [];

  //   outrightKeys?.forEach((item) => {
  //     const findExisting = sportsKeys.find((val) => val === item);
  //     if (!findExisting) {
  //       notIncludedList.push(item);
  //     }
  //     if (findExisting) {
  //       const notIncluded = {};
  //       Object.keys(outRightLeagues[filter][item])?.forEach((keyLg) => {
  //         if (!sport[item][keyLg]) {
  //           notIncluded[keyLg] = {...outRightLeagues[filter][item][keyLg], onlyOtright: true}
  //         }
  //       })
  //       sport[item] = {...sport[item], ...notIncluded}
  //     }
  //   })
  //   const outrights = {};
  //   notIncludedList?.forEach((keyCode) => {
  //     if (outRightLeagues[filter]) {
  //       outrights[keyCode] = {...outRightLeagues[filter][keyCode], onlyOutright: keyCode!=="TR"};
  //     }
  //   })

  //   if(outrights){
  //     combiLeagues[filter]={...sport, ...outrights};
  //   }else{
  //     combiLeagues[filter]= {...sport};
  //   }
  // }

  // leagues = combiLeagues;

  // leagues = { ...leagues, favorites: Cookies.getJSON('favoriteLeagues') };
  let favoriteLeagues = localStorage.getItem("favoriteLeagues") ? JSON.parse(localStorage.getItem("favoriteLeagues")) : {};

  for (let sportCode in favoriteLeagues) {
    if (Object.values(favoriteLeagues[sportCode])?.length === 0) {
      delete favoriteLeagues[sportCode];
    }
  }

  if (leagueFilter === "live" && sportCode === "favorites") {
    const liveGames = leagues["live"] ? leagues["live"] : {};
    for (let sportCode in favoriteLeagues) {
      let sportLeague = favoriteLeagues[sportCode];
      for (let leagueID in sportLeague) {
        if (liveGames[sportCode] && liveGames[sportCode][leagueID]) {
          sportLeague[leagueID]["visibleInFavorites"] = true;
        } else {
          sportLeague[leagueID]["visibleInFavorites"] = false;
        }
      }
    }

    leagues = {
      ...leagues,
      favorites: favoriteLeagues,
      visibleInFavorites: true,
    };
  } else {
    leagues = {
      ...leagues,
      favorites: favoriteLeagues,
      visibleInFavorites: false,
    };
  }

  leagues = { ...leagues, treeViewLeagues: {} };
  leagues = { ...leagues, results: leagues.all };

  leagueFilter = sportCode === "favorites" ? "favorites" : leagueFilter;
  if (!leagues[leagueFilter] || !Object.keys(leagues[leagueFilter])?.length) leagueFilter = "all";

  sportCode = sportCode === "favorites" || (sportCode && leagues[leagueFilter] && leagues[leagueFilter][sportCode]) ? sportCode : leagues[leagueFilter] ? Object.keys(leagues[leagueFilter])[0] : sportCode;
  leagueFilter = leagueFilter === "favorites" && sportCode !== "favorites" ? "all" : leagueFilter;

  leagues = { ...leagues, _sportCode: sportCode };
  leagues = { ...leagues, _leagueFilter: leagueFilter };

  dispatch({
    type: SET_LEAGUES,
    leagues: leagues,
    component: (component ? component : "") + " -> setLeagues -> ",
  });

  let autoLoadGames = leagueFilter === "live" ? true : false;
  let selectedLeagues = {};
  let lgsToSort = leagues[leagueFilter] && leagues[leagueFilter][sportCode] && autoLoadGames ? leagues[leagueFilter][sportCode] : [];
  lgsToSort?.forEach((lg) => {
    if (!selectedLeagues[lg.leagueId]) {
      selectedLeagues[lg.leagueId] = lg;
    }
  });
  // console.log(selectedLeagues);
  if (autoLoadGames && selectedLeagues) dispatch(getGames(leagueFilter, sportCode, selectedLeagues, true, component));

  if (leagueFilter === "live") {
    const liveGames = leagues[leagueFilter] ? jsonCopy(leagues[leagueFilter]) : {};
    const defaultLiveGames = liveGames[sportCode] ? liveGames[sportCode] : {};
    let payload = {};
    delete defaultLiveGames["gameCount"];
    delete defaultLiveGames["label"];

    payload[sportCode] = defaultLiveGames;

    dispatch(saveTreeViewLeagues(payload, component));
  }
};

export const getWalletBalanceDetails = (component) => async (dispatch) => {
  const reply = await api.get("wallet/balance", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getWalletBalanceDetails -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_BALANCE_DETAILS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getWalletBalanceDetails -> ",
  });
};

export const setLimitsAlert = (status, component) => (dispatch) => {
  dispatch({
    type: HANDLE_LIMITS_ALERT,
    payload: status,
    component: (component ? component : "") + " -> setLimitsAlert -> ",
  });
};

export const setOutrightLeagues = (leagues, leagueFilter, sportCode, lang, component) => async (dispatch) => {
  dispatch({
    type: DELETE_SELECTED_LEAGUES,
    payload: null,
    component: (component ? component : "") + " -> setLeagues -> ",
  });
  const outRightReply = await api.get(`outright/leagues?lang=${lang}`, {
    params: { lang },
  });
  const outRightAlert = validate(outRightReply);
  if (outRightAlert.required) {
    dispatch({
      type: SET_ALERT,
      payload: outRightAlert.payload,
      component: (component ? component : "") + " -> setOutrightLeagues -> ",
    });
    if (alert.stop) return;
  }
  let leagues = outRightReply.data.payload;

  let favoriteLeagues = localStorage.getItem("favoriteLeagues") ? JSON.parse(localStorage.getItem("favoriteLeagues")) : {};

  for (let sportCode in favoriteLeagues) {
    if (Object.values(favoriteLeagues[sportCode])?.length === 0) {
      delete favoriteLeagues[sportCode];
    }
  }

  if (leagueFilter === "live" && sportCode === "favorites") {
    const liveGames = leagues["live"] ? leagues["live"] : {};
    for (let sportCode in favoriteLeagues) {
      let sportLeague = favoriteLeagues[sportCode];
      for (let leagueID in sportLeague) {
        if (liveGames[sportCode] && liveGames[sportCode][leagueID]) {
          sportLeague[leagueID]["visibleInFavorites"] = true;
        } else {
          sportLeague[leagueID]["visibleInFavorites"] = false;
        }
      }
    }

    leagues = {
      ...leagues,
      favorites: favoriteLeagues,
      visibleInFavorites: true,
    };
  } else {
    leagues = {
      ...leagues,
      favorites: favoriteLeagues,
      visibleInFavorites: false,
    };
  }

  leagues = { ...leagues, treeViewLeagues: {} };
  leagues = { ...leagues, results: leagues.all };

  leagueFilter = sportCode === "favorites" ? "favorites" : leagueFilter;
  if (!leagues[leagueFilter] || !Object.keys(leagues[leagueFilter])?.length) leagueFilter = "all";

  sportCode = sportCode === "favorites" || (sportCode && leagues[leagueFilter] && leagues[leagueFilter][sportCode]) ? sportCode : leagues[leagueFilter] ? Object.keys(leagues[leagueFilter])[0] : sportCode;
  leagueFilter = leagueFilter === "favorites" && sportCode !== "favorites" ? "all" : leagueFilter;

  leagues = { ...leagues, _sportCode: sportCode };
  leagues = { ...leagues, _leagueFilter: leagueFilter };

  dispatch({
    type: SET_LEAGUES,
    leagues: leagues,
    component: (component ? component : "") + " -> setLeagues -> ",
  });

  let autoLoadGames = leagueFilter === "live" ? true : false;
  let selectedLeagues = leagues[leagueFilter] ? leagues[leagueFilter][sportCode] : {};

  if (autoLoadGames && selectedLeagues) dispatch(getGames(leagueFilter, sportCode, selectedLeagues, true, component));

  if (leagueFilter === "live") {
    const liveGames = leagues[leagueFilter] ? jsonCopy(leagues[leagueFilter]) : {};
    const defaultLiveGames = liveGames[sportCode] ? liveGames[sportCode] : {};
    let payload = {};
    delete defaultLiveGames["gameCount"];
    delete defaultLiveGames["label"];

    payload[sportCode] = defaultLiveGames;

    dispatch(saveTreeViewLeagues(payload, component));
  }
};

export const updateLeageuFilter = (leagueFilter, component) => {
  return {
    type: UPDATE_LEAGUE_FILTER,
    payload: leagueFilter,
    component: (component ? component : "") + " -> updateLeageuFilter -> ",
  };
};

//This function gets the games of single league in a static routing with league ID.
export const getSingleGame = (leagueFilter, leagueId, sportCode, lang, component) => async (dispatch) => {
  // SELECT_SINGLE_LEAGUE_GAME
  const result = await api.get(`sports/leagues?lang=${lang}`, {
    headers: acceptLanguage(),
    params: { lang },
  });

  const alertAll = validate(result);
  if (alertAll.required) {
    dispatch({
      type: SET_ALERT,
      payload: alertAll.payload,
      component: (component ? component : "") + " -> getSingleLeague -> ",
    });
    if (alertAll.stop) return;
  }

  const selectedLeague = result.data.payload[leagueFilter][sportCode][leagueId];

  const reply = await api.get("sports/games", {
    headers: acceptLanguage(),
    params: {
      lang: lang,
      c: sportCode.toLowerCase(),
      f: leagueFilter === "live" ? "live" : "all",
      id: leagueId,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getSingleLeagueGames -> ",
    });
    if (alert.stop) return;
  }

  const leagueGames = reply.data.payload;
  const leagues = {
    [leagueId]: { ...selectedLeague, ...leagueGames },
    _leagueFilter: leagueFilter,
  };
  const payload = {
    ...leagueGames,
    openLeague: true,
  };

  dispatch({
    type: SAVE_GAMES,
    leagues: leagues,
    payload: payload,
    component: (component ? component : "") + " -> setLeagues -> ",
  });
};

export const getOutrightGames = (leagueFilter, leagueId, sportCode, lang, component, menuValue) => async (dispatch) => {
  const reply = await api.get("outright/games", {
    headers: acceptLanguage(),
    params: {
      lang: lang,
      c: sportCode.toLowerCase(),
      f: leagueFilter === "live" ? "live" : "all",
      id: leagueId,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getOutrightGames -> ",
    });
    if (alert.stop) return;
  }

  const outRightReply = await api.get(`sports/leagues`, {
    headers: acceptLanguage(),
    params: { lang },
  });
  const outRightAlert = validate(outRightReply);
  if (outRightAlert.required) {
    dispatch({
      type: SET_ALERT,
      payload: outRightAlert.payload,
      component: (component ? component : "") + " -> getOutrightGames -> ",
    });
  }
  const findIndex = outRightReply.data.payload[leagueFilter][sportCode].findIndex((item) => item.leagueId === leagueId);
  const league = outRightReply.data.payload[leagueFilter][sportCode][findIndex];
  const allGames = reply.data.payload.games;
  const filteredGames = {};

  for (let game in allGames) {
    const element = allGames[game];
    if (element.leagueID === leagueId && element.is_active === 1) {
      filteredGames[game] = element;
    }
  }
  const gamesKeys = Object.values(filteredGames);
  if (gamesKeys?.length === 0) {
    dispatch({
      type: SET_ALERT,
      payload: { payload: { message: "There are no active games" } },
      component: (component ? component : "") + " -> getOutrightGames -> ",
    });
    return;
  }
  const leagueGames = { games: filteredGames };
  const leagues = {
    [leagueId]: {
      ...league,
      ...leagueGames,
      menuValue: menuValue ? menuValue : null,
    },
    _leagueFilter: leagueFilter,
  };
  const payload = {
    ...leagueGames,
    openLeague: true,
  };

  dispatch({
    type: SAVE_OUTRIGHT_GAMES,
    leagues: leagues,
    payload: payload,
    component: (component ? component : "") + " -> getOutrightGames -> ",
  });
};

export const getGames = (leagueFilter, sportCode, leagues, openLeague, lang, component, isOutright, leagueID) => async (dispatch) => {
  if (leagueFilter === "search") return;
  dispatch({
    type: SELECT_LEAGUES,
    payload: leagues,
    component: (component ? component : "") + " -> getGames -> ",
  });

  let leagueIds = [];

  for (let league in leagues) {
    if (!isNaN(leagues[league].leagueId) && !leagues[league]?.isOutright) leagueIds.push(leagues[league].leagueId);
  }

  leagueIds = leagueIds[0];

  if (!leagueIds || leagueIds === "") return;

  let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "fi";

  const reply = await apiCatalog.get("games/" + leagueIds + "/" + lang, {
    // headers: acceptLanguage(),
    // params: {
    //   lang: lang,
    //   c: sportCode.toLowerCase(),
    //   f: leagueFilter === 'live' ? 'live' : 'all',
    //   id: leagueIds
    // }
  });

  // const replyOutrightGame = await api.get("outright/games", {
  //   headers: acceptLanguage(),
  //   params: {
  //     lang: lang,
  //     c: sportCode.toLowerCase(),
  //     f: leagueFilter === 'live' ? 'live' : 'all',
  //     id: leagueIds,
  //   }
  // });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getGames -> ",
    });
    if (alert.stop) return;
  }

  let games = reply.data.payload.games;
  let outrightGames = reply.data.payload.outrightGames;

  games = games ? games : {};
  outrightGames = outrightGames ? outrightGames : {};

  let payload = {
    games,
    outrightGames,
    // outrightGames: replyOutrightGame?.data?.payload?.games ? replyOutrightGame.data.payload.games : [],
    hasOutright: Object.keys(outrightGames)?.length > 0,
    // hasOutright: outrightGames?.length > 0,
    openLeague: openLeague ? openLeague : false,
  };

  dispatch({
    type: SAVE_GAMES,
    payload: payload,
    leagues: leagues,
    component: (component ? component : "") + " -> getGames -> ",
  });
};

export const updateSelectedLeauge = (league) => {
  return {
    type: UPDATE_SELECTED_LEAGUE,
    payload: league,
  };
};

export const getGameAllOdds = (leagueId, gameId, component) => async (dispatch) => {
  const reply = await api.get("sports/game-all-odds", {
    params: {
      gameId: gameId,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getGameAllOdds -> ",
    });
    if (alert.stop) return;
  }

  let payload = { ...reply.data.payload, leagueId: leagueId, gameId: gameId };

  dispatch({
    type: SAVE_GAME_ALL_ODDS,
    payload: payload ? payload : [],
    component: (component ? component : "") + " -> getGameAllOdds -> ",
  });
};

export const getLeagueResults = (leagueId, component) => async (dispatch) => {
  const reply = await api.get("sports/results", {
    params: {
      id: leagueId,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLeagueResults -> ",
    });
    if (alert.stop) return;
  }

  let payload = { ...reply.data.payload, leagueId: leagueId };

  dispatch({
    type: SAVE_LEAGUE_RESULTS,
    payload: payload ? payload : [],
    component: (component ? component : "") + " -> getLeagueResults -> ",
  });
};

export const deleteSelectedLeagues = (leagueId, gamesOnly, component) => {
  return {
    type: DELETE_SELECTED_LEAGUES,
    leagueId: leagueId,
    gamesOnly: gamesOnly,
    component: (component ? component : "") + " -> deleteSelectedLeagues -> ",
  };
};

export const getLabelsMarkets = (lang, component) => async (dispatch) => {
  const reply = await api.get("sports/labels", {
    params: {
      lang,
    },
    headers: acceptLanguage(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLabelsMarkets -> ",
    });
    if (alert.stop) return;
  }
  dispatch({
    type: GET_SPORTS_LABELS,
    payload: reply.data.payload.sportLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_MARKET_LABELS,
    payload: reply.data.payload.marketViewerLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_MARKET_SPECIFIERS,
    payload: reply.data.payload.marketSpecifiers,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_SELECTION_LABELS,
    payload: reply.data.payload.selectionLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_SELECTION_VIEWER_LABELS,
    payload: reply.data.payload.selectionViewerLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_SELECTION_PRINT_LABELS,
    payload: reply.data.payload.selectionPrintLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_HC_LABELS,
    payload: reply.data.payload.marketHcPrintLabels,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_USER_BONUSES,
    payload: reply.data.payload.userBonuses,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
  dispatch({
    type: GET_CASHOUT_AVAILABILITY,
    payload: reply.data.payload.cashoutAvailability,
    component: (component ? component : "") + " -> getLabelsMarkets -> ",
  });
};

export const getTicketsGames = (type, ticketId, component) => async (dispatch) => {
  const lang = localStorage.getItem("lang");
  dispatch({
    type: IS_TICKET_GAMES_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getTicketsGames -> ",
  });
  const reply = await api.get("account/ticket", {
    params: {
      id: ticketId,
      lang: lang ? lang : "fi",
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getTicketsGames -> ",
    });
    dispatch({
      type: IS_TICKET_GAMES_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getTicketsGames -> ",
    });
    if (alert.stop) return;
  }
  dispatch({
    type: GET_TICKET_GAMES,
    payload: reply.data.payload ? reply.data.payload : [],
    component: (component ? component : "") + " -> getTicketsGames -> ",
  });
  dispatch({
    type: IS_TICKET_GAMES_LOADING,
    payload: false,
    component: (component ? component : "") + " -> getTicketsGames -> ",
  });
};

export const getTicket = (type, ticketId, component) => async (dispatch) => {
  dispatch({
    type: SAVE_TICKET,
    payload: {
      tickets: [],
      type: type,
    },
    component: (component ? component : "") + " -> getTicket -> ",
  });

  const lang = localStorage.getItem("lang");

  const reply = await api.get("account/ticket", {
    params: {
      id: ticketId,
      lang: lang ? lang : "fi",
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getTicket -> ",
    });
    if (alert.stop) return;
  }

  let payload = {
    tickets: reply.data.payload,
    type: type,
  };

  dispatch({
    type: SAVE_TICKET,
    payload: payload,
    component: (component ? component : "") + " -> getTicket -> ",
  });
};

export const getTickets = (startDate, endDate, status, component, start, limit) => async (dispatch) => {
  dispatch({
    type: IS_TICKETS_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getTickets -> ",
  });
  const lang = localStorage.getItem("lang");
  let startDateValue = new Date(startDate);
  let endDateValue = new Date(endDate);
  if (status === "pending") {
    startDateValue = new Date();
    startDateValue = startDateValue.setDate(startDateValue.getDate() - 365);
    endDateValue = new Date();
  } else {
    startDateValue = startDateValue.setDate(startDateValue.getDate() - 1);
  }
  startDateValue = formatDateToApi(startDateValue);
  endDateValue = formatDateToApi(endDateValue);

  const reply = await api.get("account/tickets", {
    params: {
      startDate: startDateValue,
      endDate: endDateValue,
      status: status,
      start: start ? start : 0,
      limit: limit ? limit : 20,
      lang: lang ? lang : "fi",
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getTickets -> ",
    });
    dispatch({
      type: IS_TICKETS_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getTickets -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: SAVE_TICKETS,
    payload: reply.data?.payload?.tickets ? reply.data?.payload?.tickets : [],
    count: reply.data.payload?.count,
    status: status,
    component: (component ? component : "") + " -> getTickets -> ",
  });

  let tickets = reply.data?.payload?.tickets ? reply.data?.payload?.tickets : [];
  let pendingTickets = [];
  const ticketsIds = [];
  for (let key in tickets) {
    ticketsIds.push(tickets[key]?.ticketID);
    if (tickets[key].status === "pending") pendingTickets.push(tickets[key].ticketID);
  }

  if (ticketsIds?.length) {
    dispatch(getTicketsGames(status, ticketsIds?.toString(), "getTicketsGames"));
  }

  if (pendingTickets?.length > 0) {
    dispatch(getTicket("calcCashout", pendingTickets.join(","), component));
  }
  dispatch({
    type: IS_TICKETS_LOADING,
    payload: false,
    component: (component ? component : "") + " -> getTickets -> ",
  });
};

export const postTicket = (data, selectedSlipTemplate, receiptPayload, component) => async (dispatch, getState) => {
  dispatch({
    type: POST_TICKET_LOADING,
    payload: true,
    component: (component ? component : "") + " -> postTicket -> ",
  });
  const reply = await api.post(
    "account/ticket",
    {
      params: {
        data: data,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  let from = "";

  if (alert.required) {
    if (alert.payload.type !== "success") {
      const payload = alert.payload;
      let allInvalidPriceMessagesFlag = true;

      if (payload.type !== "invalid_price") {
        allInvalidPriceMessagesFlag = false;
      }

      if (allInvalidPriceMessagesFlag) {
        from = "ticketPosting";
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { message: alert.payload.type },
          },
          from: "ticketPosting",
          component: (component ? component : "") + " -> postTicket -> ",
        });
      } else if (reply.data.payload?.type) {
        switch (reply.data.payload.type) {
          case "bet_price_changed":
            dispatch({
              type: SHOULD_ALERT_ODDS_UPDATE,
              payload: {
                newPrice: reply.data.payload?.newPrice,
                price: reply.data.payload?.price,
              },
              isOpen: true,
              component: (component ? component : "") + " -> postTicket -> ",
            });
            break;
          case "maxCashBet_limit_exceeded":
            dispatch({
              type: SET_ALERT,
              payload: {
                ...alert.payload,
                payload: { error: "cash_bet_limit_exceeded" },
              },
              from,
              component: (component ? component : "") + " -> postTicket -> ",
            });
            break;
          case "maxStake_limit_exceeded":
            dispatch({
              type: SET_ALERT,
              payload: {
                ...alert.payload,
                payload: { error: "maxstake_limit_exceeded" },
              },
              from,
              component: (component ? component : "") + " -> postTicket -> ",
            });
            break;
          //
          default:
            dispatch({
              type: SET_ALERT,
              payload: {
                ...alert.payload,
                payload: { type: reply.data.payload.type },
              },
              from,
              component: (component ? component : "") + " -> postTicket -> ",
            });
            break;
        }
      } else if (reply.data.payload?.key) {
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { type: reply.data.payload.key.type },
          },
          from,
          component: (component ? component : "") + " -> postTicket -> ",
        });
      } else if (reply.data.payload?.error) {
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: reply.data?.payload?.error },
          },
          from,
          component: (component ? component : "") + " -> postTicket -> ",
        });
      } else if (reply.data.type === "error") {
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: alert.payload?.payload },
          },
          from,
          component: (component ? component : "") + " -> postTicket -> ",
        });
      }
    }
    dispatch({
      type: POST_TICKET_LOADING,
      payload: false,
      component: (component ? component : "") + " -> postTicket -> ",
    });
  }
  const state = getState();
  if (reply.data?.type === "success") {
    dispatch({
      type: POST_TICKET,
      payload: { ...receiptPayload, ...reply.data.payload, date: new Date() },
      component: (component ? component : "") + " -> postTicket -> ",
    });
  }
  dispatch({
    type: POST_TICKET_LOADING,
    payload: false,
    component: (component ? component : "") + " -> postTicket -> ",
  });
  let dateFrom = new Date();
  dateFrom.setDate(dateFrom.getDate() - 14);
  dispatch(getTickets(formatDateToApi(dateFrom), formatDateToApi(Date.now()), "pending", "actions/index.js", selectedSlipTemplate, state.tickets.ticketsLimit));
  setTimeout(() => {
    dispatch(getWalletBalanceDetails("actions/index.js"));
  }, 2000);
};

export const postTicketCashout = (data, component) => async (dispatch) => {
  const reply = await api.post(
    "account/cashout",
    {
      params: {
        data: data,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> postTicketCashout -> ",
    });
    if (alert.stop) {
      dispatch({
        type: POST_TICKET_CASHOUT,
        payload: { type: "error", payload: "initialState" },
        component: (component ? component : "") + " -> postTicketCashout -> ",
        ticketID: data.ticketID,
      });
    }
  }

  dispatch({
    type: POST_TICKET_CASHOUT,
    payload: reply.data,
    component: (component ? component : "") + " -> postTicketCashout -> ",
    ticketID: data.ticketID,
  });
};

export const getMaxStake = (data, slipTemplate, boosted, component) => async (dispatch) => {
  // need to prepare tickets.
  let tickets = {};
  let ticket = {};

  ticket.betType = data[slipTemplate].betType;
  ticket.stake = data[slipTemplate]?.stake;
  ticket.combiTypes = data[slipTemplate]?.settings?.combiTypes.toString();
  ticket.combiStakes = data[slipTemplate]?.settings?.combiStakes?.[0];
  ticket.totalBets = data[slipTemplate]?.settings?.totalBetsPerBetType;
  let bets = [];
  ticket["comboBoost"] = Boolean(boosted?.isBoosted);
  ticket["percent_bonus"] = boosted?.bonus_amount;

  Object.values(data[slipTemplate]?.settings?.bets)?.forEach((game) => {
    if (game?.isOutright) {
      bets.push({
        eventType: "game",
        eventID: game.gameId,
        marketID: game.marketId,
        selectionID: game.selectionId,
        price: game.price,
        hc: game.hc,
        isBank: game.isBank,
        isOutright: game.isOutright,
      });
    } else if (game?.isBetBuilder) {
      bets.push({
        eventType: "game",
        eventID: game.gameId,
        betslipID: game.selectionId,
        price: game.price,
        isBetBuilder: game.isBetBuilder,
        leagueID: game?.game?.leagueID,
      });
    } else if (game.isPlayerProps) {
      bets.push({
        eventType: "game",
        eventID: game.gameId,
        betslipID: game.selectionId,
        price: game.price,
        isBetBuilder: true,
        leagueID: game?.game?.leagueID,
        isPlayerProps: true,
      });
    } else {
      bets.push({
        eventType: "game",
        eventID: game.gameId,
        marketID: game.marketId,
        selectionID: game.selectionId,
        price: game.price,
        hc: game.hc,
        isBank: game.isBank,
      });
    }
  });

  ticket.bets = bets;
  tickets[slipTemplate] = ticket;

  try {
    const reply = await api.post(
      "sports/max-stake",
      {
        params: {
          data: tickets[slipTemplate],
        },
      },
      {
        headers: authHeader(),
      }
    );

    const maxStake = reply?.data?.payload ? (reply.data.payload.stake ? reply.data.payload.stake : 0) : 0;

    dispatch({
      type: UPDATE_STAKE,
      payload: maxStake,
      component: (component ? component : "") + " -> setMaxStake -> ",
      slipTemplate,
    });
  } catch (error) {
    const alert = validate(error.response);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getMaxStake -> ",
      });
    }
  }
};

export const saveBet = (bet, slipTemplate, component) => (dispatch, getState) => {
  const state = getState();
  const isTicketPosted = state.tickets.isTicketPosted;
  const bets = Object.values(state.slips[0].settings.bets);
  const hasPP = bets.find((bt) => bt?.isPlayerProps);
  if (isTicketPosted) {
    dispatch(removePostedTicketState(component));
  }
  if (hasPP) {
    dispatch(emptySlipTemplate(0));
    if (hasPP?.identifier === bet?.identifier) return;
  }

  dispatch({
    type: SAVE_BET,
    payload: bet,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> saveBet -> ",
  });
};

export const deleteBet = (bet, slipTemplate, component) => {
  return {
    type: DELETE_BET,
    payload: bet,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> deleteBet -> ",
  };
};

export const updateScoresInLeague = (scores, leagueFilter, component) => {
  return {
    type: UPDATE_SCORES_IN_LEAGUE,
    payload: scores,
    leagueFilter,
    component: (component ? component : "") + " -> updateScoresInLeague -> ",
  };
};

export const updateOddsInLeague = (odds, component) => {
  return {
    type: UPDATE_ODDS_IN_LEAGUE,
    payload: odds,
    component: (component ? component : "") + " -> updateOddsInLeague -> ",
  };
};

export const updateOddsInSlip = (odds, component) => {
  return {
    type: UPDATE_ODDS_IN_SLIP,
    payload: odds,
    component: (component ? component : "") + " -> updateOddsInSlip -> ",
  };
};

export const updateBetWithLowerPrice = (bet, slipTemplate, component) => {
  return {
    type: UPDATE_BET_WITH_LOWER_PRICE,
    payload: bet,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> updateBetWithLowerPrice -> ",
  };
};

export const updateOddsInTicket = (odds, component) => {
  return {
    type: UPDATE_ODDS_IN_TICKET,
    payload: odds,
    component: (component ? component : "") + " -> updateOddsInTicket -> ",
  };
};

export const updateScoresInTicket = (odds, component) => {
  return {
    type: UPDATE_SCORES_IN_TICKET,
    payload: odds,
    component: (component ? component : "") + " -> updateScoresInTicket -> ",
  };
};

export const saveSlipTemplate = (slip, component) => {
  return {
    type: SAVE_SLIP_TEMPLATE,
    payload: slip,
    component: (component ? component : "") + " -> saveSlipTemplate -> ",
  };
};

export const selectSlipTemplate = (id, component) => {
  return {
    type: SELECT_SLIP_TEMPLATE,
    payload: id,
    component: (component ? component : "") + " -> selectSlipTemplate -> ",
  };
};

export const createSlipTemplate = (slipTemplate, component) => {
  return {
    type: CREATE_SLIP_TEMPLATE,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> createSlipTemplate -> ",
  };
};

export const deleteSlipTemplate = (slipTemplate, component) => {
  return {
    type: DELETE_SLIP_TEMPLATE,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> deleteSlipTemplate -> ",
  };
};

export const copySlipTemplate = (slipTemplate, component) => {
  return {
    type: COPY_SLIP_TEMPLATE,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> copySlipTemplate -> ",
  };
};

export const importSlipTemplate = (slipTemplateData, component) => {
  return {
    type: IMPORT_SLIP_TEMPLATE,
    slipTemplateData: slipTemplateData,
    component: (component ? component : "") + " -> importSlipTemplate -> ",
  };
};

export const exportSlipTemplate = (slipTemplate, component) => {
  return {
    type: EXPORT_SLIP_TEMPLATE,
    slipTemplate: slipTemplate,
    component: (component ? component : "") + " -> exportSlipTemplate -> ",
  };
};

export const saveTicketFilter = (filterIndex, component) => {
  return {
    type: SAVE_TICKET_FILTER,
    payload: filterIndex,
    component: (component ? component : "") + " -> saveTicketFilter -> ",
  };
};

export const expandMultiLineOdds = (odds, component) => {
  return {
    type: EXPAND_MULTI_LINE_ODDS,
    payload: odds,
    component: (component ? component : "") + " -> expandMultiLineOdds -> ",
  };
};

export const collapseMultiLineOdds = (odds, component) => {
  return {
    type: COLLAPSE_MULTI_LINE_ODDS,
    payload: odds,
    component: (component ? component : "") + " -> collapsMultiLineOdds -> ",
  };
};

export const setLeagueMenuValue = (sportCode, leagueId, menuValue, component) => {
  return {
    type: SET_LEAGUE_MENU_VALUE,
    sportCode: sportCode,
    leagueId: leagueId,
    menuValue: menuValue,
    component: (component ? component : "") + " -> setLeagueMenuValue -> ",
  };
};

export const setTheme = (theme, component) => {
  return {
    type: SET_THEME,
    payload: theme,
    component: (component ? component : "") + " -> setTheme -> ",
  };
};

export const setLayout = (layout, component) => {
  return {
    type: SET_LAYOUT,
    payload: layout,
    component: (component ? component : "") + " -> setLayout -> ",
  };
};

export const setMode = (mode, component) => {
  return {
    type: SET_MODE,
    payload: mode,
    component: (component ? component : "") + " -> setMode -> ",
  };
};

export const setNavMode = (mode, component) => {
  return {
    type: SET_NAV_MODE,
    payload: mode,
    component: (component ? component : "") + " -> setMode -> ",
  };
};

export const setMarketsLayout = (layout, component) => {
  return {
    type: SET_MARKETS_LAYOUT,
    payload: layout,
    component: (component ? component : "") + " -> setMarketsLayout -> ",
  };
};

export const setFavoritesLayout = (layout, component) => {
  return {
    type: SET_FAVORITES_LAYOUT,
    payload: layout,
    component: (component ? component : "") + " -> setFavoritsLayout -> ",
  };
};

export const setUrl = (url, component) => {
  return {
    type: SET_URL,
    payload: url,
    component: (component ? component : "") + " -> setUrl -> ",
  };
};

export const setHost = (host, component) => {
  return {
    type: SET_HOST,
    payload: host,
    component: (component ? component : "") + " -> setHost -> ",
  };
};

export const setIsMobile = (isMobile, component) => {
  return {
    type: SET_IS_MOBILE,
    payload: isMobile,
    component: (component ? component : "") + " -> setIsMobile -> ",
  };
};

export const setSSE = (sse, component) => {
  return {
    type: SET_SSE,
    payload: sse,
    component: (component ? component : "") + " -> setSSE -> ",
  };
};

export const setOddsFormat = (oddsFormat, component) => {
  return {
    type: SET_ODDS_FORMAT,
    payload: oddsFormat,
    component: (component ? component : "") + " -> setOddsFormat -> ",
  };
};

export const setLeagueGrouping = (grouping, component) => {
  return {
    type: SET_LEAGUE_GROUPING,
    payload: grouping,
    component: (component ? component : "") + " -> setLeagueGrouping -> ",
  };
};

export const showResultsFirst = (resultsFirst, component) => {
  return {
    type: SHOW_RESULTS_FIRST,
    payload: resultsFirst,
    component: (component ? component : "") + " -> setLeagueGrouping -> ",
  };
};

export const saveFavoriteLeagues = (component) => {
  return {
    type: SAVE_FAVORITE_LEAGUES,
    payload: null,
    component: (component ? component : "") + " -> saveFavoriteLeagues -> ",
  };
};

export const saveTreeViewLeagues = (league, component) => {
  return {
    type: SAVE_TREEVIEW_LEAGUES,
    payload: league,
    component: (component ? component : "") + " -> saveTreeViewLeagues -> ",
  };
};

export const saveMarketSetConfig = (marketSetConfig, component) => {
  return {
    type: SAVE_MARKET_SET_CONFIG,
    payload: marketSetConfig,
    component: (component ? component : "") + " -> saveMarketSetConfig -> ",
  };
};

export const saveCasinoFilter = (casinoFilter, component) => {
  return {
    type: SAVE_CASINO_FILTER,
    payload: casinoFilter,
    component: (component ? component : "") + " -> saveCasinoFilter -> ",
  };
};

export const toggleMyTicketsPanel = (component, flag) => {
  return {
    type: TOGGLE_MY_TICKETS_PANEL,
    flag: flag,
    component: (component ? component : "") + " -> toggleMyTicketsPanel -> ",
  };
};

export const getOddsHistory = (leagueId, gameId, marketId, hc, selections, component) => async (dispatch) => {
  let selectionIds = [];

  for (let selection in selections) {
    if (!isNaN(selections[selection])) selectionIds.push(selections[selection]);
  }

  selectionIds = selectionIds.join(",");

  const reply = await api.get("sports/odds-history", {
    params: {
      gameId: gameId,
      marketId: marketId,
      hc: hc,
      selectionIds: selectionIds,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getOddsHistory -> ",
    });
    if (alert.stop) return;
  }

  let payload = {
    data: reply.data.payload,
    leagueId: leagueId,
    gameId: gameId,
    marketId: marketId,
    hc: hc,
  };

  dispatch({
    type: SAVE_ODDS_HISTORY,
    payload: reply.data ? (payload ? payload : []) : [],
    component: (component ? component : "") + " -> getOddsHistory -> ",
  });
};

export const signIn = (email, userPass, component, termsConfirmed, callback) => async (dispatch) => {
  const params = {
    email: email,
    userPass: userPass,
  };

  if (termsConfirmed) {
    params["policyChecked"] = true;
  }

  const reply = await api.post("auth/signin", {
    params: params,
  });

  if (reply.data.payload === "TermsNotAcceptedException") {
    dispatch(setTermsStatus(true));
    return;
  }

  const alert = validate(reply);
  if (alert.required) {
    const type = alert.payload.payload?.error ? alert.payload.payload.error : alert.payload.payload;
    switch (type) {
      case "UserNotFoundException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "UserNotFoundException" },
          },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      case "UserFrozenException":
        dispatch({
          type: SET_ALERT,
          payload: { ...alert.payload, payload: { error: "UserFrozenException" } },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      case "BadCredentialsException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "BadCredentialsException" },
          },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      case "ValidationException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "UserNotFoundException" },
          },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      case "UserSelfClosedException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "UserSelfClosedException" },
          },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      case "UserCoolOffClosedException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "UserSelfClosedException" },
          },
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
      default:
        dispatch({
          type: SET_ALERT,
          payload: alert.payload,
          component: (component ? component : "") + " -> signIn -> ",
        });
        break;
    }
    if (alert.stop) return;
  }

  if (reply.data.payload.jwt) {
    const { jwt, exp, loginTS } = reply.data.payload;
    // let jwt = reply.data.payload.jwt;
    // let exp = reply.data.payload.exp;

    localStorage.setItem("jwt", jwt);
    localStorage.setItem("exp", exp);
    localStorage.setItem("loginTS", loginTS);

    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: reply.data.payload,
      component: (component ? component : "") + " -> signIn -> ",
    });
    if (Boolean(callback)) {
      await callback();
    }
  } else {
    if (reply.data.payload.emailConfirmed === false) {
      dispatch({
        type: SIGN_IN_NOT_CONFIRMED_EMAIL,
        email: reply.data.payload.email,
        component: (component ? component : "") + " -> signIn -> ",
      });
    } else {
      if (reply.data.payload.rgCooloffExpires !== 0) {
        dispatch({
          type: SIGN_IN_FAIL_BLOCKED_USER,
          payload: reply.data.payload,
          component: (component ? component : "") + " -> signIn -> ",
        });
      } else {
        dispatch({
          type: SIGN_IN_FAIL,
          component: (component ? component : "") + " -> signIn -> ",
        });
        dispatch({
          type: SET_ALERT,
          payload: reply.data.payload,
          component: (component ? component : "") + " -> signIn -> ",
        });
      }
    }
  }
};

export const signOut = (component, isManualSignOut, callback) => async (dispatch) => {
  await api.post("auth/signout", {}, { headers: authHeader() });

  localStorage.removeItem("jwt");
  localStorage.removeItem("exp");
  dispatch({
    type: SIGN_OUT,
    isManualSignOut,
    component: (component ? component : "") + " -> signOut -> ",
  });
  if (callback) {
    callback();
  }
};

export const getUserDetails = (component) => async (dispatch) => {
  const reply = await api.get("account/user", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getUserDetails -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_USER_DETAILS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getUserDetails -> ",
  });
};

export const registerUser = (data, component) => async (dispatch) => {
  let formattedData = { ...data };
  formattedData.securityAnswer = "test";

  const reply = await api.post("account/user", {
    params: {
      data: formattedData,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    if (alert.payload?.payload?.DuplicateData?.email) {
      dispatch({
        type: SET_ALERT,
        payload: { ...alert.payload, payload: { error: "email_exists" } },
        component: (component ? component : "") + " -> registerUser -> ",
      });
    } else if (alert.payload?.payload?.DuplicateData?.nickName) {
      dispatch({
        type: SET_ALERT,
        payload: { ...alert.payload, payload: { error: "user_exists" } },
        component: (component ? component : "") + " -> registerUser -> ",
      });
    } else if (alert.payload?.payload?.general) {
      dispatch({
        type: SET_ALERT,
        payload: { ...alert.payload, payload: { error: "user_unavailable" } },
        component: (component ? component : "") + " -> registerUser -> ",
      });
    } else if ((alert.payload.type = "success")) {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { message: alert.payload?.payload.message },
        },
        component: (component ? component : "") + " -> registerUser -> ",
      });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> registerUser -> ",
      });
    }
    if (alert.stop) return;
  }

  dispatch({
    type: REGISTER_USER,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> registerUser s -> ",
  });
};

export const updateUser = (data, component) => async (dispatch) => {
  const reply = await api.put(
    "/account/user",
    {
      params: {
        data: data,
      },
    },
    { headers: authHeader() }
  );
  const alert = validate(reply);
  if (alert.required) {
    if (alert.payload.type === "success") {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { message: alert.payload.payload.message },
        },
        component: (component ? component : "") + " -> updateUser -> ",
      });
      window.location.reload();
    } else {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> updateUser -> ",
      });
    }
    if (alert.stop) return;
  }

  dispatch({
    type: UPDATE_USER,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> updateUser -> ",
  });
};

export const blockUser = (data, component, callback) => async (dispatch, getState) => {
  const state = getState();
  const translation = state.i18n.translations[state.i18n.locale];
  const reply = await api.post(
    "/account/coolOff",
    {
      params: {
        coolOffDays: parseInt(data?.coolOffDays),
        password: data?.password,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);

  if (reply?.data?.payload?.success) {
    dispatch({
      type: SET_ALERT,
      payload: {
        type: "success",
        collection: "labels",
        payload: { message: translation.labels.block_activated },
      },
      component: (component ? component : "") + " -> blockUser -> ",
    });
  }

  if (alert.required) {
    if (alert.payload?.payload.success) {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { message: translation.labels.operation_successful },
        },
        component: (component ? component : "") + " -> blockUser -> ",
      });
    } else {
      switch (alert.payload.payload.error) {
        case "NoAvailableSessionException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "rg_account_blocked" },
            },
            component: (component ? component : "") + " -> blockUser -> ",
          });
          break;
        case "UserFrozenException":
          dispatch({
            type: SET_ALERT,
            payload: { ...alert.payload, payload: { error: "UserFrozenException" } },
            component: (component ? component : "") + " -> blockUser -> ",
          });
          break;
        case "BadCredentialsException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> blockUser -> ",
          });
          break;
        case "AuthenticationException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> blockUser -> ",
          });
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: alert.payload,
            component: (component ? component : "") + " -> blockUser -> ",
          });
          break;
      }
    }
    if (alert.stop) return;
  }
  dispatch(signOut("blockUser", false, callback));
  dispatch({ type: BLOCK_USER, payload: reply.data.payload });
};

export const updatePassword = (data, component, page) => async (dispatch) => {
  const reply = await api.put(
    "auth/password",
    {
      params: {
        oldPassword: data.password_current,
        password: data.password_new,
        confirmPassword: data.password_new_confirm,
      },
    },
    { headers: authHeader() }
  );

  if (reply?.data?.payload?.success) {
    delete reply.data.payload.success;
  }

  const alert = validate(reply);
  if (alert.required) {
    if (page === "PasswordResetForm" && reply.data.type === "success") {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { message: "Password changed!" },
        },
        component: (component ? component : "") + " -> updatePassword -> ",
      });
    } else {
      switch (alert.payload.payload.error) {
        case "NoAvailableSessionException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "rg_account_blocked" },
            },
            component: (component ? component : "") + " -> updatePassword -> ",
          });
          break;
        case "UserFrozenException":
          dispatch({
            type: SET_ALERT,
            payload: { ...alert.payload, payload: { error: "UserFrozenException" } },
            component: (component ? component : "") + " -> updatePassword -> ",
          });
          break;
        case "BadCredentialsException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> updatePassword -> ",
          });
          break;
        case "AuthenticationException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> updatePassword -> ",
          });
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: alert.payload,
            component: (component ? component : "") + " -> updatePassword -> ",
          });
          break;
      }
    }
    if (alert.stop) return;
  }

  dispatch({
    type: UPDATE_PASSWORD,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> updatePassword -> ",
  });
  if (page === "PasswordResetForm") {
    dispatch(reset("PasswordResetForm"));
    const lang = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "fi";
    window.location.href = window.location.origin + "/" + lang + "/auth/login";
  } else {
    dispatch(reset("PasswordUpdate"));
  }
};

export const resetPassword = (data, component, page) => async (dispatch) => {
  const reply = await api.put(
    "auth/set-password",
    {
      params: {
        code: data.password_current,
        password: data.password_new,
        confirmPassword: data.password_new_confirm,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    if (page === "PasswordResetForm" && reply.data.type === "success") {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { message: "Password changed!" },
        },
        component: (component ? component : "") + " -> updatePassword -> ",
      });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> updatePassword -> ",
      });
    }
    if (alert.stop) return;
  }

  dispatch({
    type: UPDATE_PASSWORD,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> updatePassword -> ",
  });
  if (page === "PasswordResetForm") {
    dispatch(reset("PasswordResetForm"));
    const lang = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "fi";
    window.location.href = window.location.origin + `/${lang}` + "/auth/login";
  } else {
    dispatch(reset("PasswordUpdate"));
  }
};

export const getForgottenPassword = (data, component, successMessage) => async (dispatch) => {
  const reply = await api.get("auth/password-retrieve", {
    params: {
      email: data.email,
      birthDate: data.birthDate,
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    if (reply.data.type === "success") {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          payload: { type: "forgot_email_sent", message: successMessage },
        },
        component: (component ? component : "") + " -> getForgottenPassword -> ",
      });
    } else {
      if (reply?.data?.payload?.email) {
        dispatch({
          type: SET_ALERT,
          payload: { ...alert.payload, payload: { error: "user_not_found" } },
          component: (component ? component : "") + " -> getForgottenPassword -> ",
        });
      } else if (reply?.data?.payload?.errorCode === 111) {
        dispatch({
          type: SET_ALERT,
          payload: { ...alert.payload, payload: { error: "UserFrozenException" } },
          component: (component ? component : "") + " -> getForgottenPassword -> ",
        });
      } else {
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { error: "AuthenticationException" },
          },
          component: (component ? component : "") + " -> getForgottenPassword -> ",
        });
      }
    }
    if (alert.stop) return;
  }

  dispatch({
    type: GET_FORGOTTEN_PASSWORD,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getForgottenPassword -> ",
  });
  dispatch(reset("PasswordForgotten"));
};

export const getSubscriptions = (component) => async (dispatch) => {
  const reply = await api.get("account/subscriptions", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getSubscriptions -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_SUBSCRIPTIONS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getSubscriptions -> ",
  });
};

export const updateSubscriptions = (data, component) => async (dispatch) => {
  const reply = await api.put(
    "account/subscriptions",
    {
      params: {
        sub_email: data.sub_email,
        sub_sms: data.sub_sms,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> updateSubscriptions -> ",
    });
    if (alert.stop) return;
  }

  // dispatch({ type: UPDATE_SUBSCRIPTIONS, payload: reply.data.payload });
};

export const checkKycStatus = (email, component) => async (dispatch) => {
  const reply = await api.get(`account/services/ajax?action=/ecr/ups/kycCompleted&email=${email}`, {
    headers: authHeader(),
  });

  dispatch({
    type: SET_KYC_STATUS,
    payload: reply.data.kycCompleted,
    component: (component ? component : "") + " -> checkKycStatus -> ",
  });
};

export const checkValidInstrument = (email, merchant, component) => async (dispatch) => {
  const reply = await api.get(`account/services/ajax?action=/cashier/validInstrument&email=${email}&merchant=${merchant}`, {
    headers: authHeader(),
  });

  dispatch({
    type: SET_VALID_INSTRUMENT,
    payload: reply.data.validInstrument,
    component: (component ? component : "") + " -> checkKycStatus -> ",
  });
};

export const checkPendingCashout = (email, merchant, component) => async (dispatch) => {
  const reply = await api.get(`account/services/ajax?action=/cashier/pendingCashouts&email=${email}&merchant=${merchant}`, {
    headers: authHeader(),
  });

  dispatch({
    type: SET_PENDING_CASHOUTS,
    payload: reply.data.pendingCashouts,
    component: (component ? component : "") + " -> checkKycStatus -> ",
  });
};

export const getKycDetails = (component) => async (dispatch) => {
  const reply = await api.get("account/documents", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getKycDetails -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_KYC_DETAILS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getKycDetails -> ",
  });
};

export const uploadKycDocuments = (data, component) => async (dispatch) => {
  const reply = await api.post("account/upload-document", data, {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    switch (alert.payload.type) {
      case "success":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { message: alert.payload.payload.message },
          },
          component: (component ? component : "") + " -> uploadKycDocuments -> ",
        });
        break;
      default:
        dispatch({
          type: SET_ALERT,
          payload: alert.payload,
          component: (component ? component : "") + " -> uploadKycDocuments -> ",
        });
        break;
    }
    if (alert.stop) return;
  }

  dispatch(getKycDetails(component));

  // dispatch({
  //   type: UPLOAD_KYC_DOCUMENTS,
  //   payload: reply.data.payload,
  //   component: (component ? component : '') + ' -> uploadKycDocuments -> '
  // });
};

export const getLimits = (component) => async (dispatch) => {
  let reply;

  try {
    reply = await api.get("account/limits", {
      headers: authHeader(),
    });
  } catch (error) {}
  const alert = validate(reply);
  if (reply?.data?.payload?.extraData?.error) return;
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLimits -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_LIMITS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getLimits -> ",
  });
};

export const saveLimits = (data, component) => async (dispatch, getState) => {
  const state = getState();
  const labels = state.i18n.translations[state.i18n.locale]["labels"];

  const reply = await api.post(
    "account/limits",
    {
      params: data,
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (reply?.data?.type === "data") {
    dispatch({
      type: SET_ALERT,
      payload: { type: "success", payload: { message: labels.limits_updated } },
      component: (component ? component : "") + " -> getLimits -> ",
    });
  } else if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLimits -> ",
    });
    if (alert.stop) return;
  }

  dispatch(getLimits(component));
};

export const deleteLimit = (data, component) => async (dispatch) => {
  const reply = await api.delete("account/limits", {
    headers: authHeader(),
    data: {
      params: {
        limitType: data.limitType,
        limitPeriod: data.limitPeriod,
      },
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLimits -> ",
    });
    if (alert.stop) return;
  }

  dispatch(getLimits(component));
};

export const updateLimit = (data, component) => async (dispatch) => {
  const reply = await api.put(
    "account/limits",
    {
      params: {
        operation: data.operation,
        limitType: data.limitType,
        limitPeriod: data.limitPeriod,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getLimits -> ",
    });
    if (alert.stop) return;
  }

  dispatch(getLimits(component));
};

export const getWalletBalance = (component) => async (dispatch) => {
  const reply = await api.get("wallet/balance", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getWalletBalance -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_BALANCE,
    payload: reply.data.payload.credit,
    component: (component ? component : "") + " -> getWalletBalance -> ",
  });
};

export const checkIfUserAllowedToDeposit = (component) => async (dispatch) => {
  const reply = await api.get("wallet/user-payment-details-psp", {
    headers: authHeader(),
  });
};

export const postWalletDeposit = (amount, merchant, bonus, component) => async (dispatch) => {
  const reply = await api.post(
    "wallet/confirmDeposit",
    {
      params: {
        amount: amount,
        merchant: merchant,
        bonus: bonus ? bonus : 0,
        method: "300002",
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required && reply.data.payload.error) {
    switch (reply.data.payload.error) {
      case "NoPaymentDetailsFilledException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            type: "error",
            payload: { message: "No Payment Details Filled" },
          },
          component: (component ? component : "") + " -> postWalletDeposit -> ",
        });
        break;
      case "PaymentLimitException":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            type: "error",
            collection: "trustly",
            payload: { error: "deposit_limit_exceeded" },
          },
          component: (component ? component : "") + " -> postWalletDeposit -> ",
        });
        break;

      default:
        break;
    }
    return;
  }

  dispatch({
    type: POST_WALLET_DEPOSIT,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> postWalletDeposit -> ",
  });
  window.location.href = reply.data.payload.redirectUrl;
};

export const getWalletStatement = (type, startDate, endDate, page, component) => async (dispatch) => {
  const reply = await api.get("transactions/trans", {
    params: {
      action: type,
      startTime: startDate,
      endTime: endDate,
      page: page,
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required && reply.data.type !== "success") {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getWalletStatement -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_WALLET_STATEMENT,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getWalletStatement -> ",
  });
};

export const loadMoreWalletStatement = (type, startDate, endDate, page, component) => async (dispatch) => {
  const reply = await api.get("transactions/trans", {
    params: {
      action: type,
      startDate: startDate,
      endDate: endDate,
      page: page,
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required && reply.data.type !== "success") {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getWalletStatement -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: LOAD_MORE_WALLET_STATEMENT,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getWalletStatement -> ",
  });
};

export const resetWalletStatement = (component) => async (dispatch) => {
  dispatch({
    type: RESET_WALLET_STATEMENT,
    component: (component ? component : "") + " -> getWalletStatement -> ",
  });
};

export const postSkrillWithdraw = (amount, merchant, bonus, component) => async (dispatch) => {
  const reply = await api.post(
    "wallet/withdraw",
    {
      params: {
        amount: amount,
        merchant: merchant,
        bonus: bonus ? bonus : 0,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: { ...alert.payload, extraField: "Skrill" },
      component: (component ? component : "") + " -> postWalletDeposit -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: POST_WITHDRAW,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> postWalletDeposit -> ",
  });
};

export const postBankwireWithdraw = (name, iban, swift, amount, merchant, bonus, component) => async (dispatch) => {
  const reply = await api.post(
    "wallet/withdraw",
    {
      params: {
        amount: amount,
        merchant: merchant,
        name_on_account: name,
        iban: iban,
        swift: swift,
        bonus: bonus ? bonus : 0,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: { ...alert.payload, extraField: "Bank Trasfer" },
      component: (component ? component : "") + " -> postWalletDeposit -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: POST_WITHDRAW,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> postWalletDeposit -> ",
  });
};

export const postTrustlyWithdraw = (amount, merchant, bonus, component) => async (dispatch, getState) => {
  const reply = await api.post(
    "wallet/withdraw",
    {
      params: {
        amount: amount,
        merchant: merchant,
        bonus: bonus ? bonus : 0,
        method: "1074",
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  const state = getState();
  const translation = state.i18n.translations[state.i18n.locale];
  if (alert.required) {
    if (reply.data.type === "success") {
      dispatch({
        type: SET_ALERT,
        payload: {
          ...alert.payload,
          // payload: { message: "Withdraw initiated" },
          payload: { message: translation.labels.withdraw_initiated_success },
        },
        component: (component ? component : "") + " -> postWalletWidthdraw -> ",
      });
    } else {
      switch (reply.data.payload.error) {
        case "NoPaymentDetailsFilledException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "No Payment Details Filled" },
            },
            component: (component ? component : "") + " -> postWalletWidthdraw -> ",
          });
          break;
        case "NegativeBalanceException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "Insufficiant balance" },
            },
            component: (component ? component : "") + " -> postWalletWidthdraw -> ",
          });
          break;
        case "PaymentLimitException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              collection: "trustly",
              payload: { error: "withdraw_limit_exceeded" },
            },
            component: (component ? component : "") + " -> postWalletWidthdraw -> ",
          });
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { message: alert.payload?.payload?.errorSubMessage },
            },
            component: (component ? component : "") + " -> postWalletWidthdraw -> ",
          });
          break;
      }
    }
    if (alert.stop) return;
  }

  dispatch({
    type: POST_WITHDRAW,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> postWalletWidthdraw -> ",
  });
};

export const setAlert = (message, component) => {
  return {
    type: SET_ALERT,
    payload: message,
    component: (component ? component : "") + " -> setAlert -> ",
  };
};

export const clearAlert = (component) => {
  return {
    type: CLEAR_ALERT,
    component: (component ? component : "") + " -> clearAlert -> ",
  };
};

export const getCasinoGames = (platform, search, component) => async (dispatch) => {
  const reply = await api.get("/casino/groups", {
    params: {
      api: "menu",
    },
  });

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getCasinoGames -> ",
    });
    if (alert.stop) return;
  }

  // let payload = { ...reply.data.payload, leagueId: leagueId };

  dispatch({
    type: SAVE_CASINO_GAMES,
    payload: reply.data.payload.menu,
    component: (component ? component : "") + " -> getCasinoGames -> ",
  });
};

export const filterCasinoGamesWithPublisher = (publisher, component) => {
  return {
    type: FILTER_CASINO_GAMES,
    payload: publisher,
    component: (component ? component : "") + " -> filterCasinoGamesWithPublisher -> ",
  };
};

export const getAccountLiveCheck = (component) => async (dispatch) => {
  const reply = await api.get("/account/player-summary ", {
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required && !reply?.data?.payload?.success) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getAccountLiveCheck -> ",
    });
    if (alert.stop) return;
  }

  // let payload = { ...reply.data.payload, leagueId: leagueId };

  dispatch({
    type: GET_ACCOUNT_LIVECHECK,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getAccountLiveCheck -> ",
  });
};

export const deleteCasinoGame = (gameId, component) => {
  return {
    type: DELETE_CASINO_GAME,
    payload: gameId,
    component: (component ? component : "") + " -> deleteCasinoGame -> ",
  };
};

export const emptySlipTemplate = (slipTemplate, component) => async (dispatch) => {
  localStorage.setItem("slipBetsArray", []);
  dispatch({
    type: EMPTY_SLIP_TEMPLATE,
    payload: slipTemplate,
    component: (component ? component : "") + " -> emptySlipTemplate -> ",
  });
};

export const saveAutoSlipUpdateStatus = (status, component) => {
  return {
    type: SAVE_AUTO_SLIP_UPDATE_STATUS,
    payload: status,
    component: (component ? component : "") + " -> saveAutoSlipUpdateStatus -> ",
  };
};

export const blockAccount = (component) => async (dispatch) => {
  const reply = await api.put(
    "account/block",
    {
      params: {
        data: {
          reason: "panic_button ",
          period: "1",
          message: "block",
        },
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getCasinoGame -> ",
    });
    if (alert.stop) return;
  }

  await api.post("auth/signout", {}, { headers: authHeader() });

  localStorage.removeItem("jwt");
  localStorage.removeItem("exp");
  dispatch({
    type: SIGN_OUT,
    isManualSignOut: false,
    component: (component ? component : "") + " -> signOut -> ",
  });

  dispatch(toggleMyTicketsPanel(component, true));
};

export const resetRegisterUserFlag = (component) => {
  return {
    type: RESET_REGISTER_USER,
    component: (component ? component : "") + " -> saveAutoSlipUpdateStatus -> ",
  };
};

export const getVendors = (component) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/providers", {
      headers: authHeader(),
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getVendors -> ",
      });
      if (alert.stop) return;
    }

    dispatch({
      type: SAVE_VENDORS,
      payload: reply.data.payload,
      component: (component ? component : "") + " -> getVendors -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const connectShop = (data, component) => async (dispatch) => {
  const reply = await api.put(
    "account/user",
    {
      params: {
        data: data,
      },
    },
    { headers: authHeader() }
  );

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> updateUser -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: UPDATE_USER,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> updateUser -> ",
  });
};

export const confirmEmail = (component, userid, code) => async (dispatch) => {
  const reply = await api.get("account/confirm", {
    params: {
      email: userid,
      code: code,
    },
    headers: authHeader(),
  });

  dispatch({
    type: CONFIRM_EMAIL_ADDRESS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> confirmEmail -> ",
  });
};

export const resendConfirm = (component, userid) => async (dispatch) => {
  const reply = await api.get("account/resend", {
    params: {
      email: userid,
    },
    headers: authHeader(),
  });

  dispatch({
    type: RESEND_CONFIRM_EMAIL_ADDRESS,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> confirmEmail -> ",
  });
};

export const updateMaintenanceMode = (flag) => {
  return {
    type: SET_MAINTENANCE_MODE,
    payload: { maintenance: Boolean(flag) },
  };
};

export const getCasinoGamesGroupNames = (component) => async (dispatch) => {
  const reply = await api.get("/casino/groups", {
    params: {
      api: "group_names",
    },
  });
  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getCasinoGroupNames -> ",
    });
    if (alert.stop) return;
  }
  dispatch({
    type: GET_CASINO_GAME_GROUP_NAMES,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> getCasinoGroupNames -> ",
  });
};

export const searchCasinoGames = (component, data) => async (dispatch) => {
  const reply = await api.get("/casino/search", {
    params: {
      src: data,
    },
  });
  if (reply.data.type === "data") {
    dispatch({
      type: SEARCH_CASINO_GAMES,
      payload: reply.data.payload,
      component: (component ? component : "") + " -> searchCasinoGames -> ",
    });
  }
};

export const clearSearchResults = (component) => (dispatch) => {
  dispatch({
    type: CLEAR_CASINO_SEARCH_RESULTS,
    payload: [],
    component: (component ? component : "") + " -> clearSearchResults -> ",
  });
};

export const getCasinoGamesByGroupName = (component, data) => async (dispatch) => {
  dispatch({
    type: CASINO_GAMES_LIST_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getCasinoGamesByGroupName -> ",
  });
  dispatch({
    type: RESET_CASINO_GAMES_CACHE,
    payload: null,
    component: (component ? component : "") + " -> getCasinoGamesByGroupName -> ",
  });
  const reply = await api.get("/casino/groups", {
    params: {
      api: "group_list",
      groupName: data,
    },
  });
  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getCasinoGamesByGroupName -> ",
    });
    if (alert.stop) return;
  }
  dispatch({
    type: GET_CASINO_GAMES_BY_GROUP_NAME,
    payload: {
      games: reply.data.payload,
      filter: data,
    },
    component: (component ? component : "") + " -> getCasinoGamesByGroupName -> ",
  });
};

export const registerUserWithTrustly = (component, data) => async (dispatch, getState) => {
  const state = getState();
  const translation = state.i18n.translations[state?.i18n.locale]["trustly"];
  const url = window.location.href;
  let country = url.includes("sweden") ? "SWE" : "EST";
  let currency = url.includes("sweden") ? "SEK" : "EUR";
  const reply = await api.post("/wallet/initDeposit", {
    params: {
      amount: data.amount,
      country: country,
      currency: currency,
      method: "300002",
    },
  });

  if (reply?.data?.type === "success" && !reply?.data?.payload?.error) {
    window.location.href = reply.data.payload.redirectUrl;
  } else {
    const alert = validate(reply);
    if (alert?.payload.payload?.error) {
      let message;
      switch (alert?.payload.payload?.error) {
        case "PaymentLimitException":
          message = translation["default_deposit_limit"]?.replace("{{price}}", reply?.data?.payload?.params?.limitAmount);
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              type: "error",
              collection: "trustly",
              payload: { message: message },
            },
            component: (component ? component : "") + " -> registerUserWithTrustly -> ",
          });
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: alert.payload,
            component: (component ? component : "") + " -> registerUserWithTrustly -> ",
          });
          break;
      }
    } else {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> registerUserWithTrustly -> ",
      });
    }
  }

  localStorage.setItem("trustly_register_action", JSON.stringify(reply.data.payload));

  dispatch({
    type: REGISTER_USER_WITH_TRUSTLY,
    payload: reply.data.payload,
    component: (component ? component : "") + " -> registerUserWithTrustly -> ",
  });
};

export const checkTrustlyDepositStatus = (component, data, navigate) => async (dispatch, getState) => {
  const state = getState();
  const labels = state.i18n.translations[state.i18n.locale]["labels"];
  const reply = await api.post("/wallet/depositStatus", {
    params: {
      token: data.token,
      reference: data.reference,
    },
  });

  if (reply?.data?.payload?.status === "pending" && window.location.href?.includes("/register/trustly")) {
    const lang = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "fi";
    navigate(`/${lang}/trustly/deposit/waiting`);
  }

  if (
    !window.location.href.includes("/register/trustly") &&
    !window.location.href?.includes("/trustly/deposit/waiting") &&
    !window.location.href?.includes("/interfaces/message?type=deposit") &&
    reply?.data?.payload?.status !== "pending" &&
    reply?.data?.payload?.status !== "ok" &&
    reply?.data?.payload?.status !== "waiting"
  ) {
    localStorage.removeItem("trustly_register_action");
  }
  const alert = validate(reply);
  if (alert.required) {
    switch (alert.payload.type) {
      case "success":
        if (reply.data.payload.status === "ok") {
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { message: labels.deposit_succes },
            },
            component: (component ? component : "") + " -> registerUserWithTrustly -> ",
          });
        }
        break;
      case "error":
        dispatch({
          type: SET_ALERT,
          payload: {
            ...alert.payload,
            payload: { message: labels.deposit_fail },
          },
          component: (component ? component : "") + " -> registerUserWithTrustly -> ",
        });
        break;
      default:
        dispatch({
          type: SET_ALERT,
          payload: alert.payload,
          component: (component ? component : "") + " -> registerUserWithTrustly -> ",
        });
        break;
    }
    if (alert.stop) return;
  }
  dispatch({
    type: CHECK_TRUSTLY_DEPOSIT_STATUS,
    payload: reply.data,
    component: (component ? component : "") + " -> checkTrustlyDepositStatus -> ",
  });

  if (!window.location.href.includes("/register/trustly") && !window.location.href.includes("/trustly/deposit/waiting") && !window.location.href.includes("/interfaces/message?type=deposit")) {
    const lang = window.localStorage.getItem("lang") ? window.localStorage.getItem("lang") : "fi";
    navigate(`/${lang}/register/trustly`);
  }
};

export const signinWithTrustlyToken = (component, data, navigate, signInCallback) => async (dispatch) => {
  const affiliate_id = localStorage.getItem("affiliate_id");
  const affiliate_token = localStorage.getItem("affiliate_token");
  const limitsArray = [];
  let authHeaders = {};
  if (affiliate_id && affiliate_token) {
    authHeaders["affiliate_token"] = affiliate_token;
    authHeaders["affiliate_id"] = affiliate_id;
  }
  // Stake limits push
  if (data?.dailyStake) {
    limitsArray.push({
      limitType: "stake",
      limitValue: data?.dailyStake,
      limitPeriod: 1,
      autoRenew: true,
    });
  }
  if (data?.weeklyStake) {
    limitsArray.push({
      limitType: "stake",
      limitValue: data?.weeklyStake,
      limitPeriod: 7,
      autoRenew: true,
    });
  }
  if (data?.monthlyStake) {
    limitsArray.push({
      limitType: "stake",
      limitValue: data?.monthlyStake,
      limitPeriod: 30,
      autoRenew: true,
    });
  }
  // Loss limits push
  if (data?.dailyLoss) {
    limitsArray.push({
      limitType: "loss",
      limitValue: data?.dailyLoss,
      limitPeriod: 1,
      autoRenew: true,
    });
  }
  if (data?.weeklyLoss) {
    limitsArray.push({
      limitType: "loss",
      limitValue: data?.weeklyLoss,
      limitPeriod: 7,
      autoRenew: true,
    });
  }
  if (data?.monthlyLoss) {
    limitsArray.push({
      limitType: "loss",
      limitValue: data.monthlyLoss,
      limitPeriod: 30,
      autoRenew: true,
    });
  }
  //deposit limits push
  if (data?.dailyDeposit) {
    limitsArray.push({
      limitType: "deposit",
      limitValue: data?.dailyDeposit,
      limitPeriod: 1,
      autoRenew: true,
    });
  }
  if (data?.weeklyDeposit) {
    limitsArray.push({
      limitType: "deposit",
      limitValue: data?.weeklyDeposit,
      limitPeriod: 7,
      autoRenew: true,
    });
  }
  if (data?.monthlyDeposit) {
    limitsArray.push({
      limitType: "deposit",
      limitValue: data.monthlyDeposit,
      limitPeriod: 30,
      autoRenew: true,
    });
  }
  const reply = await api.post("/auth/signin", {
    params: {
      token: data.token,
      userPass: data.password,
      email: data.email,
      phoneNumber: data.phoneNumber,
      ...authHeaders,
    },
    limits: limitsArray,
    notifications: {
      sub_email: data?.sub_email,
      sub_sms: data?.sub_sms,
    },
  });
  const trustlyAlert = validate(reply);
  if (trustlyAlert.required) {
    if (reply?.data?.payload?.error) {
      switch (reply?.data?.payload?.error) {
        case "UserNotFoundException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...trustlyAlert.payload,
              payload: { error: "UserNotFoundException" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "UserFrozenException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...trustlyAlert.payload,
              payload: { error: "UserFrozenException" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "BadCredentialsException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...trustlyAlert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "CodeAlreadyUsedException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...trustlyAlert.payload,
              collection: "acceptance",
              payload: { error: "code_already_used" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: trustlyAlert.payload,
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
      }
    } else {
      dispatch({
        type: SET_ALERT,
        payload: trustlyAlert.payload,
        component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
      });
    }
    // if (trustlyAlert.stop) return;
  }
  if (reply.data.type === "data") {
    localStorage.removeItem("trustly_register_action");
    const loginReply = await api.post("auth/signin", {
      params: {
        email: data.email,
        userPass: data.password,
      },
    });
    const alert = validate(loginReply);
    if (alert.required) {
      switch (alert.payload.payload.error) {
        case "UserNotFoundException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "UserNotFoundException" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "UserFrozenException":
          dispatch({
            type: SET_ALERT,
            payload: { ...alert.payload, payload: { error: "UserFrozenException" } },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "BadCredentialsException":
          dispatch({
            type: SET_ALERT,
            payload: {
              ...alert.payload,
              payload: { error: "BadCredentialsException" },
            },
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
        case "CodeAlreadyUsedException":
          window.location.reload();
          break;
        default:
          dispatch({
            type: SET_ALERT,
            payload: alert.payload,
            component: (component ? component : "") + " -> signinWithTrustlyToken -> ",
          });
          break;
      }
      if (alert.stop) return;
    }

    if (loginReply.data.payload.jwt) {
      const { jwt, exp, loginTS } = loginReply.data.payload;

      localStorage.setItem("jwt", jwt);
      localStorage.setItem("exp", exp);
      localStorage.setItem("loginTS", loginTS);

      dispatch({
        type: SIGN_IN_SUCCESS,
        payload: loginReply.data.payload,
        component: (component ? component : "") + " -> signIn -> ",
      });
    } else {
      if (loginReply.data.payload.emailConfirmed === false) {
        dispatch({
          type: SIGN_IN_NOT_CONFIRMED_EMAIL,
          email: loginReply.data.payload.email,
          component: (component ? component : "") + " -> signIn -> ",
        });
      } else {
        if (loginReply.data.payload.rgCooloffExpires !== 0) {
          dispatch({
            type: SIGN_IN_FAIL_BLOCKED_USER,
            payload: loginReply.data.payload,
            component: (component ? component : "") + " -> signIn -> ",
          });
        } else {
          dispatch({
            type: SIGN_IN_FAIL,
            component: (component ? component : "") + " -> signIn -> ",
          });
          dispatch({
            type: SET_ALERT,
            payload: loginReply.data.payload,
            component: (component ? component : "") + " -> signIn -> ",
          });
        }
      }
    }
  }
  dispatch({
    type: REGISTER_USER_WITH_TRUSTLY,
    payload: {},
  });
  dispatch({
    type: CHECK_TRUSTLY_DEPOSIT_STATUS,
    payload: {},
  });
  signInCallback();
  const regAffiliateType = localStorage.getItem("affiliate_type");
  if (regAffiliateType && regAffiliateType === "casino") {
    navigate("/casino/dashboard");
    window.location.reload();
  } else if (regAffiliateType && regAffiliateType === "sports") {
    navigate("/");
    window.location.reload();
  } else {
    navigate("/");
    window.location.reload();
  }
};

export const updateOutrightOddsInLeague = (odds, component) => {
  return {
    type: UPDATE_OUTRIGHT_LEAGUE_ODDS,
    payload: odds,
    component: (component ? component : "") + " -> updateOutrightOddsInLeague -> ",
  };
};

export const refreshUserSession = (component) => async (dispatch, getState) => {
  const state = getState();
  const translation = state.i18n.translations[state.i18n.locale];
  const jwt = localStorage.getItem("jwt");
  let jsSession = "";
  if (jwt) {
    jsSession = jwt.split("|")[0];
  }
  try {
    const reply = await api.get("/auth/refresh-session", {
      headers: {
        Authorization: jwt,
      },
    });
    const alert = validate(reply);
    if (reply?.data?.type === "success" && reply?.data?.payload?.success) {
      localStorage.setItem("exp", reply.data.payload.exp);
      localStorage.setItem("jwt", `${jsSession}|${reply.data.payload.jwt}`);
      if (reply.data.payload?.loginTS) {
        localStorage.setItem("loginTS", reply.data.payload.loginTS);
      } else {
        const timeNow = new Date();
        localStorage.setItem("loginTS", timeNow.getTime() / 1000);
      }
    }
    if (alert.required) {
      if (reply.data.type === "success") {
        dispatch({
          type: SET_ALERT,
          // payload: { ...alert.payload, payload: { message: "You are back" } },
          payload: {
            ...alert.payload,
            payload: { message: translation.labels.reality_successful },
          },
          component: (component ? component : "") + " -> refreshUserSession ",
        });
      } else {
        dispatch({
          type: SET_ALERT,
          payload: alert.payload,
          component: (component ? component : "") + " -> refreshUserSession ",
        });
      }
      if (alert.stop) return;
    }
  } catch (error) {
    const alert = validate(error.response);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> refreshUserSession ",
      });
    }
  }
};

export const removeEndedSlipGames = (component) => {
  return {
    type: DELETE_ENDED_SLIP_GAMES,
    payload: null,
    component: (component ? component : "") + " -> removeEndedSlipGames -> ",
  };
};

export const getMaintananceStatus = (locale, component) => async (dispatch) => {
  const reply = await api.get("/services/Maintenance", {
    params: {
      lang: locale,
    },
  });
  if (reply?.data?.type === "data" && reply?.data?.payload) {
    dispatch({
      type: SET_MAINTENANCE_MODE,
      payload: reply?.data?.payload,
      component: (component ? component : "") + " -> getMaintananceStatus -> ",
    });
  }
};

export const getWPContent = (type, page, lang, lm, component) => async (dispatch) => {
  const reply = await api.get("wp-content/content", {
    params: {
      type,
      lang,
      sk: page,
      lm,
    },
    headers: authHeader(),
  });

  const alert = validate(reply);
  if (alert.required && reply.data.type !== "success") {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getWPContent -> ",
    });
    if (alert.stop) return;
  }

  dispatch({
    type: GET_WP_CONTENT,
    payload: reply.data.payload?.contents ? reply.data.payload?.contents : [],
    component: (component ? component : "") + " -> getWPContent -> ",
  });
};

export const getWPNewsContent = (category, component) => async (dispatch) => {
  const reply = await axios.get("https://www.cms-nubet.com/wp-json/wp/v2/posts/", {
    params: {
      per_page: 100,
      categories: category,
      _fields: "id,content,categories,title",
    },
    headers: authHeader(),
  });

  dispatch({
    type: GET_WP_NEWS_CONTENT,
    payload: reply.data ? reply.data : [],
    component: (component ? component : "") + " -> getWPNewsContent -> ",
  });
};

export const getFeaturedGames = (component) => async (dispatch) => {
  const lang = localStorage.getItem("lang");
  dispatch({
    type: FEATURED_GAMES_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getFeaturedGames -> ",
  });
  try {
    const reply = await api.get(`/featured/games?lang=${lang}`);
    if (reply?.data?.type === "data" && reply?.data?.payload) {
      dispatch({
        type: GET_FEATURED_GAMES,
        payload: reply?.data?.payload,
        component: (component ? component : "") + " -> getFeaturedGames -> ",
      });
    }
    dispatch({
      type: FEATURED_GAMES_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getFeaturedGames -> ",
    });
  } catch (error) {
    dispatch({
      type: FEATURED_GAMES_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getFeaturedGames -> ",
    });
  }
};

export const getPrecannedBets = (component) => async (dispatch) => {
  const lang = localStorage.getItem("lang");
  const reply = await api.get(`/featured/precanned?lang=${lang}`);
  if (reply?.data?.type === "data" && reply?.data?.payload) {
    dispatch({
      type: GET_PRECANNED_BETS,
      payload: reply?.data?.payload,
      component: (component ? component : "") + " -> getPrecannedBets -> ",
    });
  }
};

export const getFeaturedOutrights = (component, type, params) => async (dispatch) => {
  const lang = localStorage.getItem("lang");
  dispatch({
    type: FEATURED_OUTRIGHTS_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getFeaturedOutrights -> ",
  });
  try {
    const reply = await api.get(`/featured/outrights?lang=${lang}`);
    dispatch({
      type: FEATURED_OUTRIGHTS_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getFeaturedOutrights -> ",
    });
    if (reply?.data?.type === "data" && reply?.data?.payload) {
      const data = reply.data.payload;
      if (type !== "slip-action") {
        dispatch({
          type: GET_FEATURED_OUTRIGHTS,
          payload: data,
          component: (component ? component : "") + " -> getFeaturedOutrights -> ",
        });
      }
    }
  } catch (error) {
    dispatch({
      type: FEATURED_OUTRIGHTS_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getFeaturedOutrights -> ",
    });
  }
};

const checkSelectedOdd = (odd, index, game, bets) => {
  // console.log(odd);

  let payload = {
    gameId: game.gameid,
    // isLive: game.isLive
  };

  const marketId = odd.m;
  const selectionIds = odd.s?.split(",");
  const prices = odd.p?.split(",");
  const hc = odd.hc;
  const shc = odd.shc;
  const isActives = odd.iA?.split(",");

  if (selectionIds?.length < 3 && selectionIds?.length > 1) {
    selectionIds.unshift(null);
    prices.unshift(null);
    isActives.unshift(null);
  } else {
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
    selectionIds.push(null);
    prices.push(null);
    isActives.push(null);
  }

  if (Number(prices[index]) < 1) return false;

  // payload.isMultiBet = 0;
  // payload.isBank = 0;
  payload.marketId = Number(marketId);
  payload.selectionId = Number(selectionIds[index]);
  payload.price = Number(prices[index]);
  payload.hc = hc;
  if (shc) {
    payload.shc = shc;
  }

  // payload.isActive = Number(isActives[index]);
  // console.log(payload);

  let flag = false;

  if (!bets) return flag;

  for (let i = 0; i < bets?.length; i++) {
    let bet = bets[i];
    // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
    if (bet.gameId === payload.gameId && bet.marketId === payload.marketId && bet.selectionId === payload.selectionId && bet.hc === payload.hc && bet.shc === payload.shc) {
      flag = true;
      break;
    }
  }

  // for (let key in slipTemplates) {
  //   if (slipTemplates[key].slipTemplate === selectedSlipTemplate) {

  //     let bets = slipTemplates[key].settings.bets;

  //     for (let nextKey in bets) {
  //       if ((bets[nextKey].gameId === payload.gameId) && (bets[nextKey].hc === payload.hc) && (bets[nextKey].isActive === payload.isActive) && (bets[nextKey].isBank === payload.isBank) && (bets[nextKey].isLive === payload.isLive) && (bets[nextKey].isMultiBet === payload.isMultiBet) && (bets[nextKey].marketId === payload.marketId) && (bets[nextKey].selectionId === payload.selectionId)) {
  //         flag = true;
  //         break;
  //       }
  //     }
  //   }
  // }

  return flag;
};

const checkSelectedOutrightOdd = (odd, index, game, bets) => {
  let payload = {
    gameId: game.gameid,
    // isLive: game.isLive
  };

  // payload.isMultiBet = 0;
  // payload.isBank = 0;
  payload.marketId = odd.m;
  payload.selectionId = odd.s;
  payload.price = odd.p;
  payload.hc = odd.hc;
  // payload.isActive = Number(isActives[index]);

  let flag = false;

  if (!bets) return flag;

  for (let i = 0; i < bets?.length; i++) {
    let bet = bets[i];
    // if ((bet.gameId === payload.gameId) && (bet.hc === payload.hc) && (bet.isActive === payload.isActive) && (bet.isBank === payload.isBank) && (bet.isLive === payload.isLive) && (bet.isMultiBet === payload.isMultiBet) && (bet.marketId === payload.marketId) && (bet.selectionId === payload.selectionId)) {
    if (bet.gameId === payload.gameId && bet.marketId === payload.marketId && bet.selectionId === payload.selectionId && bet.hc === payload.hc) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const getFeaturedSlip = (component, slipType, shouldAddToSlip, bets) => async (dispatch) => {
  const lang = localStorage.getItem("lang");
  dispatch({
    type: FEATURED_SLIPS_LOADING,
    payload: true,
    component: (component ? component : "") + " -> getFeaturedSlip -> ",
  });
  const reply = await api.get(`/featured/slip?lang=${lang}`);
  if (reply?.data?.type === "data" && reply?.data?.payload) {
    dispatch({
      type: GET_FEATURED_SLIP,
      payload: reply?.data?.payload,
      component: (component ? component : "") + " -> getMaintananceStatus -> ",
    });
  }
  dispatch({
    type: FEATURED_SLIPS_LOADING,
    payload: false,
    component: (component ? component : "") + " -> getFeaturedSlip -> ",
  });

  if (shouldAddToSlip && slipType) {
    const copiedSlipsList = { ...reply?.data?.payload };
    const slipsToAdd = copiedSlipsList[slipType];

    Object.values(slipsToAdd)?.forEach((slipToAdd) => {
      const slipLeagueGames = slipToAdd && slipToAdd["games"] ? Object.values(slipToAdd["games"]) : [];
      const slipLeagueOutrights = slipToAdd && slipToAdd["outrights"] ? Object.values(slipToAdd["outrights"]) : [];
      const totalGames = [];
      const totalOutrightGames = [];

      slipLeagueGames?.forEach((lgGame) => {
        Object.values(lgGame)?.forEach((sinGame) => {
          totalGames.push(sinGame);
        });
      });

      slipLeagueOutrights?.forEach((lgGame) => {
        Object.values(lgGame)?.forEach((sinGame) => {
          totalOutrightGames.push(sinGame);
        });
      });

      totalGames?.forEach((game) => {
        const index = 0;
        const odd = game["odds"] ? game["odds"][0] : {};
        let payload = {
          gameId: game.gameid,
          isLive: game.isLive,
          // isLive: game.isLive
        };

        if (checkSelectedOdd(odd, 0, game, bets)) return;

        const marketId = odd.m;
        const selectionIds = odd.s?.split(",");
        const prices = odd.p?.split(",");
        const hc = odd.hc;
        const shc = odd?.shc;
        const isActives = odd.iA?.split(",");

        if (selectionIds?.length < 3 && selectionIds?.length > 1) {
          selectionIds.unshift(null);
          prices.unshift(null);
          isActives.unshift(null);
        } else if (selectionIds?.length === 1) {
          selectionIds.push(null);
          prices.push(null);
          isActives.push(null);
          selectionIds.push(null);
          prices.push(null);
          isActives.push(null);
        }

        if (Number(prices[index]) < 1) return false;
        if (Number(prices[index]) === 1) return false;
        if (Number(isActives[index]) === 0) return false;

        const returnedGame = checkGameSession(game, "all");
        let newGame = { ...game };
        if (returnedGame) {
          newGame = calcGame(returnedGame);
        }

        payload.marketId = Number(marketId);
        payload.selectionId = Number(selectionIds[index]);
        payload.price = Number(prices[index]);
        payload.hc = hc;
        payload.isActive = Number(isActives[index]);
        payload.game = newGame;
        if (shc) {
          payload.shc = shc;
        }
        dispatch(saveBet(payload, 0, component));
      });

      totalOutrightGames?.forEach((game) => {
        const odd = game?.odds[0];
        const index = 0;
        let payload = {
          gameId: game.gameid,
          isLive: game.isLive,
        };

        if (checkSelectedOutrightOdd(odd, 0, game, bets)) return;
        const marketId = odd.m;
        const selectionId = odd.s;
        const price = odd.p;
        const hc = odd.hc;
        const isActive = odd.iA;

        let newGame = { ...game };
        const returnedGame = checkOutrightGameSession(game, "all");
        if (returnedGame) {
          newGame = calcGame(returnedGame);
        }

        payload.marketId = marketId;
        payload.selectionId = selectionId;
        payload.price = price;
        payload.hc = hc;
        payload.hn = odd.hn;
        payload.sn = odd.sn;
        payload.nr = odd?.nr;
        payload.isActive = isActive;
        payload.game = {
          ...newGame,
          score_set_calculated: {
            away: [],
            home: [],
          },
        };
        payload.isOutright = true;
        dispatch(saveBet(payload, 0, component));
      });
    });
  }
};

export const updateFeaturedGamesInBanner = (odds, component) => {
  return {
    type: UPDATE_FEATURED_GAMES,
    payload: odds,
    component: (component ? component : "") + " -> updateFeaturedGamesInBanner -> ",
  };
};

export const updateFeaturedOutrightsInBanner = (odds, component) => {
  return {
    type: UPDATE_FEATURED_OUTRIGHTS,
    payload: odds,
    component: (component ? component : "") + " -> updateFeaturedOutrightsInBanner -> ",
  };
};

export const updateFeaturedSlipsInBanner = (odds, type, component) => {
  return {
    type: UPDATE_FEATURED_SLIP,
    payload: odds,
    updateType: type,
    component: (component ? component : "") + " -> updateFeaturedSlipsInBanner -> ",
  };
};

export const removeEndedFeaturedGame = (games, component) => {
  return {
    type: REMOVE_ENDED_FEATURED_GAME,
    payload: games,
    component: (component ? component : "") + " -> updateFeaturedSlipsInBanner -> ",
  };
};

export const removeEndedFeaturedSlipGame = (games, component) => {
  return {
    type: REMOVE_ENDED_FEATURED_SLIP,
    payload: games,
    component: (component ? component : "") + " -> updateFeaturedSlipsInBanner -> ",
  };
};

export const getBetBuilderSlipBet = (betslipUid, callback, component) => async (dispatch) => {
  try {
    const reply = await api.post("/account/betbuilder", {
      params: {
        betSlipId: betslipUid,
      },
      headers: authHeader(),
    });
    callback(reply?.data);
  } catch (error) {
    console.log(error);
  }
};

export const getBetBuilderGames = (sportCode, openLeague, component, leagueID) => async (dispatch) => {
  let leagueIds = [];

  leagueIds = leagueID;

  if (!leagueIds || leagueIds === "") return;

  let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "fi";

  const reply = await apiCatalog.get("games/" + leagueIds + "/" + lang);

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getGames -> ",
    });
    if (alert.stop) return;
  }

  let games = reply.data.payload.games;

  games = games ? Object.values(games) : [];
  games = games.filter((item) => item.isBetBuilderOn);

  let payload = {
    games,
    openLeague: openLeague ? openLeague : false,
  };

  dispatch({
    type: SAVE_BETBUILDER_GAMES,
    payload: payload,
    leagueId: leagueID,
    sportCode: sportCode,
    component: (component ? component : "") + " -> getGames -> ",
  });
};

export const getCustomerFreeSpins = (component, filter) => async (dispatch) => {
  if (filter === "freespins") {
    dispatch({
      type: CASINO_FREE_SPINS_LOADING,
      payload: true,
      component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
    });
    dispatch({
      type: RESET_CASINO_GAMES_CACHE,
      payload: null,
      component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
    });
  }
  try {
    const reply = await api.get("/account/bonuses/freeSpins", {
      headers: authHeader(),
      params: {
        page: 1,
      },
    });
    if (!reply?.data?.type === "data") {
      dispatch({
        type: CASINO_FREE_SPINS_LOADING,
        payload: false,
        component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
      });
      return;
    }
    if (reply?.data?.type === "data" && reply?.data?.payload?.error) {
      dispatch({
        type: SET_ALERT,
        payload: { ...reply?.data, type: "error" },
        component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
      });
      return;
    }
    dispatch({
      type: GET_CUSTOMER_FREE_SPINS,
      payload: reply?.data?.payload?.bonuses,
      filter: filter,
      component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
    });
    dispatch({
      type: CASINO_FREE_SPINS_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
    });
  } catch (error) {
    dispatch({
      type: CASINO_FREE_SPINS_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getCustomerFreeSpins -> ",
    });
  }
};
export const removeEndedGames = (games, leagueFilter, component) => {
  return {
    type: REMOVE_ENDED_LEAGUE_GAMES,
    payload: games,
    leagueFilter: leagueFilter,
    component: (component ? component : "") + " -> removeEndedGames -> ",
  };
};

export const removeEndedOutrightGames = (games, leagueFilter, component) => {
  return {
    type: REMOVE_ENDED_OUTRIGHT_GAMES,
    payload: games,
    leagueFilter: leagueFilter,
    component: (component ? component : "") + " -> removeEndedOutrightGames -> ",
  };
};

export const setLiveChatWidget = (component) => {
  return {
    type: SET_LIVECHAT_WIDGET,
    payload: null,
    component: (component ? component : "") + " -> setLiveChatWidget -> ",
  };
};

export const updateLimitsStatus = (data, callback, component) => async (dispatch) => {
  const reply = await api.put(
    "/account/limits",
    {
      params: data,
    },
    { headers: authHeader() }
  );
  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> updateLimitsStatus -> ",
    });
    if (alert.stop) return;
  }
  callback();
  dispatch({
    type: UPDATE_LIMITS_STATUS,
    payload: {},
    component: (component ? component : "") + " -> updateLimitsStatus -> ",
  });
};

export const updateBetbuilderGames = (games, component) => {
  return {
    type: UPDATE_BETBUILDER_GAMES,
    payload: games,
    component: (component ? component : "") + " -> updateBetbuilderGames -> ",
  };
};

export const handleUpdateAlert = (status, component) => {
  return {
    type: SHOULD_ALERT_ODDS_UPDATE,
    payload: null,
    isOpen: status,
    component: (component ? component : "") + " -> updateBetbuilderGames -> ",
  };
};

export const getPlayerPropsGames = (sportCode, openLeague, component, leagueID, marketSet, goalScorers) => async (dispatch) => {
  let leagueIds = [];

  leagueIds = leagueID;

  if (!leagueIds || leagueIds === "") return;

  let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "fi";

  const reply = await apiCatalog.get("games/" + leagueIds + "/" + lang);

  const alert = validate(reply);
  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getPlayerPropsGames -> ",
    });
    if (alert.stop) return;
  }

  let games = reply.data.payload.games;

  games = games ? Object.values(games) : [];
  const availableGames = [];

  games.forEach((game) => {
    const playerOdds = game.odds.filter((odd) => marketSet.includes(odd.m));
    const goalscorerOdds = game.odds.filter((odd) => goalScorers.includes(odd.m));
    if (playerOdds && playerOdds.length) {
      availableGames.push({ ...game, hasGoalscorers: goalscorerOdds.length > 0, odds: playerOdds });
    }
  });

  let payload = {
    games: availableGames,
    openLeague: openLeague ? openLeague : false,
  };

  dispatch({
    type: GET_PLAYERPROPS_GATALOG_GAMES,
    payload: payload,
    leagueId: leagueID,
    sportCode: sportCode,
    component: (component ? component : "") + " -> getPlayerPropsGames -> ",
  });
};

export const getComboBoost = (component) => async (dispatch) => {
  const reply = await api.get("/account/bonuses/comboBoost");

  const alert = validate(reply);

  if (alert.required) {
    dispatch({
      type: SET_ALERT,
      payload: alert.payload,
      component: (component ? component : "") + " -> getComboBoost -> ",
    });
    if (alert.stop) return;
  }

  const res = reply?.data?.payload?.data && reply?.data?.payload?.success ? reply.data.payload : {};

  dispatch({
    type: GET_COMBO_BOOST,
    payload: res,
    component: (component ? component : "") + " -> getComboBoost -> ",
  });
};

export const deleteSearchResults = (component) => {
  return {
    type: DELETE_SEARCH_RESULTS,
    component: (component ? component : "") + " -> deleteSearchResults -> ",
  };
};

export const getExampleSearchItems = (lang, component) => async (dispatch) => {
  try {
    const reply = await api.get(`sports/leagues`, {
      params: { lang },
      headers: acceptLanguage(),
    });

    const leagues = reply?.data?.payload?.all && reply?.data?.payload.all["FB"] ? reply?.data?.payload.all["FB"].filter((league) => league.isTopLeague === 1) : [];
    dispatch({
      type: GET_EXAMPLE_SEARCHES,
      payload: leagues.slice(0, 6),
      component: (component ? component : "") + " -> getExampleSearchItems -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export function removePostedTicketState(component) {
  return {
    type: REMOVE_POSTED_TICKET_STATE,
    payload: null,
    component: (component ? component : "") + " -> removePostedTicketState -> ",
  };
}

// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// <--------------CASINO API REQUESTS START---------------->//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//
// ---------------------------------------------------//

export const getCasinoSlidersGames = (component, sliderFilter) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        groupName: sliderFilter,
      },
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getCasinoSlidersGames -> ",
      });
      if (alert.stop) return;
    }
    dispatch({
      type: GET_CASINO_SLIDER_GAMES,
      payload: reply.data?.payload?.games ? reply.data.payload.games : [],
      groupType: sliderFilter,
      component: (component ? component : "") + " -> getCasinoSlidersGames -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGamesByFeature = (component, params) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        ...params,
      },
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getCasinoGamesByFeature -> ",
      });
      if (alert.stop) return;
    }
    dispatch({
      type: GET_CASINO_GAMES_BY_FEATURE,
      payload: reply.data?.payload ? { data: reply.data.payload, groupId: params.groupName } : {},
      component: (component ? component : "") + " -> getCasinoGamesByFeature -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetCasinoFeatureGames = (component, groupId) => {
  return {
    type: RESET_FEATURE_GAMES,
    payload: groupId,
    component: (component ? component : "") + " -> resetCasinoFeatureGames -> ",
  };
};

export const getCasinoGamesByProvider = (component, params) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "group_list",
        ...params,
      },
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getCasinoGamesByProvider -> ",
      });
      if (alert.stop) return;
    }
    dispatch({
      type: GET_CASINO_GAMES_BY_PROVIDER,
      payload: reply.data?.payload ? reply.data.payload : {},
      component: (component ? component : "") + " -> getCasinoGamesByProvider -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetCasinoProviderGames = (component) => {
  return {
    type: RESET_CASINO_PROVIDER_GAMES,
    payload: null,
    component: (component ? component : "") + " -> resetCasinoProviderGames -> ",
  };
};

export const getUserFavoriteCasinoGames = (component) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "fav",
        type: "data",
      },
      headers: authHeader(),
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getUserFavoriteCasinoGames -> ",
      });
      if (alert.stop) return;
    }
    dispatch({
      type: GET_USER_FAVORITE_CASINO_GAMES,
      payload: reply.data.payload?.games,
      component: (component ? component : "") + " -> getUserFavoriteCasinoGames -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCasinoGame = (gameId, type, layout, component) => async (dispatch) => {
  try {
    dispatch({
      type: CASINO_GAME_LOADING,
      payload: true,
      component: (component ? component : "") + " -> getCasinoGame -> ",
    });
    const reply = await api.get("casino/game", {
      params: {
        type: type,
        gameId: gameId,
        device: layout === "touch" ? "mobile" : "desktop",
      },
      headers: authHeader(),
    });
    dispatch({
      type: CASINO_GAME_LOADING,
      payload: false,
      component: (component ? component : "") + " -> getCasinoGame -> ",
    });

    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getCasinoGame -> ",
      });
      if (alert.stop) return;
    }

    dispatch({
      type: SELECT_CASINO_GAME,
      payload: reply.data.payload,
      gameId,
      component: (component ? component : "") + " -> getCasinoGame -> ",
    });
  } catch (error) {
    const alert = validate(error?.response);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getCasinoGame -> ",
      });
      if (alert.stop) return;
    }
  }
};

export const getUserFavGamesIds = (component) => async (dispatch) => {
  try {
    const reply = await api.get("/casino/groups", {
      params: {
        api: "fav",
      },
      headers: authHeader(),
    });
    const alert = validate(reply);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> getUserFavGamesIds -> ",
      });
      if (alert.stop) return;
    }
    dispatch({
      type: GET_USER_FAV_GAMES_IDS,
      payload: reply.data.payload?.games ? reply.data.payload?.games : [],
      component: (component ? component : "") + " -> getUserFavGamesIds -> ",
    });
  } catch (error) {
    console.log(error);
  }
};

export const saveFavoriteCasinoGames = (data, component) => async (dispatch) => {
  try {
    const reply = await api.post("/casino/favourite", data, {
      headers: authHeader(),
    });
    const alert = validate(reply);
    if (alert.required) {
      if (!reply?.data?.payload?.success) {
        dispatch({
          type: SET_ALERT,
          payload: alert.payload,
          component: (component ? component : "") + " -> saveFavoriteCasinoGames -> ",
        });
      }
      if (alert.stop) return;
    }
    dispatch(getUserFavGamesIds(component));
  } catch (error) {
    const alert = validate(error?.response?.data);
    if (alert.required) {
      dispatch({
        type: SET_ALERT,
        payload: alert.payload,
        component: (component ? component : "") + " -> saveFavoriteCasinoGames -> ",
      });
      if (alert.stop) return;
    }
  }
};

// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// ------------------------CASINO REQUESTS END------------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//

// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// ----------------------------DATABET ACTIONS------------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//

export const generateDatabetToken = (component, isAuthenticated, lang) => async (dispatch) => {
  let unique_id = uuid();
  const saved_id = localStorage.getItem("databet_unique_id");
  if (saved_id) {
    unique_id = saved_id;
  } else {
    localStorage.setItem("databet_unique_id", unique_id);
  }
  try {
    const token_value = localStorage.getItem("jwt");
    const user = JSON.parse(localStorage.getItem("betworx_logged_in_user"));
    const reply = await api.post(
      "/token/create",
      {
        locale: lang,
        currency: "EUR",
        player_id: user?.user_id,
        params: {
          some_token: token_value || unique_id,
          internal_player_id: user?.user_id,
          session_id: token_value || unique_id,
        },
        context: {
          bet: {
            min_stake_sum: "1.00",
          },
        },
      },
      {
        headers: authHeader(),
      }
    );
    if (reply?.data?.token) {
      dispatch({
        type: GENERATE_DATABET_TOKEN,
        payload: reply.data.token,
        isAuthenticated: isAuthenticated,
        lang: lang,
        component: (component ? component : "") + " -> saveFavoriteCasinoGames -> ",
      });
    }
  } catch (error) {
    console.log(error);
  }
};

// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
// ----------------------------DATABET ACTIONS END--------------------------------//
// -------------------------------------------------------------------------------//
// -------------------------------------------------------------------------------//
