/**
 * BonusTerms - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM VEDONLYÖNTI BONUS KÄYTTÖEHDOT </h1>
            Suomenkielinen versio 1.3 Viimeisin päivitys 13.2.2025.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Näitä urheiluvedonlyönnin ehtoja sovelletaan Nubetin urheiluvedonlyöntiin.<br></br>
            <br></br>
            1.2. Näissä urheiluvedonlyöntibonusehdoissa kuvatun urheiluvedonlyöntibonuksen myöntäjä on Nubet verkkosivustolla Nubet.com, jota ylläpitää Vana Lauri OÜ, Virossa rekisteröidyn yrityksen
            rekisteröintinumerolla 16427120 rekisteröity yritys, jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna, Viro (jäljempänä "Nubet" tai "yritys"). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen
            myöntämä toimilupa ja sen valvonta, ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä: 10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet voi myöntää sinulle minkä tahansa tyyppistä urheiluvedonlyöntibonusluottoa tai muunlaisia bonuksia, kuten ilmaisvedon (jäljempänä myös "bonusraha", "bonus" tai "urheiluvedonlyöntibonus"), jos
            tietyt ehdot täyttyvät. Bonuksen käyttöön liittyviä
            vedonlyöntiehtoja (jäljempänä myös "vedonlyönti") sovelletaan kaikkiin myönnettyihin urheiluvedonlyöntibonuksiin, ja vedonlyöntisäännöt ilmoitetaan pelaajalle etukäteen verkkosivustolla ja sähköpostitse.
            Myönnetty urheiluvedonlyöntibonus hyvitetään automaattisesti pelitilille bonusrahana, jota sitovat näissä urheiluvedonlyöntibonusehdoissa määritellyt säännöt.<br></br>
            <br></br>
            <h3 id="Tc">2. YLEISET VEDONLYÖNTI BONUS KÄYTTÖEHDOT</h3>
            2.1. Näitä yleisiä urheiluvedonlyöntibonusehtoja sovelletaan kaikkiin Nubetin myöntämiin urheiluvedonlyöntibonuksiin ja kampanjatarjouksiin.<br></br>
            <br></br>
            2.2. Yhtiö voi harkintansa mukaan sulkea yksittäisiä pelaajia tarjouksen ulkopuolelle. Yhtiöllä on yksinomainen harkintavalta
            mitätöidä bonukset ja niihin liittyvät voitot, jos yhtiö näkee, että pelaaja ei noudata bonussääntöjä. Yhtiö voi myös muuttaa bonuksen ehtoja tai lopettaa sen tarjoamisen milloin tahansa ilman
            ennakkoilmoitusta.<br></br>
            <br></br>
            2.3. Nubet urheiluvedonlyönti-bonus on voimassa ja se on käytettävä tietyn kampanjajakson aikana. Jos sitä ei käytetä, se vanhenee, eikä sitä voi käyttää sen jälkeen.<br></br>
            <br></br>
            2.4. Nubeturheiluvedonlyönti-bonus on käytettävissä vain kerran asiakasta, pelitiliä, kotitaloutta, talletuksen tekemiseen käytetyn rahansiirtomenetelmän ja/tai tilin omistajaa, yhteistä tietokonetta
            (yliopisto, työpaikka, internetkahvila jne.) ja/tai IP-osoitetta kohden.<br></br>
            <br></br>
            2.5. Nubet urheiluvedonlyönti-bonusta ei voi yhdistää muihin bonuksiin, ellei nimenomaisesti toisin mainita, eikä sitä voi käyttää yhdessä muiden kampanjoiden kanssa.<br></br>
            <br></br>
            2.6. Nubetin urheiluvedonlyönti-bonuskampanjatarjouksen, joka on lähetetty sähköpostitse, voi lunastaa vain alkuperäinen bonussähköpostin vastaanottaja. Bonuksen vastaanottavan ja käyttävän
            sähköpostiosoitteen on oltava sama kuin pelitiliä luotaessa. Nubet pidättää oikeuden tutkia, onko urheiluvedonlyönti-bonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti
            pelitilillä käytettyyn sähköpostiosoitteeseen. Tämän säännön rikkominen voi johtaa bonuksen ja siihen liittyvien voittojen takavarikointiin.<br></br>
            <br></br>
            2.7. Nubetin urheiluvedonlyönti-bonus, joka on sidottu talletuksen tekemiseen, on voimassa vain silloin, kun käytetään Nubetin tarjoamia maksutapoja, joita ei ole nimenomaisesti suljettu pois
            urheiluvedonlyönti-bonustarjouksen maksutapana. Tilinhaltijan ja maksutavan haltijan on oltava sama. Pelitilin luottosaldoa, joka on peräisin pelaajan tekemästä talletuksesta ilman bonuksen käyttöä ja jota
            eivät siten sido bonusehdot, kutsutaan oikean rahan saldoksi (jäljempänä ”oikean rahan saldo").<br></br>
            <br></br>
            2.8. Nubet urheiluvedonlyönti-bonus hyvitetään pelitilisi saldoon. Jos urheiluvedonlyönti-bonukseen liittyy panostus, pelitilin saldoa ei voi nostaa tai muuntaa oikean rahan saldoksi ennen kuin panostus on
            suoritettu loppuun bonusehdoissa määritellyllä tavalla.<br></br>
            <br></br>
            2.9. Vain annettu bonus on kierrätyksen alainen.<br></br>
            <br></br>
            2.10. Vain maksetut vedot lasketaan mukaan panostusvaatimukseen. Jos veto/panos palautetaan keskeytyneen tapahtuman vuoksi tai tapahtuma julistetaan peruuntuneeksi tai mitättömäksi tai jos otteluvetoa ei
            voida jostain muusta syystä ratkaista normaalisti, vetosummaa ei lasketa mukaan panosvaatimukseen. Sama pätee myös vedonlyöntiin, joka lunastetaan cash outina.<br></br>
            <br></br>
            2.11. Kun pelaaja on lyönyt vetoa aktiivisella bonuksella, lunastettua bonusta ei voi enää peruuttaa menettämättä bonusta.<br></br>
            <br></br>
            
            2.12. Ellei toisin mainita, kaikki saadut urheiluvedonlyöntibonukset on kierrätettävä vähintään kuusi (6) kertaa, ennen kuin ne voidaan maksaa tai niitä voidaan yrittää nostaa. Bonusrahalla tehtyjä voittoja
            ei makseta ennen kuin bonuksen kierrätysehdot täyttyvät. Bonuksen kierrätysehtojen ollessa käynnissä, kotiutuksia ei hyväksytä missään muodossa.
            <br></br>
            <br></br>
            2.13. Bonuksen kierrätysvaatimukset on täytettävä 30 vuorokauden kuluessa. Jos bonusehtoja ei täytetä ajoissa, Nubet pidättää oikeuden peruuttaa bonuksen määrän ja kaikki siitä saadut voitot.
            <br></br>
            <br></br>
            2.14. Nubet valvoo kaikkien bonusten käyttöä epäsäännöllisten pelitapojen varalta.<br></br>
            <br></br>
            2.15. Urheiluvedonlyönti-bonuksen väärinkäyttö on bonusehtojen rikkominen. Nubet olettaa bonusehtojen rikkomisen seuraavissa tapauksissa:<br></br>
            <br></br>
            <ListItem>bonuksen moninkertainen käyttö saman henkilön toimesta tai samaa IP:tä tai päätelaitetta käyttäen;</ListItem>
            <ListItem>bonustarjouksen epäilty järjestäytynyt käyttö;</ListItem>
            <ListItem>
              sellaisten vetojen asettaminen, jotka niiden tuloksesta riippumatta takaavat urheiluvedonlyönti-bonuksen voiton yksittäiselle henkilölle tai yhdessä yhteistyötä tekevälle pelaajaryhmälle, joka tekee niin
              sanottuja "varmoja voittoja"
            </ListItem>
            <br></br>
            2.16. Jos epäillään, että urheiluvedonlyönti-bonusehtoja on rikottu tai asiakas on asettanut vedon, joka takaa voiton urheiluvedonlyönnin talletusbonuksen, ilmaisvedon tai muun kampanjatarjouksen ansiosta
            vedon lopputuloksesta riippumatta, Nubet pidättää itsellään oikeuden vaatia Nubetin urheiluvedonlyöntibonus-tarjouksen takaisin, oikaista tai peruuttaa asetetut vedot, kieltäytyä pyydetyistä kotiutuksista ja
            sulkea tilit.<br></br>
            <br></br>
            2.17. Bonusta ei hyvitetä, jos sinut on suljettu Nubet-tarjouksen ulkopuolelle tai poistettu sen piiristä.<br></br>
            <br></br>
            2.18. Urheiluvedonlyönti-bonus on voimassa, kunnes panostus on suoritettu tai urheiluvedonlyönti-bonus peruutetaan.<br></br>
            <br></br>
            2.19. Nubet pidättää oikeuden muuttaa, keskeyttää tai peruuttaa bonuksen ja kampanjan keston milloin tahansa.<br></br>
            <br></br>
            2.20. Sovelletaan Nubetin ehtoja.<br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
