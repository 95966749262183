/**
 * BonusTerms - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTerms extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i SPORDIENNUSTUSE BOONUSE TINGIMUSED</h1>
            Eestikeelne versioon 1.3, viimati uuendatud 13. veebruaaris 2025.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Siinsed spordiennustuse tingimused kehtivad Nubeti spordikihlvedude kohta.<br></br>
            <br></br>
            1.2. Siinsetes spordiennustuse boonuse tingimustes nimetatud boonust pakub Nubet veebisaidil nubet.com, mida haldab Eestis registreeritud ettevõte Vana Lauri OÜ, registrikood 16427120, registrijärgne aadress
            Roseni 10-135, 10111 Tallinn, Estonia (edaspidi: <b>Nubet</b> või <b>ettevõte</b>). Vana Lauri OÜ tegutseb Eesti Maksu- ja Tolliameti järelevalve all ja järgmiste tegevuslubade alusel: HKT000066 (välja antud
            10.07.2022) ja HKT000067 (välja antud 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet võib teatavate tingimuste täitmise korral anda teile mis tahes tüüpi spordiennustuse boonuse krediiti või muud tüüpi boonuseid, nagu tasuta panuseid (edaspidi: <b>boonusraha</b>, <b>boonus</b> või{" "}
            <b>spordiennustuse boonus</b>). Iga antud spordiennustuse boonuse
            puhul kehtivad boonuse kasutamisega seotud panustamistingimused (edaspidi: <b>panustamine</b>), millest teavitatakse mängijat varem veebisaidi ja e-posti teel. Kõik antud spordiennustuse boonused kantakse
            mängukontole automaatselt boonusrahana, mida reguleerivad spordiennustuse boonuse tingimustes määratletud reeglid.<br></br>
            <br></br>
            <h3 id="Tc">2. SPORDIENNUSTUSE BOONUSE ÜLDTINGIMUSED</h3>
            2.1. Siinsed spordiennustuse boonuse üldtingimused kehtivad Nubeti antud mis tahes tüüpi spordiennustuse boonuste ja kampaaniapakkumiste kohta.<br></br>
            <br></br>
            2.2. On ettevõttel õigus oma ainuäranägemise järgi keelata üksikutel mängijatel boonust kasutamast. Ettevõte võib oma
            ainuäranägemisel tühistada mis tahes boonuse ja sellega seotud võidud, kui ettevõte avastab, et mängija ei järgi boonusereegleid. Ettevõte võib igal ajal ilma ette teatamata muuta boonuse tingimusi või
            lõpetada selle pakkumise.<br></br>
            <br></br>
            2.3. Nubeti spordiennustuse boonus kehtib ja seda tuleb kasutada teatava kampaaniaperioodi jooksul. Kui seda ei kasutata, siis boonus aegub ja seda ei saa hiljem rakendada.<br></br>
            <br></br>
            2.4. Nubeti spordiennustuse boonus on saadaval ainult üks kord kliendi, mängijakonto, majapidamise, sissemakse tegemiseks kasutatud ülekandemeetodi ja/või konto omaniku, ühise arvuti (ülikool, töökoht,
            internetikohvik jne) ja/või IP-aadressi kohta.<br></br>
            <br></br>
            2.5. Nubeti spordiennustuse boonust ei tohi kombineerida teiste boonustega, kui sõnaselgelt ei ole teisiti märgitud, ega kasutada koos muude kampaaniatega.<br></br>
            <br></br>
            2.6. Nubeti spordiennustuse boonuse kampaaniapakkumise, mis saadetakse e-kirjaga, võib lunastada ainult boonuse e-kirja algne saaja. Boonuse saanud ja seda kasutav e-posti aadress peab ühtima mängijakonto
            loomisel kasutatud e-posti aadressiga. Nubet jätab endale õiguse uurida, kas boonuskood saadeti isiklikult spordiennustuse boonuse pakkumist kasutanud mängukonto omanikule mängukontol kasutatud e-posti
            aadressile. Kui seda reeglit rikutakse, võidakse boonus ja sellega seotud võidud konfiskeerida.<br></br>
            <br></br>
            2.7. Nubeti spordiennustuse boonus, mis on seotud sissemakse tegemisega, kehtib ainult Nubeti pakutavate maksemeetodite kasutamise korral ega ole spordiennustuse boonuse pakkumisest maksemeetodina välistatud.
            Kontoomanik ja maksemeetodi omanik peavad olema samad. Mängukonto krediidijääki, mis koosneb ainult mängija tehtud sissemaksetest (mitte boonustest) ja mis seega ei ole seotud boonuse tingimustega,
            nimetatakse pärisrahajäägiks (edaspidi: <b>pärisrahajääk</b>).<br></br>
            <br></br>
            2.8. Nubeti spordiennustuse boonus kajastub teie mängukonto jäägis. Kui spordiennustuse boonus on panustatud, ei saa kontojääki välja võtta ega konverteerida seda pärisrahajäägiks enne, kui panustamine on
            lõpetatud, nagu on sätestatud boonuse tingimustes.
            <br></br>
            <br></br>
            2.9. Ainult antud boonuse suhtes kohaldatakse panustamise nõuet.<br></br>
            <br></br>
            2.10. Panustamisnõuet täidavad ainult arveldatud panused. Kui panus makstakse tagasi, sest sündmus peatatakse, tühistatakse, kuulutatakse kehtetuks või ilmneb mõni muu põhjus, miks mängu panust ei saa
            tavapäraselt arveldada, ei täida panusesumma panustamisnõuet. Sama kehtib panuste kohta, millest on raha väljavõetud.<br></br>
            <br></br>
            
            2.11. Kui mängija on panustanud aktiivse boonusega, ei saa lunastatud boonust ilma boonust kaotamata enam tühistada.<br></br>
            <br></br>
            
            2.12. Kui ei ole sätestatud teisiti, tuleb saadud kasiino spordiennustuse boonus enne selle väljamaksmist või väljamaksmise üritamist panustada vähemalt kuus (6) korda. Boonusrahaga teenitud võite ei maksta
            välja enne, kui boonuse panustamise tingimused on täidetud. Kui panustamisnõue on käimas, on tagasivõtmised üldiselt blokeeritud.<br></br>
            <br></br>
            2.13. Boonuse panustamise nõuded tuleb täita kindlaksmääratud aja jooksul pärast boonuse saamist. Kui boonuse tingimusi ei täideta õigel ajal, jätab Nubet endale õiguse tühistada boonuse summa ja kõik sellest
            tulenenud võidud.<br></br>
            <br></br>
            2.14. Nubet jälgib, kas boonuseid kasutatakse ebahariliku mängumustri osana.<br></br>
            <br></br>
            2.15. Spordikihlvedude boonust väärkasutades rikutakse boonuse tingimusi. Nubet eeldab, et boonuse tingimusi on rikutud järgmistel juhtudel:<br></br>
            <br></br>
            <ListItem>sama isik kasutab boonust mitu korda või boonust kasutatakse mitu korda sama IP-aadressi all või seadmes;</ListItem>
            <ListItem>kahtlustatakse, et boonuspakkumist kasutab organisatsioon;</ListItem>
            <ListItem>tehakse panuseid (nn kindlaid panuseid), mis tagavad tulemustest olenemata spordiennustuse boonuse võidu üksikisikule või koostööd tegevale mängijaterühmale.</ListItem>
            <br></br>
            2.16. Kui Nubetil tekib kahtlus, et spordiennustuse boonuse tingimusi on rikutud või klient on teinud panuseid, mis tagavad spordiennustuse sissemakseboonuse, tasuta panuste või mis tahes muu
            reklaamipakkumise tõttu võidu, olenemata panuse tulemusest, jätab ettevõte endale õiguse nõuda spordiennustuse boonus tagasi, parandada või tühistada tehtud panuseid, keelduda taotletud väljamaksetest ja
            sulgeda mängija konto.<br></br>
            <br></br>
            2.17. Boonust ei kanta kontole, kui te ei tohi Nubeti pakkumises osaleda või pakkumine on peatatud.<br></br>
            <br></br>
            2.18. Spordikihlvedude boonus kehtib kuni panustamise lõpuni või boonuse tühistamiseni.<br></br>
            <br></br>
            2.19. Nubet jätab endale õiguse boonust ja kampaania kestust igal ajal muuta, peatada või tühistada.<br></br>
            <br></br>
            2.20. Kohaldatakse Nubeti tingimusi.<br></br>
            <br></br>
            
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTerms));
