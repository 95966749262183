//<---- Casino main page----->
// @react
import { useEffect } from "react";
//@mui comonents
import { Stack, Container, Paper } from "@mui/material";
// views
import CasinoBanner from "./components/CasinoBanner";
import CasinoFilterTabs from "./components/CasinoFilterTabs";
import CasinoGameView from "./views/CasinoGameView";
import CasinoBannerRotation from "../../components/wp-content/CasinoBannerRotation";
// router
import { Outlet, useSearchParams } from "react-router-dom";
// redux tools
import { useDispatch, useSelector } from "react-redux";
// redux actions
import { getUserFavGamesIdsAsync } from "../../redux-store/casino-reducer";

const CasinoMainPage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  // select search params
  const dialogType = searchParams.get("dialog");
  // select from state
  const auth = useSelector((state) => state.auth);
  // get user fav games ids
  const getUserFavIds = () => {
    if (auth.isSignedIn) {
      dispatch(getUserFavGamesIdsAsync());
    }
  };
  //make requsts on mount and auth state change
  useEffect(getUserFavIds, [auth.isSignedIn]);

  return (
    <Container sx={{ padding: 0 }} maxWidth={"xl"}>
      <Stack rowGap={2} pb={3}>
        <CasinoBannerRotation />
        {/* <CasinoBanner /> */}
        <Stack mt={0} position="sticky" top={0} zIndex={10}>
          <Paper>
            <CasinoFilterTabs />
          </Paper>
        </Stack>
        {dialogType === "play-game" && <CasinoGameView />}
        <Outlet />
      </Stack>
    </Container>
  );
};

export default CasinoMainPage;
