import { Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Translate } from "react-redux-i18n";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import RHFormProvider from "../../../hook-form/form-provider";
import { useState } from "react";
import Iconify from "../../../iconify";
import RHFSelectField from "../../../hook-form/rhf-select-field";
import { documentTypes } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import RHFFilePicker from "../../../hook-form/rhf-file-picker";
import { getUserDocumentsAsync, uploadUserDocumentAsync } from "../../../../redux-store/account-reducer";

const UploadDocumentDialog = () => {
  const [dialogStatus, setDialogStatus] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const translate = useSelector((state) => state.i18n.translations[state.i18n.locale]);
  const isUploadLoading = useSelector((state) => state.account.documents.isPostLoading);
  // upload functions
  const documentScheme = Yup.object().shape({
    documentSide: Yup.string().required(),
    documentType: Yup.string().required(),
    file: Yup.mixed().required(),
  });
  const methods = useForm({
    resolver: yupResolver(documentScheme),
    defaultValues: {
      documentSide: undefined,
      documentType: undefined,
      file: undefined,
    },
  });

  const onSubmit = async (formValues) => {
    const formData = new FormData();
    formData.append("name", formValues.documentType);
    formData.append("documentType", formValues.documentType);
    formData.append("documentSide", formValues.documentSide);
    formData.append("file", formValues.file);
    dispatch(
      uploadUserDocumentAsync({
        data: formData,
        callback: () => {
          setDialogStatus(false);
          dispatch(getUserDocumentsAsync());
        },
      })
    );
  };

  return (
    <>
      <Button startIcon={<Iconify icon="line-md:upload-loop" />} onClick={() => setDialogStatus(true)} variant="contained" color="primary">
        <Translate value="labels.upload_document" />
      </Button>
      <Dialog fullWidth open={dialogStatus} onClose={() => setDialogStatus(false)}>
        <Stack position="relative">
          <IconButton size="small" onClick={() => setDialogStatus(false)} sx={{ position: "absolute", right: 10, top: 10 }}>
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </Stack>
        <DialogTitle>
          <Typography variant="h5">{translate.labels.upload_new_documents}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            {translate.labels.upload_document_note}
          </Typography>
        </DialogContent>
        <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack px={2} gap={2} pb={3}>
            <RHFSelectField name={"documentType"} label={translate.labels.select_document_type} size={"small"}>
              {documentTypes.map((dct) => (
                <MenuItem value={dct} key={dct}>
                  <Translate value={`labels.${dct}`} />
                </MenuItem>
              ))}
            </RHFSelectField>
            <RHFSelectField name={"documentSide"} label={translate.labels.select_document_side} size={"small"}>
              {["front", "back", "any"].map((dct) => (
                <MenuItem value={dct} key={dct}>
                  <Translate value={`labels.${dct}_side`} />
                </MenuItem>
              ))}
            </RHFSelectField>
            <RHFFilePicker name="file" />
            <LoadingButton loading={isUploadLoading} variant="contained" color="primary" type="submit">
              {translate.labels.upload_document}
            </LoadingButton>
          </Stack>
        </RHFormProvider>
      </Dialog>
    </>
  );
};

export default UploadDocumentDialog;
