import { useFormContext, Controller } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// @mui
import { Box, IconButton, Stack, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { Image, PictureAsPdf } from "@mui/icons-material";
import Iconify from "../iconify";

const StyledContainer = styled(Box)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  padding: theme.spacing(1),
  borderRadius: 10,
  cursor: "pointer",
}));

export default function RHFFilePicker({ name, helperText, type, textField, ...other }) {
  const { control } = useFormContext();
  const translate = useSelector((state) => state.i18n.translations[state.i18n.locale]);
  const inputRef = useRef();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box>
          <StyledContainer onClick={() => inputRef.current.click()}>
            <input onChange={(e) => field.onChange(e.target.files[0])} style={{ display: "none" }} ref={inputRef} type="file" accept=".png, .jpg, .jpeg, .pdf" />
            <Stack alignItems="center" py={3}>
              <CloudUploadIcon fontSize="large" />
              <Stack direction="row" alignItems="center" gap={1} mt={2}>
                <Typography variant="string">{translate.labels.supported_formats}:</Typography>
                <Typography variant="string" color="text.secondary">
                  png, jpg, jpeg, pdf
                </Typography>
              </Stack>
            </Stack>
          </StyledContainer>
          {field?.value && (
            <Box sx={{ mt: 1, p: 1, borderRadius: 2, display: "flex", alignItems: "center", justifyContent: "space-between", gap: 2, bgcolor: "background.default" }}>
              <Stack direction="row" alignItems="center" gap={1}>
                {field?.value.type.startsWith("image/") ? <Image color="primary" fontSize="large" /> : field?.value.type === "application/pdf" ? <PictureAsPdf color="error" fontSize="large" /> : null}
                <Typography variant="body1">{field?.value.name}</Typography>
              </Stack>
              <IconButton size="small">
                <Iconify icon="material-symbols:close" />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    />
  );
}
