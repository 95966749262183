import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";

// initialize registration deposit
export const initRegisterDeposit = async (amount) => {
  const url = window.location.href;
  let country = url.includes("sweden") ? "SWE" : "EST";
  let currency = url.includes("sweden") ? "SEK" : "EUR";
  const reply = await api.post("/wallet/initDeposit", {
    params: {
      amount: amount,
      country: country,
      currency: currency,
      method: "300002",
    },
  });
  return {
    shouldAlert: reply.data?.type !== "success",
    response: reply.data,
    data: reply.data?.payload,
  };
};

// LOGIN WITH TRUSTLY
export const trustlyLogin = async (locale) => {
  const reply = await api.post("auth/initLogin", {
    params: {
      country: locale === "et" ? "EST" : "FIN",
      method: "300002",
    },
  });
  return {
    shouldAlert: reply.data?.type !== "success",
    response: reply.data,
    data: reply.data?.payload,
  };
};

// CHECK TRUSTLY REGISTER DEPOSIT STATUS
export const checkTrustlyRegDepositStatus = async ({ token, reference }) => {
  const reply = await api.post("/wallet/depositStatus", {
    params: {
      token,
      reference,
    },
  });
  const depsoitStatus = reply.data?.payload?.status;
  const reqStatus = reply.data?.type;
  let response = { ...reply.data };
  if (depsoitStatus === "success" || depsoitStatus === "ok") {
    response = { ...response, payload: "deposit_succes" };
  }
  if (reqStatus === "error" || depsoitStatus === "cancelled" || depsoitStatus === "failed" || depsoitStatus === "voided") {
    response = { ...response, payload: "deposit_fail", type: "error" };
  }
  return {
    shouldAlert: false,
    response: response,
    data: reply.data?.payload,
  };
};

// check trustly login status

export const checkTrustlyLoginStatus = async (token) => {
  const reply = await api.post("auth/loginStatus", {
    params: { token },
  });
  return reply.data;
};

// GET USER SUMMARY

export const getUserSummary = async () => {
  const reply = await api.get("/account/player-summary ", {
    headers: authHeader(),
  });
  return reply.data;
};

// send forgot password email

export const sendPasswordResetEmail = async ({ params, callback }) => {
  const reply = await api.get("auth/password-retrieve", {
    params,
  });
  if (callback) {
    callback();
  }
  let response = reply?.data?.type ? reply.data : {};
  if (response.type === "error" || response.type === "warning") {
    let resPay = typeof response.payload === "object" ? response?.payload?.error : response?.payload;
    response = { ...response, payload: resPay };
  }
  return {
    shouldAlert: true,
    response: response,
    data: reply?.data?.payload,
  };
};

// reset user password

export const resetUserPassword = async (params) => {
  const reply = await api.put(
    "auth/set-password",
    {
      params,
    },
    { headers: authHeader() }
  );
  let response = reply?.data?.type ? reply.data : {};
  if (response.type === "error" || response.type === "warning") {
    let resPay = typeof response.payload === "object" ? response?.payload?.error : response?.payload;
    response = { ...response, payload: resPay };
  }
  return {
    shouldAlert: response?.type !== "success",
    response: response,
    data: reply?.data?.payload,
  };
};

// MAKE DEPOSIT

export const makeDeposit = async ({ amount, bonus, storedDetailReference }) => {
  const reply = await api.post(
    "wallet/confirmDeposit",
    {
      params: {
        amount: amount,
        merchant: "trustly",
        bonus: bonus ? bonus : 0,
        method: "300002",
        storedDetailReference,
      },
    },
    { headers: authHeader() }
  );
  let response = typeof reply?.data === "object" ? { ...reply.data } : {};

  if (response?.type === "error" && typeof response?.payload === "object") {
    response = { ...response, payload: { error: response?.payload?.error } };
  }
  return {
    shouldAlert: reply.data.type !== "success",
    response: response,
    data: reply.data?.payload,
  };
};

// check user deposit status

export const checkDepositStatus = async ({ reference }) => {
  let shouldAlertBonus = false;
  const reply = await api.post(
    "/wallet/depositStatus",
    {
      params: {
        reference,
      },
    },
    { headers: authHeader() }
  );
  if (reply.data?.payload?.status === "ok") {
    const res = await api.get("/account/depositCampaigns", { headers: authHeader() });
    if (res.data && res.data?.payload && Array.isArray(res.data?.payload) && res.data?.payload?.length > 0) {
      shouldAlertBonus = true;
    }
  }
  const depositBonusesCount = localStorage.getItem("deposit-bonuses-count");
  if (Number(depositBonusesCount) > 0) {
    shouldAlertBonus = true;
  }
  localStorage.removeItem("deposit-bonuses-count");
  return {
    deposit: reply.data,
    shouldAlertBonus,
  };
};

export const cancelBonusOfferAsync = async (callback) => {
  const res = await api.post("account/bonuses/revoke", {}, { headers: authHeader() });
  if (callback) {
    callback();
  }
  return res.data;
};
