import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Help_DE from "../components/info/de/Help";
import AboutUs_EN from "../components/info/en/AboutUs";
import BettingRules_EN from "../components/info/en/BettingRules";
import BonusTerms_EN from "../components/info/en/BonusTerms";
import BonusTermsSlots_EN from "../components/info/en/BonusTermsSlots";
import Help_EN from "../components/info/en/Help";
import OutdatedBrowser_EN from "../components/info/en/OutdatedBrowser";
import PrivacyPolicy_EN from "../components/info/en/PrivacyPolicy";
import ResponsibleGame_EN from "../components/info/en/ResponsibleGame";
import SlotsRules_EN from "../components/info/en/SlotsRules";
import TermsConditions_EN from "../components/info/en/TermsConditions";
import Faq_EN from "../components/info/en/Faq";
import AboutUs_ET from "../components/info/et/AboutUs";
import BettingRules_ET from "../components/info/et/BettingRules";
import BonusTerms_ET from "../components/info/et/BonusTerms";
import BonusTermsSlots_ET from "../components/info/et/BonusTermsSlots";
import Help_ET from "../components/info/et/Help";
import PrivacyPolicy_ET from "../components/info/et/PrivacyPolicy";
import ResponsibleGame_ET from "../components/info/et/ResponsibleGame";
import SlotsRules_ET from "../components/info/et/SlotsRules";
import TermsConditions_ET from "../components/info/et/TermsConditions";
import OutdatedBrowser_ET from "../components/info/et/OutdatedBrowser";
import Faq_ET from "../components/info/et/Faq";
import AboutUs_FI from "../components/info/fi/AboutUs";
import BettingRules_FI from "../components/info/fi/BettingRules";
import BonusTerms_FI from "../components/info/fi/BonusTerms";
import BonusTermsSlots_FI from "../components/info/fi/BonusTermsSlots";
import Help_FI from "../components/info/fi/Help";
import PrivacyPolicy_FI from "../components/info/fi/PrivacyPolicy";
import ResponsibleGame_FI from "../components/info/fi/ResponsibleGame";
import SlotsRules_FI from "../components/info/fi/SlotsRules";
import TermsConditions_FI from "../components/info/fi/TermsConditions";
import OutdatedBrowser_FI from "../components/info/fi/OutdatedBrowser";
import Faq_FI from "../components/info/fi/Faq";
import CustomNotFoundComponent from "../components/404";
import WPCampaigns from "../components/wp-content/Campaigns";
import WPNews from "../components/wp-content/News";
import WPPromotions from "../components/wp-content/Promotions";
import LeaderboardShanhai from "../components/wp-content/LeaderboardShanhai";
import LeaderboardHockey from "../components/wp-content/LeaderboardHockey";
// Main promotions
import Promotions_FI from "../components/info/fi/Promotions";
import Promotions_ET from "../components/info/et/Promotions";
import Promotions_EN from "../components/info/en/Promotions";
// free speens promottions
import PromotionsFreespins_FI from "../components/info/fi/PromotionsFreespins";
import PromotionsFreespins_ET from "../components/info/et/PromotionsFreespins";
import PromotionsFreespins_EN from "../components/info/en/PromotionsFreespins";
// betbuilder promotions
import PromotionsBetbuilder_FI from "../components/info/fi/PromotionsBetbuilder";
import PromotionsBetbuilder_ET from "../components/info/et/PromotionsBetbuilder";
import PromotionsBetbuilder_EN from "../components/info/en/PromotionsBetbuilder";
// lazy loaded screens
import Slip from "../components/sports/slip";
import BetbuilderPage from "../components/sports/betbuilder";
import CasinoMainPage from "../components/casino";
import CasinoMainView from "../components/casino/views/CasinoMainView";
import CasinoFilterView from "../components/casino/views/TabFilterView";
import CasinoProviderFilterView from "../components/casino/views/ProviderFilterView";
import FreeSpinsView from "../components/casino/views/FreeSpinsView";
import PlayerPropsCatalog from "../components/sports/player-props";
import RacingPage from "../components/sports/racing";
import BetBuilderLoaderPage from "../components/sports/betbuilder/BetbuilderView";
import BetbuilderLeagueGamesPage from "../components/sports/betbuilder/games-list";

import FavoriteGamesView from "../components/casino/views/FavoriteGamesView";
import PageLoader from "../components/PageLoader";
import UserRegister from "../components/user/auth/register";
import UserLogin from "../components/user/auth/login";
import AuthenticatedRedirect from "./AuthenticatedRedirect";
import AutoLoginPage from "../components/user/auth/AutoLogin";
import TrustlyLoginStatusPage from "../components/user/auth/TrustlyLoginStatus";
import UserPaymentsPage from "../components/user/payments";
import UnAuthorizedRedirect from "./UnauthorizedRedirect";
import TrustlyRegStatusPage from "../components/user/auth/TrustlyRegStatus";
import LimitsRequestPage from "../components/user/user-details/SetLimitsRequest";
import DepositStatusPage from "../components/user/payments/deposit-status";
import TrustlyActionsPage from "../components/user/actions/TrustlyActions";
import StatementsPage from "../components/user/statements";
import SettingsPage from "../components/user/settings";
import TicketsList from "../components/user/tickets/TicketsList";
import ForgotPasswordPage from "../components/user/auth/forgot-password";
import ResetPasswordPage from "../components/user/auth/reset-password";
import PromotionsHockeyWeekendBonus from "../components/info/en/PromotionsHockeyWeekendBonus";
import PromotionsHockeyJerseyGiveaway from "../components/info/en/PromotionsHockeyJerseyGiveaway";
import PlayerPropsGamesPage from "../components/sports/player-props/games-list";
import PlayerPropsGamePage from "../components/sports/player-props/game-odds";
import RacingGamesPage from "../components/sports/racing/racing-games";
import CasinoSearchView from "../components/casino/views/CasinoSearchView";
import UserDocuments from "../components/user/documents";

const DataBetPage = lazy(() => import("../components/databet"));

const AppRoutes = () => {
  const allLocales = ["fi", "en", "et"];
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const lang = useSelector((state) => state.i18n.locale);

  const routeListener = () => {
    const pathname = location.pathname;
    const routeLocale = pathname.split("/")[1];
    if (!allLocales.includes(routeLocale)) {
      navigate(`/${lang}${pathname}?${searchParams.toString()}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    routeListener();
  }, [location.pathname]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {/* EMPTY PAGE AND REDIRECT ROUTES START*/}
        <Route path="*" exact element={<CustomNotFoundComponent />} />
        <Route path="/" element={<Navigate to={`/${lang}/sports?sportEventStatusSlug=all`} />} />
        <Route path={`/${lang}`} element={<Navigate to={`/${lang}/sports?sportEventStatusSlug=all`} />} />
        {/* EMPTY PAGE AND REDIRECT ROUTES END */}

        {/* AUTH FLOW ROUTES  START */}
        <Route
          path="/:lang/auth/registration"
          exact
          element={
            <AuthenticatedRedirect>
              <UserRegister />
            </AuthenticatedRedirect>
          }
        />
        <Route
          path="/:lang/auth/login"
          exact
          element={
            <AuthenticatedRedirect>
              <UserLogin />
            </AuthenticatedRedirect>
          }
        />
        <Route
          path="/:lang/auth/login/status"
          exact
          element={
            <AuthenticatedRedirect>
              <TrustlyLoginStatusPage />
            </AuthenticatedRedirect>
          }
        />
        <Route path="/:lang/auth/registration/status" exact element={<TrustlyRegStatusPage />} />
        <Route
          path="/:lang/auth/autoLogin"
          exact
          element={
            <AuthenticatedRedirect>
              <AutoLoginPage />
            </AuthenticatedRedirect>
          }
        />
        <Route path="/:lang/auth/request-limits" exact element={<LimitsRequestPage />} />
        <Route path="/:lang/auth/forgot-password" exact element={<ForgotPasswordPage />} />
        <Route path="/:lang/login/reset" exact element={<ResetPasswordPage />} />
        {/* AUTH FLOW ROUTES  END */}

        {/* USER ACCOUNT ROUTES  START */}
        <Route
          path="/:lang/account/payments"
          exact
          element={
            <UnAuthorizedRedirect>
              <UserPaymentsPage />
            </UnAuthorizedRedirect>
          }
        />
        <Route path="/:lang/account/payments/deposit/status" exact element={<DepositStatusPage />} />
        <Route path="/:lang/account/tickets" exact element={<TicketsList />} />
        <Route
          path="/:lang/account/statements"
          exact
          element={
            <UnAuthorizedRedirect>
              <StatementsPage />
            </UnAuthorizedRedirect>
          }
        />
        <Route
          path="/:lang/account/settings"
          exact
          element={
            <UnAuthorizedRedirect>
              <SettingsPage />
            </UnAuthorizedRedirect>
          }
        />
        <Route
          path="/:lang/account/documents"
          exact
          element={
            <UnAuthorizedRedirect>
              <UserDocuments />
            </UnAuthorizedRedirect>
          }
        />
        <Route path="/:lang/actions/trustly" exact element={<TrustlyActionsPage />} />
        {/* USER ACCOUNT ROUTES  END */}

        {/* PRIVACY ROUTES START */}
        <Route path="/:lang/info/betting-rules" exact element={lang === "en" ? <BettingRules_EN /> : lang === "et" ? <BettingRules_ET /> : lang === "fi" ? <BettingRules_FI /> : <BettingRules_EN />} />
        <Route path="/:lang/info/bonus-terms" exact element={lang === "en" ? <BonusTerms_EN /> : lang === "et" ? <BonusTerms_ET /> : lang === "fi" ? <BonusTerms_FI /> : <BonusTerms_EN />} />
        <Route path="/:lang/info/privacy-policy" exact element={lang === "en" ? <PrivacyPolicy_EN /> : lang === "et" ? <PrivacyPolicy_ET /> : lang === "fi" ? <PrivacyPolicy_FI /> : <PrivacyPolicy_EN />} />
        <Route path="/:lang/info/help" exact element={lang === "en" ? <Help_EN /> : lang === "et" ? <Help_ET /> : lang === "fi" ? <Help_FI /> : <Help_DE />} />
        <Route path="/:lang/info/responsible-game" exact element={lang === "en" ? <ResponsibleGame_EN /> : lang === "et" ? <ResponsibleGame_ET /> : lang === "fi" ? <ResponsibleGame_FI /> : <ResponsibleGame_EN />} />
        <Route path="/:lang/info/about-us" exact element={lang === "en" ? <AboutUs_EN /> : lang === "et" ? <AboutUs_ET /> : lang === "fi" ? <AboutUs_FI /> : <AboutUs_EN />} />
        <Route path="/:lang/info/faq" exact element={lang === "en" ? <Faq_EN /> : lang === "et" ? <Faq_ET /> : lang === "fi" ? <Faq_FI /> : <Faq_EN />} />
        <Route path="/:lang/info/terms-conditions" exact element={lang === "en" ? <TermsConditions_EN /> : lang === "et" ? <TermsConditions_ET /> : lang === "fi" ? <TermsConditions_FI /> : <TermsConditions_EN />} />
        <Route path="/:lang/info/outdated_browser" exact element={lang === "en" ? <OutdatedBrowser_EN /> : lang === "et" ? <OutdatedBrowser_ET /> : lang === "fi" ? <OutdatedBrowser_FI /> : <OutdatedBrowser_EN />} />
        <Route path="/:lang/info/bonus-terms-slots" exact element={lang === "en" ? <BonusTermsSlots_EN /> : lang === "et" ? <BonusTermsSlots_ET /> : lang === "fi" ? <BonusTermsSlots_FI /> : <BonusTermsSlots_EN />} />
        <Route path="/:lang/info/slots-rules" exact element={lang === "en" ? <SlotsRules_EN /> : lang === "et" ? <SlotsRules_ET /> : lang === "fi" ? <SlotsRules_FI /> : <SlotsRules_EN />} />
        {/* PRIVACY ROUTES END */}

        {/* SPORTS BETTING ROUTES START */}
        <Route path="/:lang/betbuilder" exact element={<BetbuilderPage />} />
        <Route path="/:lang/betbuilder/league/:leagueId" exact element={<BetbuilderLeagueGamesPage />} />
        <Route path="/:lang/betbuilder/game/:gameId" exact element={<BetBuilderLoaderPage />} />

        <Route path="/:lang/player-props" exact element={<PlayerPropsCatalog />} />
        <Route path="/:lang/player-props/league/:leagueId" exact element={<PlayerPropsGamesPage />} />
        <Route path="/:lang/player-props/game/:gameId" exact element={<PlayerPropsGamePage />} />

        <Route path="/:lang/horseracing" exact element={<RacingPage />} />
        <Route path="/:lang/horseracing/league/:leagueId" exact element={<RacingGamesPage />} />

        <Route path="/:lang" exact element={<DataBetPage />} />
        <Route path="/:lang/sports" exact element={<DataBetPage />} />
        <Route path="/:lang/freebets" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug/match/:matchSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/sports/:sportsSlug/tournament/:tournamentSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/favorites" exact element={<DataBetPage />} />
        <Route path="/:lang/esports" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug/match/:matchSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/esports/:sportsSlug/tournament/:tournamentSlug" exact element={<DataBetPage />} />
        <Route path="/:lang/freebets" exact element={<DataBetPage />} />
        <Route path="/:lang/freebets/freebets" exact element={<DataBetPage />} />
        <Route path="/:lang/slip" exact element={<Slip />} />
        {/* SPORTS BETTING ROUTES END */}

        {/* CASINO ROUTES START */}
        <Route path="/:lang/casino" exact element={<CasinoMainPage />}>
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route path="dashboard" element={<CasinoMainView />} />
          <Route path="featured-games" element={<CasinoFilterView />} />
          <Route path="provider-games" element={<CasinoProviderFilterView />} />
          <Route path="search" element={<CasinoSearchView />} />
          <Route
            path="free-spins"
            element={
              <UnAuthorizedRedirect>
                <FreeSpinsView />
              </UnAuthorizedRedirect>
            }
          />
          <Route
            path="my-games"
            element={
              <UnAuthorizedRedirect>
                <FavoriteGamesView />
              </UnAuthorizedRedirect>
            }
          />
        </Route>
        {/* CASINO ROUTES END */}

        {/* PROMOTIONS ROUTES START */}
        <Route path="/:lang/leaderboard/shanghai2024" exact element={<LeaderboardShanhai />} />
        <Route path="/:lang/leaderboard/hockey" exact element={<LeaderboardHockey />} />
        <Route path="/:lang/platform/campaigns" exact element={<WPCampaigns />} />
        <Route path="/:lang/promotions" exact element={<WPPromotions />} />
        <Route path="/:lang/promotions-freespins" exact element={lang === "en" ? <PromotionsFreespins_EN /> : lang === "et" ? <PromotionsFreespins_ET /> : <PromotionsFreespins_FI />} />
        <Route path="/:lang/promotions-hockey-weekend-bonus" exact element={<PromotionsHockeyWeekendBonus />} />
        <Route path="/:lang/promotions-hockey-jersey-giveaway" exact element={<PromotionsHockeyJerseyGiveaway />} />
        <Route path="/:lang/promotions-riskfree" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />
        <Route path="/:lang/promotions-betbuilder" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} />
        {/* <Route path="/:lang/promotions" exact element={lang === "en" ? <Promotions_EN /> : lang === "et" ? <Promotions_ET /> : <Promotions_FI />} /> */}
        {/* PROMOTIONS ROUTES END */}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
