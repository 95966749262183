/**
 * BonusTermsSlots - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import { ListItem } from "@mui/material";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class BonusTermsSlots extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component={"span"}>
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM KASINO BONUS KÄYTTÖEHDOT</h1>
            Suomenkielinen versio 1.3 Viimeisin päivitys 13.2.2025.<br></br>
            <br></br>
            <h3 id="General">1. YLEISTÄ</h3>
            1.1. Nämä kasinobonusehdot koskevat Nubet-kasinoa.<br></br>
            <br></br>
            1.2. Näissä kasinobonusehdoissa kuvatun kasinobonuksen myöntäjä on Nubet verkkosivustolla Nubet.com, jota ylläpitää Vana Lauri OÜ, Virossa rekisteröidyn yrityksen rekisteröintinumerolla 16427120
            rekisteröity yritys, jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna, Viro (jäljempänä "Nubet" tai "Yhtiö"). Vana Lauri OÜ:llä on Viron vero- ja tullihallituksen myöntämä toimilupa ja sen valvonta,
            ja sillä on seuraavat toimiluvat: HKT000066 (myöntämispäivä: 10.07.2022) ja HKT000067 (myöntämispäivä: 10.07.2022).<br></br>
            <br></br>
            1.3. Nubet voi myöntää sinulle minkä tahansa tyyppistä kasinobonusluottoa tai muunlaisia bonuksia, kuten ilmaiskierroksia (jäljempänä myös "bonusraha", "bonus" tai "kasinobonus"), jos tietyt ehdot täyttyvät.
            Bonuksen käyttöön liittyviä vedonlyöntiehtoja (jäljempänä myös
            "vedonlyönti") sovelletaan kaikkiin myönnettyihin kasinobonuksiin, ja vedonlyöntisäännöistä ilmoitetaan pelaajalle etukäteen verkkosivustolla ja sähköpostitse. Myönnetty kasinobonus hyvitetään automaattisesti
            pelitilille bonusrahana, jota sitovat näissä kasinobonusehdoissa määritellyt säännöt.<br></br>
            <br></br>
            <h3 id="Generalbonus">2. YLEISET KASINO BONUS KÄYTTÖEHDOT</h3>
            2.1. Näitä yleisiä kasinobonusehtoja sovelletaan kaikkiin Nubetin myöntämiin kasinobonuksiin.<br></br>
            <br></br>
            2.2. Yhtiö voi harkintansa mukaan sulkea yksittäisiä pelaajia tarjouksen ulkopuolelle. Yhtiöllä on yksinomainen harkintavalta mitätöidä
            bonukset ja niihin liittyvät voitot, jos yhtiö näkee, että pelaaja ei noudata bonussääntöjä. Yhtiö voi myös muuttaa Bonuksen ehtoja tai lopettaa sen tarjoamisen milloin tahansa ilman ennakkoilmoitusta.
            <br></br>
            <br></br>
            2.3. Nubet Kasino Bonus on voimassa ja se on käytettävä tietyn kampanjajakson aikana. Jos sitä ei käytetä, se vanhenee, eikä sitä voi käyttää sen jälkeen.<br></br>
            <br></br>
            2.4. Nubet Kasino Bonus on saatavilla vain kerran asiakasta, pelitiliä, kotitaloutta, talletuksen tekemiseen käytetyn rahansiirtomenetelmän ja/tai tilin omistajaa, jaettua tietokonetta (yliopisto, työpaikka,
            internetkahvila jne.) ja/tai IP-osoitetta kohden.<br></br>
            <br></br>
            2.5. Nubet Kasino Bonusta ei voi yhdistää muihin bonuksiin, ellei nimenomaisesti toisin mainita, eikä sitä voi käyttää yhdessä muiden kampanjoiden tai kampanjoiden kanssa.<br></br>
            <br></br>
            2.6. Sähköpostitse lähetetyn Nubet kasinon bonuskampanjatarjouksen voi lunastaa vain bonussähköpostin alkuperäinen vastaanottaja. Bonuksen vastaanottavan ja käyttävän sähköpostiosoitteen on oltava sama kuin
            pelitiliä luotaessa. Nubet pidättää oikeuden tutkia, onko kasinobonustarjouksen käyttänyt pelitilin haltija saanut bonuskoodin henkilökohtaisesti pelitilillä käytettyyn sähköpostiosoitteeseen. Tämän säännön
            rikkominen voi johtaa bonuksen ja siihen liittyvien voittojen takavarikointiin.<br></br>
            <br></br>
            2.7. Nubet-kasinobonus, joka on sidottu talletuksen tekemiseen, on voimassa vain silloin, kun käytetään Nubetin tarjoamia maksutapoja, joita ei ole nimenomaisesti suljettu pois kasinobonustarjouksen
            maksutapana. Tilinhaltijan ja maksutavan haltijan on oltava sama. Pelitilin luottosaldoa, joka on peräisin pelaajan tekemästä talletuksesta ilman bonuksen käyttöä, eikä sitä siten sido bonusehdot, kutsutaan
            oikean rahan saldoksi (jäljempänä <b>"Oikean rahan saldo"</b>).<br></br>
            <br></br>
            2.8. Nubet Kasino Bonus hyvitetään pelitilisi saldoon. Jos kasinobonukseen liittyy kierrätys, pelitilin saldoa ei voi nostaa tai muuntaa oikean rahan saldoksi ennen kuin kierrätys on suoritettu loppuun
            bonusehdoissa määritellyllä tavalla.<br></br>
            <br></br>
            2.9. Vain annettu bonus on kierrätyksen alainen.<br></br>
            <br></br>
            2.10. Jos toisin ei ole mainittu, kaikki saadut kasinobonukset on kierrätettävä vähintään 30 kertaa (30x) ennen kuin ne voidaan maksaa tai niitä voidaan yrittää nostaa. bonusrahalla tehtyjä voittoja ei
            makseta ennen kuin bonuksen kierrätysehdot täyttyvät. Bonuksen kierrätysehtojen ollessa käynnissä, kotiutuksia ei hyväksytä missään muodossa.<br></br>
            <br></br>
            2.11. Ellei toisin mainita, bonuksen kierrätysvaatimukset on täytettävä 7 päivän kuluessa bonuksen saamisesta. Jos bonusehtoja ei täytetä ajoissa, Nubet pidättää oikeuden peruuttaa bonuksen määrän ja kaikki
            siitä saadut voitot.<br></br>
            <br></br>
            2.12. Jos ilmaiskierroksia myönnetään, niiden vähimmäisarvo on 0,10 euroa per kierros, ellei toisin mainita. Kierros, jota ei käytetä määritellyn voimassaoloajan aikana, menetetään. Kaikki ilmaiskierroksista
            saadut voitot katsotaan kasinobonushyvityksiksi, ja niiden on täytettävä kierrätysvaatimukset.<br></br>
            <br></br>
            2.13. Nubet valvoo kaikkien bonusten käyttöä epäsäännöllisten pelitapojen varalta.<br></br>
            <br></br>
            2.14. Kasinobonuksen väärinkäyttö on bonusehtojen rikkominen. Nubet olettaa bonusehtojen rikkomisen seuraavissa tapauksissa:<br></br>
            <ListItem>bonuksen moninkertainen käyttö saman henkilön toimesta tai samaa IP:tä tai päätelaitetta käyttäen;</ListItem>
            <br></br>
            <ListItem>bonustarjouksen epäilty järjestäytynyt käyttö.</ListItem>
            <br></br>
            <br></br>
            2.15. Nubet pidättää oikeuden takavarikoida kaikki käyttämättömät bonushyvitysvoitot, lukuun ottamatta talletettua oikean rahan saldoa, jos epäillään epäsäännöllistä pelaamista tai riippuvuutta aiheuttavaa
            käytöstä. Tällaisessa tilanteessa oikean rahan luotto palautetaan.<br></br>
            <br></br>
            2.16. Bonusta ei hyvitetä, jos sinut on suljettu Nubet-tarjouksen ulkopuolelle tai poistettu tarjouksen piiristä.<br></br>
            <br></br>
            2.17. Kasinobonus on voimassa, kunnes kierrätys on suoritettu tai kasinobonus peruutetaan.<br></br>
            <br></br>
            2.18. Nubet pidättää oikeuden muuttaa, keskeyttää tai peruuttaa bonuksen ja kampanjan keston milloin tahansa.<br></br>
            <br></br>
            2.19. Seuraavien pelien panostusta ei lasketa bonuksiin sisältyvään panostusvaatimukseen: Absolootly Mad Mega Moolah, Agent Royale, Age of Conquest, Astro Legends Lyra & Erion, Blazing Bull 2, Bakers Treat,
            Book Of 99, Castle Builder II, Cauldron, Double Dragons,Cazino Cosmos, Dwarf Mine, EggOmatic, Eye Of The Kraken, Gemix, Goblins and Gemstones, Magic of Sahara, Mad Monsters, Reel Rush II, Dynamite Riches, 11
            Champions, Victoria Wild, Ducks Till Dawn, The Wish Master, Viking Runecraft, White Rabbit, Sea Hunter, Mystery Joker 6000, Extra Chilli, Wild Swarm, Dark Vortex, Jingle Spin, Zombie Hoard, Mahjong 88, Le
            Kaffee Bar, Narcos, Village People Macho Moves, Solar Queen, Razor Shark, Tiki Mania, Jewel Scarabs, Crystal Cavern, Blazing Bull, Solar Temple, Wilderland, Bronco Spirit, Hellcatraz, Football glory, All Star
            Knockout Ultra Gamble, Marching Legions, Banana Drop, Maui Millions, Lil Devil, Bombuster, Jungle Gorilla, 5 Families, Rage of the Seas, Lucky Fridays, Solar King, Emerald King, Hoard of Poseidon, Golden
            Tsar, Leprechauns Magic Megaways, Augustus, Jingle Bells Power Reels, Sylvan Spirits, Golden Stallion, Frost Queen Jackpots, Rome The Golden Age, Rome The Golden Age, Pirates Plenty MegaWays, Pearls Of India,
            The Hot Offer, Tower Quest, Rage To Riches, Robin Hood Shifting Riches, Scudamores Super Stakes, Scrooge, Serengeti Kings, Spina Colada, Sweet Alchemy, Tuts Twister, Vikings Go Berzerk, Wolf Hunters. 2.20.
            Sovelletaan Nubetin ehtoja.<br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(BonusTermsSlots));
