/**
 * MenuTop component - for desktop view mode
 */

import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import { alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import AccountPanel from "./user/AccountPanel";

import SignInIcon from "@mui/icons-material/LockOpen";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";

import IconFREESPIN from "./sports/@components/@icons/IconFREESPIN";
import withRouter from "../helpers/withRouter";
import PromotionsMenu from "./user/promo-menu";

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
    "& .MuiAppBar-root": {
      "@media (hover:hover)": {
        width: "auto",
      },
    },
    "& .MuiToolbar-gutters": {
      paddingLeft: "0.5em",
      paddingRight: "1em",
    },
    "& .MuiBadge-badge": {
      padding: "0.2em",
      color: "var(--color-promo-badge)",
      backgroundColor: "var(--yellow-1)",
      fontSize: "0.7em",
      fontWeight: "800",
      height: "1em",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "0.5em",
      right: "0.1em",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoIcon: {
    display: "flex",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  topNavi: {
    background: "var(--bg-menu-top) !important",
    "@media (hover:none) and (orientation:landscape)": {
      fontSize: "1.8em !important",
      height: "4em !important",
    },
    "& .MuiIconButton-edgeEnd": {
      marginRight: "0 !important",
    },
    "& .MuiIconButton-root": {
      padding: "0 0 0 0.5em !important",
    },
    "& .MuiButton-containedSizeSmall": {
      // padding: "0.5em 0.7em !important",
      fontSize: "1em !important",
    },
    "& .MuiTypography-body1": {
      fontSize: "1.3em !important",
    },
    "& .MuiButton-iconSizeSmall > *:first-child": {
      fontSize: "1.5em !important",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#00000000 !important",
    },
  },
  shadows: ["none"],
  error: {
    fontSize: "12px",
    marginRight: "10px",
    color: "var(--red)!important",
  },
  customTooltip: {
    fontSize: 14,
  },
});

class MenuTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 3,
      isButtonPressing: false,
    };

    this.timer = null;
  }

  handleNavigate = (url) => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/${url}`);
    window.location.reload();
  };

  handleLogin = () => {
    const { locale, navigate, location } = this.props;
    localStorage.setItem("redirect_url", location.pathname);
    navigate(`/${locale}/auth/login`);
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, layout, url, host, selectedTheme, locale, auth, modalType, offersCount } = this.props;

    if (!host) return null;

    // let logo = selectedTheme === 'chris'  ? 'dark' : selectedTheme;

    let logo = "";
    switch (selectedTheme) {
      default:
      case "dark":
      case "light":
      case "classic":
        logo = selectedTheme;
        break;

      case "chris":
        logo = "dark";
        break;

      case "tv":
        logo = "tv";
        break;
    }

    return (
      <div className={classes.grow} id="nubet_app_header">
        <AppBar elevation={0} classes={{ root: classes.topNavi }} position="sticky">
          <Toolbar>
            {
              <Link to={`/${locale}`} key={1} style={{ cursor: "pointer" }}>
                <img
                  style={{
                    height: layout === "mouse" ? "4em" : "3.5em",
                    margin: layout === "mouse" ? "0.2em 1.5em 0 1em" : "0.2em 0em 0 1em",
                    // margin: "0.2em 1.5em 0 1em",
                    cursor: "pointer",
                  }}
                  src={`https://api.nubet.com/media/logos/${host.logo}_${selectedTheme}.svg`}
                  alt="logo"
                />
              </Link>
            }
            {layout === "mouse" && host.bet ? (
              <Link onClick={() => this.handleNavigate("sports?sportEventStatusSlug=all")} key="link-betting" to={`/${locale}/sports?sportEventStatusSlug=all`}>
                <Typography
                  sx={{
                    marginRight: "1em",
                    textTransform: "uppercase",
                    color:
                      !url?.includes(`/${locale}/casino`) &&
                      !url?.includes(`/${locale}/horseracing`) &&
                      !url?.includes(`/${locale}/outrights`) &&
                      !url?.includes(`/${locale}/platform/news`) &&
                      !url?.includes(`/${locale}/promotions`) &&
                      !url?.includes(`/${locale}/leaderboard/shanghai2024`) &&
                      !url?.includes(`/${locale}/platform/campaigns`) &&
                      !url?.includes(`/${locale}/betbuilder`) &&
                      !url?.includes(`/${locale}/player-props`) &&
                      !url?.includes(`/${locale}/esports`) &&
                      !url?.includes(`/${locale}/esports`) &&
                      !modalType
                        ? "primary.menuTopActive"
                        : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.sportsBetting`} />
                </Typography>
              </Link>
            ) : null}
            {layout === "mouse" && host.bet ? (
              <Link onClick={() => this.handleNavigate("esports?sportEventStatusSlug=all")} key="link-esports" to={`/${locale}/esports?sportEventStatusSlug=all`}>
                <Typography
                  onClick={() => this.handleNavigate("esports?sportEventStatusSlug=all")}
                  sx={{
                    marginRight: "1em",
                    textTransform: "uppercase",
                    color: url?.includes(`/${locale}/esports`) ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.esports`} />
                </Typography>
              </Link>
            ) : null}
            {layout === "mouse" && host.bet ? (
              <Link key="link-horseracing" to={`/${locale}/horseracing`}>
                <Typography
                  sx={{
                    marginRight: "1em",
                    textTransform: "uppercase",
                    color: url?.includes(`/${locale}/horseracing`) ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.trotting`} />
                </Typography>
              </Link>
            ) : null}

            {layout === "mouse" ? (
              <Link key="link-betbuilder" to={`/${locale}/betbuilder`}>
                <Typography
                  sx={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes(`/${locale}/betbuilder`) ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`marketSets.betbuilder`} />
                </Typography>
              </Link>
            ) : null}
            {layout === "mouse" ? (
              <Link key="link-playerprops-catalog" to={`/${locale}/player-props`}>
                <Typography
                  sx={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes(`/${locale}/player-props`) ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`marketSets.player`} />
                </Typography>
              </Link>
            ) : null}
            {layout === "mouse" && host.gaming ? (
              <Link key="link-casino" to={`/${locale}/casino/dashboard`}>
                <Typography
                  sx={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes("/casino") ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.casino`} />
                </Typography>
              </Link>
            ) : null}
            {layout === "mouse" && host.promoPage ? (
              <Link key="link-promotions" to={`/${locale}/promotions`}>
                <Typography
                  style={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes("/promotions") ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.promotions`} />
                </Typography>
              </Link>
            ) : null}
            {/* {layout === "mouse" && host.promoPage ? (
              <Link key="link-leaderboard" to={`/${locale}/leaderboard/shanghai2024`}>
                <Typography
                  style={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes("/leaderboard/shanghai2024") ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.lb_shanghai`} />
                </Typography>
              </Link>
            ) : null} */}
            {/* {layout === "mouse" && host.promoPage ? (
              <Link key="link-leaderboard" to={`/${locale}/leaderboard/hockey`}>
                <Typography
                  style={{
                    marginRight: "0.8em",
                    textTransform: "uppercase",
                    color: url?.includes("/leaderboard/hockey") ? "primary.menuTopActive" : "var(--grey-15)",
                  }}
                  variant="body1"
                >
                  <Translate value={`menu.lb_hockey`} />
                </Typography>
              </Link>
            ) : null} */}

            <div className={classes.grow} />

            {layout === "mouse" && !auth.isSignedIn ? (
              <Link to={`/${locale}/auth/registration`}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                    marginRight: "0.5em",
                    color: "var(--btn-text)",
                    // background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                  }}
                  startIcon={<PersonAddIcon />}
                >
                  <Translate value="labels.register" />
                </Button>
              </Link>
            ) : null}
            {layout === "mouse" && !auth.isSignedIn ? (
              <Button onClick={this.handleLogin} variant="contained" color="warning" size="small" startIcon={<SignInIcon />}>
                <Translate value="labels.account_login" />
              </Button>
            ) : null}

            {layout === "mouse" && auth.isSignedIn ? (
              <Link to={`/${locale}/account/payments`}>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  style={{
                    color: "var(--btn-text)",
                    // background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  <Translate value="labels.deposit_title" />
                </Button>
              </Link>
            ) : null}
            {auth.isSignedIn && offersCount > 0 ? <PromotionsMenu /> : null}
            <AccountPanel />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const transactions = state.casino.bonuses?.transactions ? state.casino.bonuses?.transactions : [];
  const freeBets = state.account.campaigns.freeBets.list;

  const depositCampaigns = state.account.campaigns.deposit.list;
  let freeSpinGamesCount = 0;

  transactions?.forEach((transaction) => {
    if (transaction && transaction?.games && transaction?.games?.length) {
      freeSpinGamesCount += transaction.games?.length;
    }
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const modalType = params.get("dialogue");

  return {
    layout: state.settings.layout,
    url: state.settings.url,
    host: state.settings.host,
    selectedTheme: state.settings.theme,
    locale: state.i18n.locale,
    auth: state.auth,
    modalType,
    translate: state.i18n.translations[state.i18n.locale],
    offersCount: freeSpinGamesCount + depositCampaigns.length + freeBets.length,
  };
};

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(MenuTop)));
