import React from "react";
import { Box, Grid2, Stack } from "@mui/material";
import SportsLayout from "../@components/SportsLayout";
import SportsSideBar from "../@components/SportsSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getPlayerPropsAsync, getPlayerPropsCatalogAsync } from "../../../redux-store/sports-reducer";
import SportsHeader from "../@components/SportsHeader";
import PlayerPropsMarketGroup from "./PlayerPropsMarketGroup";

const PlayerPropsGamePage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const games = useSelector((state) => state.sports.playerProps.games);
  const sportCodes = useSelector((state) => state.sports.playerProps.sportCodes);
  const leagues = useSelector((state) => state.sports.playerProps.leagues);
  const selectedSportCode = searchParams.get("selected-sport") || sportCodes[0];
  const leagueId = searchParams.get("league");
  const locale = useSelector((state) => state.i18n.locale);
  const game = games ? games.find((gm) => gm.game_id === Number(params.gameId)) : null;
  const selectedLeague = leagues[selectedSportCode]?.find((lg) => lg.leagueId === Number(leagueId));

  React.useEffect(() => {
    if (leagueId && selectedSportCode) {
      dispatch(
        getPlayerPropsCatalogAsync({
          lang: locale,
          leagueID: leagueId,
          sportCode: selectedSportCode,
        })
      );
    }
    if (!selectedLeague) {
      dispatch(getPlayerPropsAsync(locale));
    }
  }, [location.pathname, leagueId, selectedSportCode]);

  return (
    <SportsLayout navigationBar={<SportsSideBar collection="player-props" sportCodes={sportCodes} initialSportCode={sportCodes[0]} />}>
      <Box pb={{ xs: 0, md: 2 }}>
        <Grid2 container spacing={1}>
          <Grid2 item size={12}>
            <Stack gap={1}>
              <SportsHeader collection="player-props" league={selectedLeague} game={game} sportCode={selectedSportCode} />
              {game?.markets?.map((market, indx) => (
                <PlayerPropsMarketGroup id={market.id} {...market} mIndx={indx} game={game} />
              ))}
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </SportsLayout>
  );
};

export default PlayerPropsGamePage;
