import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { cancelBonusOffer } from "../../../redux-store/auth-reducer";
import { I18n, Translate } from "react-redux-i18n";
import { cancelBonusOfferAsync } from "../../../redux-store/auth-reducer/_requests";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const BonusOfferDialog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isDialogOpen = useSelector((state) => state.auth.deposit.shouldAlertBonusOffer);
  const viewName = searchParams.get("interface");

  const handleClose = () => {
    dispatch(cancelBonusOffer());
    navigate("/");
  };

  const cancelBonus = () => {
    cancelBonusOfferAsync(handleClose);
  };

  return (
    <Dialog open={isDialogOpen && !location.pathname.includes("limits") && viewName !== "limits"} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{I18n.t("labels.bonus_offer")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{I18n.t("labels.bonus_offer_text")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelBonus} color="warning" sx={{ mr: 2 }} variant="contained">
          <Translate value="selections.no" />
        </Button>
        <Button onClick={handleClose} autoFocus variant="contained" color="primary">
          <Translate value="labels.claim_offer" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BonusOfferDialog;
