// Precanned banner card
import "./style.css";
import icons from "../../sports/@components/sportsIcons";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { calcGame } from "../../../helpers/gameFunctions";
import CommitIcon from "@mui/icons-material/Commit";
import OddsFormat from "../../OddsFormat";
import { emptySlipTemplate, saveBet, setAlert } from "../../../actions";
import createJersey from "../../sports/@components/@utils/create-jersey";
import { Box, Paper, styled, Typography } from "@mui/material";
import CountryAvatar from "../../sports/@components/CountryAvatar";
import Iconify from "../../iconify";
import locales from "../../../helpers/datefns-locales";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

const StyledIconContainer = styled(Box)(({ theme }) => ({
  "& .icon-own": {
    height: "1.5em",
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  padding: theme.spacing(1),
  marginRight: theme.spacing(0.5),
  background: "transparent",
  height: "100%",
}));

const PrecannedCard = ({ game, bet, redirect }) => {
  //  action dispatcher
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // select data
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const lang = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);
  const layout = useSelector((state) => state.settings.layout);

  const handleAddToBetslip = () => {
    if (!auth.isSignedIn && layout === "touch") {
      return dispatch(
        setAlert({
          type: "warning",
          payload: "login_to_add_to_betslip",
          collection: "labels",
        })
      );
    }
    let gameCopy = calcGame(game);
    let payload = {
      game: gameCopy,
      gameId: game?.gameid,
      consumerFixtureId: `sr:match:${game?.gameid}`,
      isLive: false,
      isBetBuilder: true,
      isPrecanned: true,
      marketId: undefined,
      betslipUid: bet.betslipUid,
      price: bet.price,
      isActive: true,
      isPrecannedBet: true,
      hc: "",
      selectionId: bet.betslipUid,
      description: bet.description,
      translations: [],
      identifier: bet.identifier,
      marketDescription: bet.marketDescription,
      isMultiBet: 0,
      sn: bet.description?.toString(),
    };
    dispatch(emptySlipTemplate(selectedSlipTemplate));
    dispatch(saveBet(payload, selectedSlipTemplate, "PrecannedCard"));
    if (redirect && layout === "touch") {
      navigate(`/${lang}/betbuilder?betslip=open`);
    }
    if (redirect && layout === "mouse") {
      navigate(`/${lang}/betbuilder`);
    }
  };

  const getOldPrice = () => {
    const currentPrice = parseFloat(bet.price);
    let currentFloor = currentPrice * 0.95;
    if (currentFloor) {
      currentFloor = currentFloor?.toFixed(1);
      // currentFloor = currentFloor?.toFixed(2);
    }

    return currentFloor;
  };

  let selectionsArray = bet && bet?.description && typeof bet?.description === "object" && bet?.description?.length ? bet.description : [];

  let selections = [];

  selectionsArray?.forEach((element) => {
    const elements = element?.split(",");
    elements?.forEach((el) => {
      selections.push(el);
    });
  });

  return (
    <StyledPaper>
      <Stack direction="row" alignItems="start" justifyContent="space-evenly" pb={1}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <StyledIconContainer>{icons[game?.sport_code]}</StyledIconContainer>
        </Stack>
        <Stack alignItems="center">
          <Typography variant="string" color="text.secondary" fontWeight={300}>
            {game?.leagueName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {game?.startTime
              ? format(new Date(game?.startTime), "dd.MM. HH:mm", {
                  locale: locales[lang],
                })
              : null}
          </Typography>
        </Stack>
        {game?.countryCode ? <CountryAvatar countryCode={game?.countryCode} /> : null}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5} pb={1}>
        <Stack alignItems="center">
          {createJersey(
            game,
            game?.team1_color,
            game?.team1_horizontal_stripes,
            game?.team1_number,
            game?.team1_sleeve,
            game?.team1_split,
            game?.team1_squares,
            game?.team1_stripes,
            game?.team1_sleeve_detail,
            game?.sport_code,
            true
          )}
          <Typography variant="teamnamebb" component="p">
            {game?.team1}
          </Typography>
        </Stack>
        <Iconify icon="tabler:vs" color="text.secondary" />
        <Stack alignItems="center">
          {createJersey(
            game,
            game?.team2_color,
            game?.team2_horizontal_stripes,
            game?.team2_number,
            game?.team2_sleeve,
            game?.team2_split,
            game?.team2_squares,
            game?.team2_stripes,
            game?.team2_sleeve_detail,
            game?.sport_code,
            false
          )}
          <Typography variant="teamnamebb" component="p">
            {game?.team2}
          </Typography>
        </Stack>
      </Stack>
      <Stack pl={1}>
        {selections?.map((des, indx) => (
          <Stack key={indx} direction="row" alignItems="center" gap={1} sx={{ margin: "0 0 -0.3em 0" }}>
            <CommitIcon sx={{ fontSize: "1.5em" }} className="rotated" />
            <Typography variant="string" fontWeight={300}>
              {des}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" pt={0.5}>
        <div className="precanned-total-odds-container cursor-pointer" onClick={handleAddToBetslip}>
          <Stack direction="row" alignItems="center" gap={1}>
            {/* <del className="color-odds font-xl font-bold-2 prev-odd"> */}
            <Typography variant="body2" component="del" color="text.secondary">
              <OddsFormat isActive={true} price={getOldPrice()} />
            </Typography>
            <DoubleArrowIcon fontSize="small" style={{ fontSize: "1.1em", color: "var(--color-main-featured)" }} />
            {/* <span className="color-odds font-xl font-bold-2"> */}
            <Typography variant="body2">
              <OddsFormat isActive={true} price={bet.price} />
            </Typography>
          </Stack>
        </div>
      </Stack>
    </StyledPaper>
  );
};

export default PrecannedCard;
