import React from "react";
import { alpha, Badge, Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import Iconify from "../../iconify";
import { useSelector } from "react-redux";
import IconFREESPIN from "../../sports/@components/@icons/IconFREESPIN";
import { Link, useNavigate } from "react-router-dom";
import CasinoGameBonusCard2 from "../../casino/components/CasinoGameBonusCard2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { format } from "date-fns";

const PromotionsMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dpStatus, setDpStatus] = React.useState(false);
  const [freeSpinsStatus, setFreeSpins] = React.useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const locale = useSelector((state) => state.i18n.locale);

  const handleGoToFreeBets = () => {
    navigate(`/${locale}/freebets/freebets`);
    window.location.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoTodeposit = () => {
    handleClose();
    navigate(`/${locale}/account/payments`);
  };

  const translate = useSelector((state) => state.i18n.translations[state.i18n.locale]);
  const depositCampaigns = useSelector((state) => state.account.campaigns.deposit.list);
  const freeBets = useSelector((state) => state.account.campaigns.freeBets.list);
  const transactions = useSelector((state) => state.casino.bonuses?.transactions);
  let freeSpinGamesCount = 0;
  let freeSpinsGames = [];

  transactions?.forEach((transaction) => {
    if (transaction && transaction?.games && transaction?.games?.length) {
      if (transaction.games?.length) {
        freeSpinGamesCount += transaction.games?.length;
        transaction.games.forEach((gm) => {
          freeSpinsGames.push(gm);
        });
      }
    }
  });

  return (
    <Box px={1.5}>
      <IconButton onClick={handleClick} size="small">
        <Badge badgeContent={freeSpinGamesCount + depositCampaigns.length + freeBets.length}>
          <Iconify icon="emojione:wrapped-gift" width={25} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              border: "none",
              borderRadius: "6px",
              width: "500px",
              minHeight: "30dvh",
              maxHeight: "80dvh",
              overflowY: "auto",
              top: "60px !important",
              "@media only screen and (max-width: 600px)": {
                width: "100dvw !important",
                top: "60px !important",
                minHeight: "280px",
              },
              "& .MuiListItemIcon-root": {
                minWidth: "2em",
              },
              boxShadow: "0px 1px 8px 6px rgba(34, 60, 80, 0.39)",
              background: "var(--bg-app)",
              "& .MuiBadge-badge": {
                padding: "0.2em",
                color: "var(--color-promo-badge)",
                backgroundColor: "var(--yellow-1)",
                fontSize: "0.7em",
                fontWeight: "800",
                height: "1.5em",
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ mt: 1, mx: { xs: 0.5, md: 1 }, px: 0.5, pt: 0.5, borderRadius: 1.5 }}>
          <MenuItem onClick={handleGoToFreeBets} disabled={freeBets.length === 0} sx={{ bgcolor: "background.dbPaper", py: 2, borderRadius: 1.5 }}>
            <ListItemIcon>
              <Iconify width={21} icon="mdi:alphabet-b-box-outline" sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <ListItemText>{translate.menu.free_bets}</ListItemText>
            <ListItemIcon>
              <Badge badgeContent={freeBets.length} color="warning" />
            </ListItemIcon>
          </MenuItem>
        </Box>
        <Box sx={{ ...(freeSpinsStatus && { background: alpha(theme.palette.background.dbPaper, 0.5) }), mt: 1, mx: { xs: 0.5, md: 1 }, px: 0.5, pt: 0.5, borderRadius: 1.5 }}>
          <MenuItem onClick={() => setFreeSpins(!freeSpinsStatus)} disabled={freeSpinGamesCount === 0} sx={{ bgcolor: "background.dbPaper", py: 2, borderRadius: 1.5 }}>
            <ListItemIcon>
              <IconFREESPIN />
            </ListItemIcon>
            <ListItemText>{translate.menu.free_spins}</ListItemText>
            <ListItemIcon>
              <Badge badgeContent={freeSpinGamesCount} color="warning" />
            </ListItemIcon>
          </MenuItem>
          {freeSpinsStatus && (
            <Stack alignItems="flex-start" px={1.7} py={1.5} gap={1}>
              {transactions?.map((bonusData) => {
                return bonusData?.games.map((gameData) => <CasinoGameBonusCard2 key={gameData.id} bonus={bonusData} game={gameData} />);
              })}
            </Stack>
          )}
        </Box>
        <Box sx={{ ...(dpStatus && { background: alpha(theme.palette.background.dbPaper, 0.5) }), mt: 1, mx: { xs: 0.5, md: 1 }, px: 0.5, pt: 0.5, borderRadius: 1.5 }}>
          <MenuItem onClick={() => setDpStatus(!dpStatus)} disabled={!depositCampaigns?.length || depositCampaigns?.length === 0} sx={{ bgcolor: "background.dbPaper", py: 2, borderRadius: 1.5 }}>
            <ListItemIcon>
              <Iconify width={21} icon="tabler:coin-euro" sx={{ color: "text.secondary" }} />
            </ListItemIcon>
            <ListItemText>{translate.menu.deposit_campaigns}</ListItemText>
            <ListItemIcon>
              <Badge badgeContent={depositCampaigns?.length} color="warning" />
            </ListItemIcon>
          </MenuItem>
          {dpStatus && (
            <Stack alignItems="flex-start" px={1.7} py={1.5} width={"100%"}>
              {depositCampaigns.map((dp, indx) => (
                <Stack key={dp.campaignId} gap={0.5} width={"100%"} sx={{ ...(depositCampaigns.length > 1 && { borderBottom: "1px solid", borderColor: "divider", py: 1 }) }}>
                  {dp.promoAmount ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="string" component="p" width={250} noWrap>
                        {translate.labels._bonus}:
                      </Typography>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        {dp.promoAmount}%
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="center">
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Typography variant="string" component="p" width={250} noWrap>
                          {translate.labels._bonus}:
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        {dp.name}
                      </Typography>
                    </Stack>
                  )}
                  {dp.maxPromoLimit ? (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="string" component="p" width={250} noWrap>
                        {translate.labels.max_amount}:
                      </Typography>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        {dp.maxPromoLimit}€
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="string" component="p" width={250} noWrap>
                        {translate.labels.min_deposit}:
                      </Typography>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        {dp.minDepositAmount}€
                      </Typography>
                    </Stack>
                  )}
                  {Boolean(dp.maxPromoLimit) && (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="string" component="p" width={350} noWrap>
                        {translate.menu.bonus_wr} ({translate.menu.sportsBetting}):
                      </Typography>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        {dp.turnoverFactor} x
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction="row" alignItems="center">
                    <Typography variant="string" component="p" width={350} noWrap>
                      {translate.menu.bonus_wr} {dp.maxPromoLimit ? `(${translate.menu.casino})` : ""}:
                    </Typography>
                    <Typography variant="subtitle2" textAlign="right" color="primary">
                      {dp.turnoverFactor * 5} x
                    </Typography>
                  </Stack>
                  {Boolean(dp.promoAmount) && (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="string" component="p" width={250} noWrap>
                        {translate.labels.min_odds}:
                      </Typography>
                      <Typography variant="subtitle2" textAlign="right" color="primary">
                        1.6
                      </Typography>
                    </Stack>
                  )}
                  <Stack direction="row" alignItems="center">
                    <Typography variant="string" component="p" width={250} noWrap>
                      {translate.labels.expires}:
                    </Typography>
                    <Typography variant="subtitle2" textAlign="right" color="primary">
                      {format(new Date(dp.endTime), "dd.MM.yyyy HH:mm")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="string" component="p" width={250} noWrap>
                      {translate.labels.recycling_time}:
                    </Typography>
                    <Typography variant="subtitle2" textAlign="right" color="primary">
                      7 {translate.labels.days.toLowerCase()}
                    </Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <Button variant="contained" startIcon={<AddCircleIcon />} color="warning" onClick={handleGoTodeposit}>
                      {translate.menu.deposit}
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

export default PromotionsMenu;
