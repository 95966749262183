// casino promo game card
// @mui compoenents
import { Box, Grid2, Paper, Stack, styled, Typography, Button } from "@mui/material";
// @laxy load image
import Image from "../../image";
// router
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { I18n, Translate } from "react-redux-i18n";
import { useSelector } from "react-redux";

const CasinoGameBonusCard2 = ({ game, bonus }) => {
  // router hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locale = useSelector((state) => state.i18n.locale);
  // generate game image
  let casinoGameImgLink = `https://storage.googleapis.com/nubet-casino-gfx/${game.name}.jpg`;
  // generate 404 image placeholder
  const handleImage404Error = (e) => {
    e.target.src = `https://storage.googleapis.com/nubet-casino-gfx/coming_soon.jpg`;
  };

  const handleOpen = () => {
    searchParams.set("dialog", "play-game");
    searchParams.set("game-id", game.gameId);
    searchParams.set("bonus", "free-spins");
    navigate(`/${locale}/casino/dashboard?${searchParams.toString()}`);
  };

  return (
    <Box width={"100%"}>
      <Grid2 container spacing={2}>
        <Grid2 size={3.5}>
          <Image onError={handleImage404Error} src={casinoGameImgLink} ratio="4/3" />
        </Grid2>
        <Grid2 size={8.5}>
          <Stack width={"100%"}>
            <Typography variant="subtitle1" component="p" color="primary">
              {game.translation} {I18n.t("menu.free_spins")}
            </Typography>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
              <Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="string" component="p">
                    {I18n.t("labels.statement_header_game")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {game.translation}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="string" component="p">
                    {I18n.t("labels.spins_left")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {bonus.freeSpins}/{bonus.total}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="string" component="p">
                    {I18n.t("menu.bonus_wr")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {bonus.turnoverFactor * 5}x
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="string" component="p">
                    {I18n.t("labels.expires")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    {format(new Date(bonus.expirationTime), "dd.MM.yyyy HH:mm")}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="string" component="p">
                    {I18n.t("labels.recycling_time")}:
                  </Typography>
                  <Typography variant="subtitle2" component="h3">
                    7 {I18n.t("labels.days")}
                  </Typography>
                </Stack>
                <br></br>
                <Stack pt={1}>
                  <Button fullWidth onClick={handleOpen} variant="contained" color="primary">
                    <Translate value="labels.slots_play" />
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CasinoGameBonusCard2;
