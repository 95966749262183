import React from "react";
import { connect } from "react-redux";
import { Localize } from "react-redux-i18n";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import TicketIcon from "@mui/icons-material/DynamicFeed";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Drawer from "@mui/material/Drawer";
import withRouter from "../helpers/withRouter";

import IconFB from "./sports/@components/@icons/IconFB";
import IconCAS from "./sports/@components/@icons/IconCAS";
import IconSLIP from "./sports/@components/@icons/IconSLIP";
import IconESP from "./sports/@components/@icons/IconESP";
import IconTR from "./sports/@components/@icons/IconTR";
import Iconify from "./iconify";
import { Box, Tab, Tabs } from "@mui/material";

const styles = (theme) => ({
  root: {
    overflow: "auto !important",
    flexGrow: 1,
    bottom: 0,
    width: "100%",
    position: "fixed",
    zIndex: 1299,
    background: "var(--bg-app)",
    "& .MuiTab-root": {
      fontSize: "0.9em",
    },
    "& .Mui-selected": {
      "& .MuiTab-icon": {
        color: "var(--color-odds) !important",
      },
    },
  },
  betbuilderBadge: {
    "& .MuiBadge-badge": {
      padding: "0.2em",
      color: "var(--color-promo-badge)",
      backgroundColor: "var(--yellow-1)",
      fontSize: "0.7em",
      fontWeight: "800",
      height: "1em",
    },
    "& .MuiBadge-anchorOriginTopRightCircular": {
      top: "0",
      right: "0",
    },
  },
  moreMenu: {
    position: "fixed !important",
    bottom: "56px",
    left: 0,
    right: 0,
    width: "100%",
    backgroundColor: "var(--color-menu-more)",
    zIndex: 1300,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      bottom: "56px",
    },
  },
  listItem: {
    color: "var(--grey)",
    borderBottom: "1px solid var(--grey-6) !important",
    fontSize: "0.9em",
    textAlign: "center",
    // padding: "10px 5px !important",
    justifyContent: "right !important",
  },
  bottomNavLabel: {
    "& .MuiBottomNavigationAction-label": {
      fontSize: "0.9em !important",
    },
  },
  moreVertIcon: {
    fontSize: "1.65em !important",
  },
});

class MenuBottom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: "/",
      moreMenuOpen: false,
    };
  }

  componentDidMount() {
    this.setState({ value: this.props.location.pathname });
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  toggleMoreMenu = () => {
    this.setState((prevState) => ({
      moreMenuOpen: !prevState.moreMenuOpen,
    }));
  };

  goToSlip = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/slip`);
  };

  goToTickets = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/account/tickets`);
  };

  goToCasino = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/casino/dashboard`);
  };

  goToSports = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/sports?sportEventStatusSlug=all`);
    window.location.reload();
  };

  gotToESports = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/esports?sportEventStatusSlug=all`);
    window.location.reload();
  };

  goToRacing = () => {
    const { locale, navigate } = this.props;
    navigate(`/${locale}/horseracing`);
  };

  goToPlayerProps = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/player-props`);
  };

  goToBetbuilder = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/betbuilder`);
  };

  goToSignIn = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    localStorage.setItem("redirect_url", `/${locale}/account/tickets`);
    navigate(`/${locale}/auth/login`);
  };

  goToPromotions = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/promotions`);
  };
  goToLeaderboardShanghai = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/leaderboard/shanghai2024`);
  };
  goToLeaderboardHockey = () => {
    const { locale, navigate } = this.props;
    this.setState({ moreMenuOpen: false });
    navigate(`/${locale}/leaderboard/hockey`);
  };

  shouldHide = () => {
    const { url, locale } = this.props;
    if (url?.includes(`/${locale}/sports`) || url?.includes(`/${locale}/esports`)) {
      return true;
    }
    return false;
  };

  renderSelectedBets() {
    const { selectedBets, translate, slipTemplates, thisSlipTemplate, oddsFormat } = this.props;
    this.settings = slipTemplates[thisSlipTemplate]?.settings;
    const isHidden = this.shouldHide();
    if (isHidden) return;
    if (selectedBets > 0) {
      return (
        <BottomNavigationAction
          label={
            <span style={{ fontSize: "0.8em", whiteSpace: "nowrap" }}>
              {translate.menu.maxOdd}{" "}
              {oddsFormat === "decimal" ? (
                <Localize
                  value={this.settings.maxOdds}
                  options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }}
                />
              ) : (
                <span>{this.settings.maxOdds}</span>
              )}
            </span>
          }
          value={`/${this.props.locale}/slip`}
          icon={
            <Badge badgeContent={`${selectedBets}`} color="error">
              <IconSLIP />
            </Badge>
          }
          onClick={this.goToSlip}
        />
      );
    } else {
      return <BottomNavigationAction label={translate.menu.slip} value={`/${this.props.locale}/slip`} icon={<IconSLIP />} onClick={this.goToSlip} />;
    }
  }

  renderSelectedBetsNew() {
    const { selectedBets, translate, slipTemplates, thisSlipTemplate, oddsFormat, classes } = this.props;
    this.settings = slipTemplates[thisSlipTemplate]?.settings;
    const isHidden = this.shouldHide();
    if (isHidden) return;
    if (selectedBets > 0) {
      return (
        <ListItem className={classes.listItem} button onClick={this.goToSlip}>
          <Badge badgeContent={`${selectedBets}`} color="error">
            <span style={{ fontSize: "0.8em", whiteSpace: "nowrap" }}>
              {translate.menu.maxOdd}{" "}
              {oddsFormat === "decimal" ? (
                <Localize
                  value={this.settings.maxOdds}
                  options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }}
                />
              ) : (
                <span>{this.settings.maxOdds}</span>
              )}
            </span>
          </Badge>
          {/* <IconSLIP /> */}
        </ListItem>
      );
    } else {
      return (
        <ListItem className={classes.listItem} button onClick={this.goToSlip}>
          <span>{translate.menu.slip}</span>
          {/* <IconSLIP /> */}
        </ListItem>
      );
    }
  }

  renderTickets() {
    const { pendingTickets, translate, auth, locale } = this.props;
    const isHidden = this.shouldHide();
    if (isHidden) return;
    if (auth?.isSignedIn) {
      return (
        <BottomNavigationAction
          label={translate.menu.myBets}
          value={`/${locale}/account/tickets`}
          icon={
            pendingTickets !== 0 ? (
              <Badge badgeContent={`${pendingTickets}`} color="error" overlap="rectangular">
                <TicketIcon />
              </Badge>
            ) : (
              <TicketIcon />
            )
          }
          onClick={this.goToTickets}
        />
      );
    }
  }

  renderTicketsNew() {
    const { pendingTickets, translate, auth, locale, classes } = this.props;
    const isHidden = this.shouldHide();
    if (isHidden) return;
    if (auth?.isSignedIn) {
      return (
        <ListItem button onClick={this.goToTickets} className={classes.listItem}>
          {pendingTickets !== 0 ? (
            <Badge badgeContent={`${pendingTickets}`} color="error" overlap="rectangular">
              {translate.menu.myBets}
            </Badge>
          ) : (
            translate.menu.myBets
          )}
          {/* <TicketIcon /> */}
        </ListItem>
      );
    }
  }

  renderMoreMenu() {
    const { moreMenuOpen, translate } = this.state;
    const { classes } = this.props;

    return (
      <Drawer sx={{ zIndex: 2000 }} anchor="bottom" open={moreMenuOpen} onClose={() => this.setState({ moreMenuOpen: false })}>
        <List className={classes.moreMenu}>
          <ListItem className={classes.listItem} button onClick={this.goToPlayerProps}>
            <Translate value={`marketSets.player`} />
          </ListItem>
          <ListItem className={classes.listItem} button onClick={this.goToBetbuilder}>
            <Translate value={`marketSets.betbuilder`} />
          </ListItem>
          <ListItem className={classes.listItem} button onClick={this.goToPromotions}>
            <Translate value={`menu.promotions`} />
          </ListItem>
          {/* <ListItem className={classes.listItem} button onClick={this.goToLeaderboardShanghai}>
            <Translate value={`menu.lb_shanghai`} />
          </ListItem> */}
          {/* <ListItem className={classes.listItem} button onClick={this.goToLeaderboardHockey}>
            <Translate value={`menu.lb_hockey`} />
          </ListItem> */}
          {this.renderSelectedBetsNew()}
          {this.renderTicketsNew()}
        </List>
      </Drawer>
    );
  }

  render() {
    const { host, translate, classes, locale, url, auth } = this.props;
    const isHidden = this.shouldHide();
    if (!host) return null;

    return (
      <Box className={classes.root}>
        <Tabs variant="scrollable" value={url} TabIndicatorProps={{ sx: { display: "none" } }}>
          <Tab label={translate.menu.sportsBetting} iconPosition="top" value={`/${locale}/sports`} icon={<IconFB state={this.state.value === `/${locale}/sports` ? "active" : ""} />} onClick={this.goToSports} />
          <Tab iconPosition="top" label={translate.menu.esports} value={`/${locale}/esports`} icon={<IconESP state={this.state.value === `/${locale}/esports` ? "active" : ""} />} onClick={this.gotToESports} />
          <Tab iconPosition="top" label={translate.menu.trotting} value={`/${locale}/horseracing`} icon={<IconTR state={this.state.value === `/${locale}/horseracing` ? "active" : ""} />} onClick={this.goToRacing} />
          <Tab iconPosition="top" className={classes.bottomNavLabel} label={translate.menu.casino} value={`/${locale}/casino/dashboard`} icon={<IconCAS />} onClick={this.goToCasino} />
          <Tab iconPosition="top" label={translate.marketSets.betbuilder} value={`/${locale}/betbuilder`} icon={<Iconify icon="carbon:model-builder" />} onClick={this.goToBetbuilder} />
          <Tab iconPosition="top" label={translate.marketSets.player} value={`/${locale}/player-props`} icon={<Iconify icon="game-icons:american-football-player" />} onClick={this.goToPlayerProps} />
          <Tab iconPosition="top" label={translate.menu.promotions} value={`/${locale}/promotions`} icon={<Iconify icon="hugeicons:promotion" />} onClick={this.goToPromotions} />
          {/* <Tab iconPosition="top" label={translate.menu.lb_shanghai} value={`/${locale}/leaderboard/shanghai2024`} icon={<Iconify icon="hugeicons:left-to-right-list-number" />} onClick={this.goToLeaderboardShanghai} /> */}
          {/* <Tab iconPosition="top" label={translate.menu.lb_hockey} value={`/${locale}/leaderboard/hockey`} icon={<Iconify icon="hugeicons:left-to-right-list-number" />} onClick={this.goToLeaderboardHockey} /> */}
        </Tabs>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedSlipTemplateData = Object.values(state.slips).filter(function (item) {
    return item.slipTemplate === state.selected.slipTemplate;
  });

  let pendingTickets = 0;
  if (state.tickets.pendingList) {
    pendingTickets = Object.values(state.tickets.pendingList)?.length;
  }

  return {
    auth: state.auth,
    host: state.settings.host,
    selectedLeague: Object.values(state.selected.leagues),
    favoriteLeagues: state.settings.favorites ? state.settings.favorites.leagues : null,
    selectedSlipTemplate: state.selected.slipTemplate,
    selectedBets: selectedSlipTemplateData[0] ? (selectedSlipTemplateData[0].settings ? (selectedSlipTemplateData[0].settings.bets ? Object.keys(selectedSlipTemplateData[0].settings.bets)?.length : 0) : 0) : 0,
    pendingTickets: pendingTickets,
    translate: state.i18n.translations[state.i18n.locale],
    thisSlipTemplate: state.selected.slipTemplate,
    slipTemplates: state.slips,
    oddsFormat: state.settings.oddsFormat,
    locale: state.i18n.locale,
    url: state.settings.url,
  };
};

export default withRouter(connect(mapStateToProps, {})(withStyles(styles)(MenuBottom)));
