import { Box, Grid2, Stack } from "@mui/material";
import SportsLayout from "../@components/SportsLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getPlayerPropsAsync, getPlayerPropsCatalogAsync } from "../../../redux-store/sports-reducer";
import React from "react";
import SportsSideBar from "../@components/SportsSideBar";
import { PaperContainer } from "../@components/containers";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import SportsHeader from "../@components/SportsHeader";
import PPGameCard from "../@components/PPGameCard";
import _ from "lodash";

const PlayerPropsGamesPage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();

  // selectors
  const sportCodes = useSelector((state) => state.sports.playerProps.sportCodes);
  const selectedSportCode = searchParams.get("selected-sport") || sportCodes[0];
  const leagues = useSelector((state) => state.sports.playerProps.leagues);
  const games = useSelector((state) => state.sports.playerProps.games);
  const locale = useSelector((state) => state.i18n.locale);
  const selectedLeague = leagues[selectedSportCode]?.find((lg) => lg.leagueId === Number(params.leagueId));

  React.useEffect(() => {
    if (params.leagueId && selectedSportCode) {
      dispatch(
        getPlayerPropsCatalogAsync({
          lang: locale,
          leagueID: params.leagueId,
          sportCode: selectedSportCode,
        })
      );
    }
    if (!leagues || !Object.keys(leagues).length) {
      dispatch(getPlayerPropsAsync(locale));
    }
  }, [location.pathname, params.leagueId, selectedSportCode]);

  const sortedGames = _.cloneDeep(games).sort((a, b) => {
    const dateA = new Date(a.startTime);
    const dateB = new Date(b.startTime);
    return dateA - dateB;
  });

  return (
    <SportsLayout
      banner={
        <Box display={{ xs: "none", md: "block" }}>
          <PrecannedBetsCarousel />
        </Box>
      }
      navigationBar={<SportsSideBar collection="player-props" sportCodes={sportCodes} initialSportCode={sportCodes[0]} />}
    >
      <Box pb={{ xs: 0, md: 2 }}>
        <Stack gap={1}>
          <SportsHeader collection="player-props" league={selectedLeague} sportCode={selectedSportCode} />
          <PaperContainer>
            <Grid2 container spacing={0.5}>
              {sortedGames?.map((game) => (
                <Grid2 item size={{ xs: 12, sm: 6, md: 4, xl: 3 }} key={game.game_id}>
                  <PPGameCard sportCode={selectedSportCode} leagueId={params.leagueId} game={game} />
                </Grid2>
              ))}
            </Grid2>
          </PaperContainer>
        </Stack>
      </Box>
    </SportsLayout>
  );
};

export default PlayerPropsGamesPage;
