import { Paper, Stack, styled, Typography } from "@mui/material";
import createJersey from "./@utils/create-jersey";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import locales from "../../../helpers/datefns-locales";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  cursor: "pointer",
  userSelect: "none",
  height: "100%",
  borderRadius: 8,
}));

const PPGameCard = ({ game, leagueId }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // selectros
  const lang = useSelector((state) => state.i18n.locale);

  const handleRoute = () => {
    searchParams.set("league", leagueId);
    navigate(`/${lang}/player-props/game/${game.game_id}?${searchParams?.toString()}`);
  };

  return (
    <StyledPaper onClick={handleRoute}>
      <Stack>
        <Typography variant="string" align="center" color="text.secondary" mb={1}>
          {game?.startTime
            ? format(new Date(game?.startTime), "dd.MM. HH:mm", {
                locale: locales[lang],
              })
            : null}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack gap={1} width="40%" alignItems="top" justifyContent="center">
            {createJersey(
              { ...game, sport_code: game.sportCode },
              game?.team1_color,
              game?.team1_horizontal_stripes,
              game?.team1_number,
              game?.team1_sleeve,
              game?.team1_split,
              game?.team1_squares,
              game?.team1_stripes,
              game?.team1_sleeve_detail,
              game?.sportCode,
              true
            )}
            <Typography variant="teamnamebb" color="primary.team" sx={{ textAlign: "center", width: "100%" }}>
              {game.team1}
            </Typography>
          </Stack>

          <Typography variant="h3" color="primary.featured2" width="20%" textAlign="center">
            VS
          </Typography>
          <Stack gap={1} width="40%" alignItems="top" justifyContent="center">
            {createJersey(
              { ...game, sport_code: game.sportCode },
              game?.team2_color,
              game?.team2_horizontal_stripes,
              game?.team2_number,
              game?.team2_sleeve,
              game?.team2_split,
              game?.team2_squares,
              game?.team2_stripes,
              game?.team2_sleeve_detail,
              game?.sportCode,
              false
            )}
            <Typography variant="teamnamebb" color="primary.team" sx={{ textAlign: "center", width: "100%" }}>
              {game.team2}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </StyledPaper>
  );
};

export default PPGameCard;
