/**
 * Casino Banner component
 */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../../image";
import { Box, styled } from "@mui/material";
import useWindowSize from "../../../helpers/useWindowSize";
// carousel
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";
import casinoBannerSliderSettings from "../../swiper/settings/casino-banner-slider";
import CasinoBannerRotation from "../../../components/wp-content/CasinoBannerRotation";

const StyledContainer = styled(Box)(() => ({
  padding: "0 1em",
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff00",
    padding: "6em 0 0 0",
    textAlign: "right",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1em !important",
  },
}));

const CasinoBanner = () => {
  const locale = useSelector((state) => state.i18n.locale);
  const screenWidth = useWindowSize();
  const auth = useSelector((state) => state.auth);

  let items = [
    // {
    //   name: "Drops and Wins",
    //   description: "Drops and Wins",
    //   image: "banner_dropswins.jpg",
    //   link: "promotions",
    // },
    // {
    //   name: "Sugar Rush 1000",
    //   description: "Sugar Rush 1000",
    //   image: "banner_sugar_rush_1000.jpg",
    //   link: "casino/dashboard?dialog=play-game&game-id=690111",
    // },
    {
      name: "Fire in the Hole",
      description: "Fire in the Hole",
      image: "banner_firehole.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=590097",
    },
    {
      name: "Big Bamboo",
      description: "Big Bamboo",
      image: "banner_bigbamboo.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=770096",
    },
    {
      name: "Gates of olympus 1000",
      description: "Gates of olympus 1000",
      image: "banner_olympus1000.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=690078",
    },
    {
      name: "Gonzos Quest",
      description: "Gonzos Quest",
      image: "banner_gonzos_quest.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=966524",
    },
    {
      name: "Buffalo King",
      description: "Buffalo King",
      image: "banner_buffalo.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=678260",
    },
    {
      name: "Reactoonz",
      description: "Reactoonz",
      image: "banner_reactoonz_241.jpg",
      link: "casino/dashboard?dialog=play-game&game-id=60451",
    },
    // {
    //   name: "Land of the Free",
    //   description: "Land of the Free",
    //   image: "banner_landofthefree_241.jpg",
    //   link: "casino/dashboard?dialog=play-game&game-id=590245",
    // },
    // {
    //   name: "Gonzos Quest",
    //   description: "Gonzos Quest",
    //   image: "banner_gonzos_quest.jpg",
    //   link: "casino/dashboard?dialog=play-game&game-id=160250",
    // },
  ];
  // if (!auth.isSignedIn) {
  //   items.unshift({
  //     name: "Book of Power",
  //     description: "Book of Power",
  //     image: "banner_bookofpower_101.jpg",
  //     link: "promotions-freespins",
  //   });
  // }

  return (
    <SwiperSlider {...casinoBannerSliderSettings}>
      {items?.map((item, indx) => (
        <SwiperSlide key={indx}>
          <StyledContainer>
            <Link key="link-promo" to={`/${locale}/${item.link}`}>
              <Image
                src={screenWidth <= 600 ? `https://storage.googleapis.com/nubet-prod-content-news/mobile_${item.image}` : `https://storage.googleapis.com/nubet-prod-content-news/${item.image}`}
                ratio={screenWidth <= 600 ? "21/9" : "21/6"}
              />
            </Link>
            {/* <CasinoBannerRotation/> */}
          </StyledContainer>
        </SwiperSlide>
      ))}
    </SwiperSlider>
  );
};

export default CasinoBanner;
